export type Headers = {
  [key: string]: string;
  "X-Volt-SCA-Token": string;
};

export function prepareHeadersForSCA<T extends Partial<Headers>>(
  headers: T extends Partial<Headers> ? Partial<Headers> : T,
  scaToken?: string
) {
  const newHeaders = Object.assign({}, headers);

  if (scaToken) {
    newHeaders["X-Volt-SCA-Token"] = scaToken;
  }

  return newHeaders;
}
