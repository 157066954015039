import type { Params } from "modules/common/hooks/useQueryString";

import type { PayoutsFilters } from "../../constants/PayoutsFilters";

export function prepareFiltersForApi(filters?: Partial<PayoutsFilters>) {
  if (!filters) {
    return {};
  }

  const params: Params = {};

  const { status, currency, amountTo, amountFrom } = filters;

  if (status) {
    params["status"] = status;
  }

  if (currency) {
    params["currency"] = currency;
  }

  if (amountFrom) {
    params["amount[gte]"] = amountFrom;
  }

  if (amountTo) {
    params["amount[lte]"] = amountTo;
  }

  return params;
}
