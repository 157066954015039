import { ButtonDeprecated } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { TEST_IDS } from "../../../constants/TestIds";
import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";
import { ApproveRefundConfirmModal } from "../../ApproveRefundConfirmModal";
import { DisabledApproveButton } from "./DisabledApproveButton";
import { useApproveButton } from "./useApproveButton";

export function ApproveButton() {
  const { t } = useTranslation("refunds");

  const {
    isModalActive,
    toggleIsModalActive,
    handleButtonClick,
    isVisible,
    isDisabled,
    tooltipTitleTranslationKey,
    isLoading,
    handleApproveRefund,
  } = useApproveButton();

  if (!isVisible) {
    return null;
  }

  if (isDisabled && tooltipTitleTranslationKey) {
    return (
      <DisabledApproveButton tooltipTitle={t(tooltipTitleTranslationKey)} />
    );
  }

  return (
    <>
      <ApproveRefundConfirmModal
        isOpen={isModalActive}
        onClose={toggleIsModalActive}
        onSuccess={handleApproveRefund}
      />
      <ButtonDeprecated
        data-testid={TEST_IDS.refundApproveButton}
        disabled={isDisabled}
        loading={isLoading}
        onClick={handleButtonClick}
      >
        {t(TRANSLATION_KEYS.approve)}
      </ButtonDeprecated>
    </>
  );
}
