import type {
  CustomerHierarchy,
  CustomerHierarchyTree,
} from "../models/Hierarchy";

export const mapHierarchyArrayToObject = (
  baseObject: CustomerHierarchy,
  hierarchy: CustomerHierarchy[]
) => {
  const root: CustomerHierarchyTree = {
    ...baseObject,
    children: [],
  };

  if (root.childrenCount > 0) {
    hierarchy.forEach((child) => {
      const isChild =
        child.integratorId === baseObject.id ||
        child.parentId === baseObject.id;
      const isNextLevel = child.depth === root.depth + 1;

      if (isNextLevel && isChild) {
        root.children.push(mapHierarchyArrayToObject(child, hierarchy));
      }
    });
  }

  return root;
};
