import { styled } from "@mui/material";
import { ListBox, ListItem } from "@volt_developers/components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { DropdownNavigation } from "modules/common/components/DropdownNavigation";

import { PAGE_URLS } from "../../../constants/PageUrls";
import { TEST_IDS } from "../../../constants/TestIds";

export interface PayoutNotificationsCardDropdownNavigationProps {
  isVisible: boolean;
}

export function ReportNotificationsCardDropdownNavigation({
  isVisible,
}: PayoutNotificationsCardDropdownNavigationProps) {
  const { t } = useTranslation("common");

  if (!isVisible) {
    return null;
  }

  return (
    <DropdownNavigation
      buttonTestId={TEST_IDS.reportNotificationsCardDropdownButton}
    >
      <ListBox sx={{ right: 0, width: LIST_BOX_WIDTH }}>
        <ListItem>
          <StyledLink
            data-testid={TEST_IDS.reportNotificationsCardEditButton}
            to={PAGE_URLS.reportNotificationsConfiguration}
          >
            {t(COMMON_TRANSLATION_KEYS.edit)}
          </StyledLink>
        </ListItem>
      </ListBox>
    </DropdownNavigation>
  );
}

const LIST_BOX_WIDTH = 160;

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.high,
  textDecoration: "none",
  width: "100%",
}));
