import { SERVICES } from "constants/environment";

import type { ENV_INSENSITIVE_SERVICES} from "constants/environment";

export const ENV_INSENSITIVE_SERVICES_URLS: Record<
  ENV_INSENSITIVE_SERVICES,
  string
> = {
  [SERVICES.WORKFLOWS]: import.meta.env.FUZEBOX_WORKFLOWS_URL ?? "",
  [SERVICES.USERS]: import.meta.env.FUZEBOX_USERS_URL ?? "",
} as const;
