import type { BeneficiaryAddress } from "../models/BeneficiaryAddress";

export function isBeneficiaryAddressDefined(address: BeneficiaryAddress) {
  return Boolean(
    address?.buildingNumber ||
      address?.city ||
      address?.postalCode ||
      address?.street ||
      address?.country
  );
}
