type ObjectType = Record<string, any>;

export const trimObjectProperties = <T extends ObjectType>(object: T) =>
  Object.entries(object).reduce((accumulator, [key, value]) => {
    if (typeof value === "string") {
      (accumulator as any)[key] = value.trim();
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      (accumulator as any)[key] = trimObjectProperties(value);
    } else {
      (accumulator as any)[key] = value;
    }

    return accumulator;
  }, {} as T);
