import { useDispatch, useSelector } from "react-redux";

import { ENV_PRODUCTION } from "constants/environment";
import { selectEnvironment, toggleEnvironment } from "store/appSlice";

import { useAppRoutes } from "./useBuildChildRoutes";

export function useSwitchEnvironment() {
  const dispatch = useDispatch();
  const appEnvironment = useSelector(selectEnvironment);
  const { allRoutes } = useAppRoutes();

  return {
    isProductionEnv: appEnvironment === ENV_PRODUCTION,
    changeEnvironment: () => {
      dispatch(toggleEnvironment({ allRoutes }));
    },
  };
}
