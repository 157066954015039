import { createAction, createSlice } from "@reduxjs/toolkit";

import type { Country } from "models/Country";

import type { RootState } from ".";

export const initialState: {
  processableCountries: Country["id"][];
} = {
  processableCountries: [],
};

export const slice = createSlice({
  name: "values",
  initialState,
  reducers: {
    setProcessableCountries: (state, action) => {
      state.processableCountries = action.payload;
    },
  },
});

export const valuesOperationPaths = {
  processableCountries: "values.processableCountries",
};

export const { setProcessableCountries } = slice.actions;

export const fetchProcessableCountries = createAction(
  "fetchProcessableCountries"
);

export const selectProcessableCountries = (state: RootState) =>
  state.values.processableCountries;

export const valuesSlice = slice.reducer;
