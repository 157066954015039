import * as yup from "yup";

import { FIELD_REQUIRED, INVALID_UUID } from "constants/errors";
import { isUUID } from "utils";

export const voltCashManagementBankProviderSchema = yup.object().shape({
  voltAccountId: yup
    .string()
    .nullable()
    .required(FIELD_REQUIRED)
    .test("is-uuid", INVALID_UUID, (value) => {
      if (typeof value === "string") {
        return isUUID(value);
      }

      return false;
    }),
});

export const bankingCirceProviderSchema = yup.object().shape({
  masterAccountNumber: yup.string().nullable().required(FIELD_REQUIRED),
});

export const clearJunctionProviderSchema = yup.object().shape({
  walletId: yup
    .string()
    .nullable()
    .required(FIELD_REQUIRED)
    .test("is-uuid", INVALID_UUID, (value) => {
      if (typeof value === "string") {
        return isUUID(value);
      }

      return false;
    }),
});
