import {
  BLOCK_RULE_TYPE_BANK,
  BLOCK_RULE_TYPE_DOCUMENT_ID,
  BLOCK_RULE_TYPE_FINGERPRINT,
  BLOCK_RULE_TYPE_IBAN,
  BLOCK_RULE_TYPE_IP_ADDRESS,
  BLOCK_RULE_TYPE_MAIL,
  BLOCK_RULE_TYPE_PAYER,
} from "modules/circuit-breaker/constants/Rules";

import { ALL_KEYS_ID } from "../../components/BlocklistFiltersDropdown";
import {
  FILTER_ACTIVE_DISABLED,
  FILTER_ACTIVE_ENABLED,
} from "../../hooks/useBlocklistFilters";

export const circuitBreaker = {
  [BLOCK_RULE_TYPE_BANK]: "Bank Branch",
  [BLOCK_RULE_TYPE_FINGERPRINT]: "Device fingerprint",
  [BLOCK_RULE_TYPE_DOCUMENT_ID]: "Document ID",
  [BLOCK_RULE_TYPE_MAIL]: "Email address",
  [BLOCK_RULE_TYPE_IBAN]: "IBAN",
  [BLOCK_RULE_TYPE_IP_ADDRESS]: "IP Address",
  [BLOCK_RULE_TYPE_PAYER]: "Payer ID",
  [ALL_KEYS_ID]: "All keys",
  [FILTER_ACTIVE_ENABLED]: "Active blocks",
  [FILTER_ACTIVE_DISABLED]: "Inactive blocks",
};
