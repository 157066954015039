import { subject } from "@casl/ability";
import axios from "axios";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import { portalApi } from "api/rtkApi";
import { SUBJECT_BANK } from "models/role/subject";

import { BLOCK_RULE_TYPE_BANK } from "../constants/Rules";

import type { AxiosRequestConfig, AxiosResponse } from "axios";

import type { Bank } from "modules/banks/models/banks";
import type { Payment } from "modules/payments/models/Payment";
import type { UUID } from "utils";

import type {
  BlockList,
  RiskOverviewResponse,
  RiskScore,
  RuleResult,
} from "../models/RiskOverview";
import type { RiskRule } from "../models/RiskRule";

export type GetRiskOverviewRequest = {
  id: UUID;
};

export type GetRiskOverviewResponse = {
  blockList: (BlockList | Pick<BlockList, "ruleResults">) & { bank?: Bank };
  riskScore:
    | Pick<RiskScore, "matchedRules" | "score" | "threshold">
    | RiskScore;
};

export type ResultForPayments = (
  paymentId: Pick<Payment, "id">,
  options?: AxiosRequestConfig
) => Promise<AxiosResponse<RiskOverviewResponse>>;

export const getResultForPayments: ResultForPayments = async (
  paymentId,
  options
) => {
  try {
    const response = await axios.get<AxiosResponse<RiskOverviewResponse>>(
      `/circuit-breaker/results-for-payments/${paymentId}`,
      options
    );

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

const extendedApi = portalApi.injectEndpoints({
  endpoints: (build) => ({
    riskOverview: build.query<GetRiskOverviewResponse, GetRiskOverviewRequest>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { id } = arg;
        const resultsForPayment = await fetchWithBQ(
          `/circuit-breaker/results-for-payments/${id}`
        );

        if (resultsForPayment.error) {
          return { error: resultsForPayment.error };
        }

        const resultsForPaymentResponse =
          resultsForPayment.data as GetRiskOverviewResponse;

        let riskOverview: GetRiskOverviewResponse;

        if (Array.isArray(resultsForPaymentResponse)) {
          const blockList = {
            ruleResults: [] as RuleResult[],
          };
          const riskScore = {
            score: 0,
            threshold: 0,
            matchedRules: [] as RiskRule[],
          };

          riskOverview = { blockList, riskScore };
        } else {
          riskOverview = resultsForPaymentResponse;
        }

        if (!("blockList" in riskOverview)) {
          return { data: riskOverview };
        }

        const bankRuleResult = riskOverview.blockList.ruleResults.find(
          (ruleResult: RuleResult) => ruleResult.type === BLOCK_RULE_TYPE_BANK
        );

        if (bankRuleResult) {
          const resultsForBank = await fetchWithBQ(
            `/banks/${bankRuleResult.value}`
          );

          if (resultsForBank.error) {
            throw resultsForBank.error;
          }

          const resultsForBankData = resultsForBank.data as Bank;
          const bankData = subject(SUBJECT_BANK, resultsForBankData);

          if ("id" in bankData) {
            riskOverview.blockList.bank = bankData;
          }
        }

        return { data: riskOverview };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useRiskOverviewQuery } = extendedApi;
