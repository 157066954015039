import { Box, Tooltip } from "@mui/material";
import { ButtonDeprecated } from "@volt_developers/components";

import { TEST_IDS } from "constants/TestIds";
import { DisabledTooltipAnchor } from "modules/common/components/DisabledTooltipAnchor";

import { CancelButton } from "./CancelButton";

import type { ReactNode } from "react";

import type { CancelButtonProps } from "./CancelButton";

type FormButtonsProps = {
  submitLabel: string;
  cancelDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  tooltipTitle?: ReactNode;
} & CancelButtonProps;

export function FormButtons({
  disabled = false,
  isLoading = false,
  tooltipTitle,
  submitLabel,
  ...cancelProps
}: FormButtonsProps) {
  return (
    <Box>
      <CancelButton {...cancelProps} />
      <Tooltip title={tooltipTitle ?? ""} arrow>
        <DisabledTooltipAnchor sx={{ display: "inline-block" }}>
          <ButtonDeprecated
            color="default"
            data-testid={TEST_IDS.formSubmitButton}
            disabled={disabled}
            loading={isLoading}
            size="medium"
            type="submit"
            variant="solid"
          >
            {submitLabel}
          </ButtonDeprecated>
        </DisabledTooltipAnchor>
      </Tooltip>
    </Box>
  );
}
