import { portalApi, TAGS } from "api/rtkApi";
import { SERVICES } from "constants/environment";

import type { CashManagementProvider } from "../models/Provider";

type GetProvidersResponse = CashManagementProvider[];

type GetProvidersRequest = void;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getProviders: builder.query<GetProvidersResponse, GetProvidersRequest>({
      query: () => ({
        url: "/cash-management/providers",
        method: "GET",
      }),
      providesTags: [TAGS.CASH_MANAGEMENT_PROVIDERS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
  }),
});

export const { useGetProvidersQuery } = extendedApi;
