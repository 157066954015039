import { Skeleton, styled } from "@mui/material";
import { Breadcrumbs as VoltBreadcrumbs } from "@volt_developers/components";
import { Link } from "react-router-dom";

import { useBreadcrumb } from "./useBreadcrumb";

import type { BreadcrumbItem } from "../../models/breadcrumbs";

export interface BreadcrumbProps extends Omit<BreadcrumbItem, "id"> {
  isLast: boolean;
}

export function Breadcrumb({
  title,
  url,
  "data-testid": dataTestId,
  isDisabled: isDisabledByConfig,
  isLast,
}: BreadcrumbProps) {
  const { isActive, isDisabled } = useBreadcrumb({
    url,
    isLast,
    isDisabledByConfig,
  });
  const titleElement = title ?? <Skeleton height={24} width={90} />;

  if (isActive || isDisabled || !url) {
    return (
      <VoltBreadcrumbs.Text data-testid={dataTestId} isActive={isActive}>
        {titleElement}
      </VoltBreadcrumbs.Text>
    );
  }

  return (
    <VoltBreadcrumbs.Link
      data-testid={dataTestId}
      sxWrapper={{
        "& > span": {
          padding: 0,
        },
      }}
    >
      <StyledRouterLink to={url}>{titleElement}</StyledRouterLink>
    </VoltBreadcrumbs.Link>
  );
}

const StyledRouterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
  display: "flex",
  padding: theme.spacing(0.5),
}));
