import { DashboardIcon } from "@volt_developers/components";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import type { Route } from "routes/RouteModel";

const Dashboard = lazyRetryWithPreload(
  () => import("../pages/Dashboard"),
  "Dashboard"
);

export const dashboardRoutes: Route = {
  title: COMMON_TRANSLATION_KEYS.dashboard,
  path: "/",
  icon: DashboardIcon,
  component: Dashboard,
  newTheme: true,
};
