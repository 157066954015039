import { Ability, AbilityBuilder } from "@casl/ability";
import { createContext } from "react";

import { ACTION_CREATE, ACTION_GLOBAL_FILTER } from "models/role/action";
import {
  SUBJECT_CUSTOMER,
  SUBJECT_USER_PRODUCT_INTEREST,
} from "models/role/subject";
import { isGroupNameCorrect  } from "modules/auth/constants/permissionGroups";

import { applyPermissions } from "./ability/applyPermissions";

import type { AbilityClass } from "@casl/ability";

import type { PERMISSION_GROUP_LIST_ALL } from "modules/auth/constants/permissionGroups";
import type { UUID } from "utils";

import type { AppAbilityType } from "./ability/ability";

export const AppAbility = Ability as AbilityClass<AppAbilityType>;

interface AbilityOptions {
  customerId?: UUID | null;
  hierarchyIds?: UUID[];
  permissionGroups?: readonly (PERMISSION_GROUP_LIST_ALL | string)[];
  userId?: UUID | null;
}

const definePermissionsFor = ({
  permissionGroups = [],
  userId = null,
  customerId = null,
  hierarchyIds = [],
}: AbilityOptions) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbilityType>(AppAbility);

  if (!userId) {
    return rules;
  }

  // Every role permissions

  if (customerId) {
    can(ACTION_CREATE, [SUBJECT_USER_PRODUCT_INTEREST]);
  }

  if (!customerId) {
    can(ACTION_GLOBAL_FILTER, SUBJECT_CUSTOMER);
  }

  for (const permissionGroup of permissionGroups) {
    if (!isGroupNameCorrect(permissionGroup)) {
      console.error(`Permission group ${permissionGroup} was not found.`);
      continue;
    }

    applyPermissions[permissionGroup]({
      ability: { can, cannot },
      userId,
      customerId,
      hierarchyIds,
    });
  }

  return rules;
};

export function defineAbilityFor({
  permissionGroups,
  userId,
  customerId,
  hierarchyIds,
}: AbilityOptions) {
  return new AppAbility(
    definePermissionsFor({
      permissionGroups,
      userId,
      customerId,
      hierarchyIds,
    })
  );
}

const defaultAbility = defineAbilityFor({});
export const AbilityContext = createContext(defaultAbility);
