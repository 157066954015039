import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { spacing } from "@material-ui/system";
import styled from "styled-components";

const CircularProgress = styled(MuiCircularProgress)(spacing);

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
`;

/**
 * @deprecated Do not use it in the new code base.
 * @todo Migrate it to MUI or change it in useBuildChildRoutes
 */
export function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" m={2} />
    </Root>
  );
}
