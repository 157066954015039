import { getErrorDetails } from "api/helpers/getErrorDetails";

import { axiosAuthInstance } from "./axiosAuthInstance";

import type { AxiosRequestConfig } from "axios";

import type { LoginApiResult } from "../store/utils";

export async function finalizeTwoFactorAuthEmail(
  code: number,
  options: AxiosRequestConfig
): Promise<LoginApiResult> {
  try {
    const response = await axiosAuthInstance({
      ...options,
      method: "POST",
      url: "/2fa/authenticate/email",
      data: {
        code,
      },
    });

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function resendEmail2FACode(
  options: AxiosRequestConfig
): Promise<void> {
  try {
    await axiosAuthInstance({
      ...options,
      method: "POST",
      url: "/2fa/code/resend",
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
}
