import { portalApi, TAGS } from "api/rtkApi";
import { SERVICES } from "constants/environment";

import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { RISK_SCORE_OPTION } from "../constants/Provider";
import type {
  CashManagementProviderCustomerConfig,
  ClearJunctionCredentials,
  IFXPaymentsCredentials,
  ISXFinancialCredentials,
} from "../models/ProviderCustomerConfig";

type GetProviderCustomerConfigResponse = CashManagementProviderCustomerConfig[];

type GetProviderCustomerConfigRequest = {
  customerId: UUID;
};

type PostProviderCustomerResponse = ObjectWithId;

type PostProviderCustomerRequest = {
  cashManagementProvider: UUID;
  credentials?:
    | IFXPaymentsCredentials
    | Omit<ISXFinancialCredentials, "payout" | "type">;
  customerId?: UUID;
  riskScore?: RISK_SCORE_OPTION | null;
};

type PutProviderCustomerResponse = ObjectWithId;

type PutProviderCustomerRequest = {
  cashManagementProvider: UUID;
  configId: UUID;
  credentials?:
    | ClearJunctionCredentials
    | IFXPaymentsCredentials
    | Omit<ISXFinancialCredentials, "type">;
  riskScore?: RISK_SCORE_OPTION;
};

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCashManagementCustomerConfig: builder.query<
      GetProviderCustomerConfigResponse,
      GetProviderCustomerConfigRequest
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/cash-management-provider/config`,
        method: "GET",
      }),
      providesTags: [TAGS.CASH_MANAGEMENT_CUSTOMER_CONFIG],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    createCashManagementCustomerConfig: builder.mutation<
      PostProviderCustomerResponse,
      PostProviderCustomerRequest
    >({
      query: ({
        cashManagementProvider,
        credentials,
        customerId,
        ...data
      }) => ({
        url: `/customers/${customerId}/cash-management-provider/config`,
        method: "POST",
        body: {
          cashManagementProvider,
          credentials,
          ...data,
        },
      }),
      invalidatesTags: [
        TAGS.CASH_MANAGEMENT_CUSTOMER_CONFIG,
        TAGS.CASH_MANAGEMENT_PROVIDERS,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    updateCashManagementCustomerConfig: builder.mutation<
      PutProviderCustomerResponse,
      PutProviderCustomerRequest
    >({
      query: ({ cashManagementProvider, credentials, configId, ...data }) => ({
        url: `/cash-management-provider/config/${configId}`,
        method: "PUT",
        body: {
          cashManagementProvider,
          credentials,
          ...data,
        },
      }),
      invalidatesTags: [
        TAGS.CASH_MANAGEMENT_CUSTOMER_CONFIG,
        TAGS.CASH_MANAGEMENT_PROVIDERS,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCashManagementCustomerConfigQuery,
  useCreateCashManagementCustomerConfigMutation,
  useUpdateCashManagementCustomerConfigMutation,
} = extendedApi;
