import { useSomethingWentWrongToast } from "hooks/useSomethingWentWrongToast";

import { useTranslatedToasts } from "../../../hooks/toasts/useTranslatedToasts";
import { useRejectRefundMutation } from "../api/Refunds";
import { REFUNDS_TOASTS } from "../constants/Toasts";

import type { UUID } from "../../../utils";

export function useRejectRefund() {
  const [rejectRefund, { isError, isLoading }] = useRejectRefundMutation();
  const { showRejectSuccessToast } = useTranslatedToasts(
    REFUNDS_TOASTS,
    "refunds"
  );

  useSomethingWentWrongToast(isError);

  const handleRejectRefund = async (refundId: UUID) => {
    await rejectRefund({ id: refundId }).unwrap();
    showRejectSuccessToast();
  };

  return { rejectRefund: handleRejectRefund, isRejectRefundLoading: isLoading };
}
