import { Users as UsersIcon } from "@volt_developers/react-ui-components";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_USER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import type { Route } from "routes/RouteModel";

const UserList = lazyRetry(() => import("../pages/List"), "UserManagementView");
const UserDetails = lazyRetry(
  () => import("../pages/Details"),
  "UserManagementView"
);
const UserEdit = lazyRetry(
  () => import("../pages/Edit"),
  "UserManagementManage"
);
const UserCreate = lazyRetry(
  () => import("../pages/Create"),
  "UserManagementManage"
);

export const userManagementRoutes: Route = {
  title: [COMMON_TRANSLATION_KEYS.users, COMMON_TRANSLATION_KEYS.list],
  icon: UsersIcon,
  path: "/configuration/users",
  component: UserList,
  newTheme: true,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_USER,
  },
  children: [
    {
      path: "/configuration/users/create",
      title: [COMMON_TRANSLATION_KEYS.users, COMMON_TRANSLATION_KEYS.new],
      component: UserCreate,
      newTheme: true,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_USER,
      },
    },
    {
      path: "/configuration/users/:id/edit",
      title: [COMMON_TRANSLATION_KEYS.users, COMMON_TRANSLATION_KEYS.edit],
      component: UserEdit,
      newTheme: true,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_USER,
      },
    },
    {
      path: "/configuration/users/:id",
      title: [COMMON_TRANSLATION_KEYS.users, COMMON_TRANSLATION_KEYS.details],
      component: UserDetails,
      newTheme: true,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_USER,
      },
    },
  ],
};
