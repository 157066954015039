import {
  UnauthorizedError,
  WebauthnRequestCancelledError,
} from "@teamhanko/hanko-frontend-sdk";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useTranslatedToasts } from "hooks/toasts/useTranslatedToasts";
// eslint-disable-next-line no-restricted-imports
import { useLoading } from "modules/common/hooks/useLoading";

import { AUTH_TOASTS } from "../constants/AuthToasts";
import { ERRORS } from "../constants/Errors";
import { useSCAContext } from "../context/SCAContext";
import { getSCATranslationKey } from "../helpers/getSCATranslationKey";
import { SCA_ERRORS } from "../services/sca";
import {
  loginSCA,
  selectAccessToken,
  selectSCASubjectId,
} from "../store/auth.slice";
import { useCurrentUser } from "./useCurrentUser";
import { useIsCurrentUserVoltEmployee } from "./useIsCurrentUserVoltEmployee";
import { useSCASetup } from "./useSCASetup";

export function useSCA() {
  const { t } = useTranslation(["auth", "common"]);
  const { currentUser } = useCurrentUser();
  const { sca } = useSCAContext();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const { isLoading, withLoading } = useLoading();
  const { initializeSCASetup, finalizeSCA, finalizeSCASetup } = useSCASetup();

  const subjectIdFromToken = useSelector(selectSCASubjectId);
  const subjectId =
    currentUser?.scaAuthentication?.subjectId ?? subjectIdFromToken;
  const hasSCA = Boolean(currentUser?.scaAuthentication?.active);
  const isVoltEmployee = useIsCurrentUserVoltEmployee();
  const token = useSelector(selectAccessToken);
  const controller = useRef(new AbortController());
  const {
    showScaConfigurationSuccessToast,
    showScaAuthenticateFailureToast,
    showScaConfigurationFailureToast,
    showScaLoginFailureToast,
  } = useTranslatedToasts(AUTH_TOASTS, "auth");

  const setupSCA = withLoading(async () => {
    cleanupError();

    if (!currentUser?.email || !currentUser?.id) {
      return;
    }

    try {
      const initializeResult = await initializeSCASetup();

      if (initializeResult.data === undefined) {
        throw new Error(ERRORS.SCA_INITIALIZATION);
      }

      const credential = await sca?.createPasskey(initializeResult.data);

      if (!credential) {
        throw new Error(ERRORS.SCA_CREATION);
      }

      const finalizeResult = await finalizeSCASetup({
        id: currentUser.id,
        credential,
      });

      if (!("data" in finalizeResult)) {
        throw new Error(ERRORS.SCA_FINALIZATION);
      }

      showScaConfigurationSuccessToast();
    } catch (error) {
      showScaConfigurationFailureToast(
        t(getSCATranslationKey(error.message ?? "default"), { ns: "common" })
      );
    }
  });

  const authenticateSCAAction = useCallback(async (): Promise<
    string | undefined
  > => {
    cleanupError();

    if (!subjectId || !sca) {
      return;
    }

    try {
      const signal = controller.current.signal;
      const credential = await sca.getPasskey(signal);

      const { id, type, rawId, response } = credential;
      const scaToken = await sca.authenticate({
        id,
        type,
        rawId,
        response,
      });

      return scaToken;
    } catch (error) {
      showScaAuthenticateFailureToast(
        t(getSCATranslationKey(error.message ?? "default"), { ns: "common" })
      );
    }
  }, [sca, showScaAuthenticateFailureToast, subjectId, t]);

  const login = useMemo(
    () =>
      withLoading(async () => {
        cleanupError();

        if (!subjectId || !sca) {
          return;
        }

        try {
          const scaToken = await authenticateSCAAction();

          if (token && scaToken) {
            const result = await finalizeSCA({
              scaToken,
              bearerToken: token,
            });

            if (result.data === undefined) {
              throw new Error(ERRORS.SCA_FINALIZATION);
            }

            dispatch(loginSCA(result.data));
          }
        } catch (error) {
          console.error("hanko error", error);

          if (error instanceof WebauthnRequestCancelledError) {
            // The WebAuthn API failed. Usually in this case the user cancelled the WebAuthn dialog.
            setError(SCA_ERRORS.CANCEL);

            return;
          }

          if (error instanceof UnauthorizedError) {
            // The user needs to login to perform this action
            setError(SCA_ERRORS.UNAUTHORIZED);

            return;
          }

          showScaLoginFailureToast(
            t(getSCATranslationKey(error.message ?? "default"), {
              ns: "common",
            })
          );
        }
      }),
    [
      authenticateSCAAction,
      dispatch,
      finalizeSCA,
      sca,
      showScaLoginFailureToast,
      subjectId,
      t,
      token,
      withLoading,
    ]
  );

  const cancel = useCallback(() => {
    controller.current.abort();
  }, [controller]);

  const cleanupError = () => setError(null);

  const authenticateWithLoading = withLoading(authenticateSCAAction);

  return {
    shouldShowCard: !isVoltEmployee,
    setupSCA,
    hasSCA,
    login,
    authenticateSCAAction: authenticateWithLoading,
    error,
    isLoading,
    cancel,
    cleanupError,
  };
}
