import type { CurrencyCode } from "models/CurrencyCode";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

export const PROPERTY_SANDBOX_SETTLEMENT_BATCHES = "sandbox.settlementBatches";

export const PROPERTY_PRODUCTION_SETTLEMENT_BATCHES =
  "production.settlementBatches";

export const PROPERTY_PRODUCTION_SETTLEMENT_BALANCE =
  "production.settlementBalance";

export const PROPERTY_SANDBOX_SETTLEMENT_BALANCE = "sandbox.settlementBalance";

export const PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG =
  "production.settlementScheduleConfig";

export const PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG =
  "sandbox.settlementScheduleConfig";

export const PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION =
  "production.settlementConfiguration";

export const PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION =
  "sandbox.settlementConfiguration";

export type TriggerSettlementCode =
  | "creatingSettlementInitiated"
  | "noUnsettledOperations";

export interface SettlementLimit {
  limit: number;
}

export interface SettlementBalance {
  amount: number;
}

export type SettlementStatus =
  | "COMPLETED"
  | "CREATED"
  | "FAILED_CANCELED"
  | "FAILED"
  | "INITIATED"
  | "REJECTED";

export type SettlementTriggerType = "AUTOMATIC" | "MANUAL";

export interface Settlement extends ObjectWithId {
  accountId: UUID;
  amount: number;
  createdAt: string;
  currency: CurrencyCode;
  customerId: UUID;
  nickname: string;
  reportUrl: string | null;
  settledAt: string | null;
  status: SettlementStatus;
  triggeredType: SettlementTriggerType;
}

export type SettlementDetails = ObjectWithId &
  Pick<
    Settlement,
    | "accountId"
    | "amount"
    | "currency"
    | "customerId"
    | "reportUrl"
    | "status"
    | "triggeredType"
  > & {
    accountNickname: string | null;
    createdAt: string;
    statusHistory: SettlementTimeline;
    reference: string;
    destinationAccountId: string;
    destinationAccountIdentificationList: SettlementDestinationAccountIdentification;
  };

export type SettlementDestinationAccountIdentification = {
  country: string;
  iban?: string;
  accountNumber?: string;
  sortCode?: string;
  bic?: string;
};

export type SettlementTimeline = Array<{
  createdAt: string;
  newStatus: SettlementStatus;
  oldStatus: SettlementStatus | null;
}>;

export type OrderableColumns = "amount" | "createdAt" | "currency" | "status";
