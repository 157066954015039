import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_LIST } from "models/role/action";
import { SUBJECT_CIRCUIT_BREAKER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { ComponentType } from "react";

import type { Route } from "routes/RouteModel";

const BlocklistProxy = lazyRetry(
  () => import("../pages"),
  "CircuitBreakerProxy",
  (module: { BlocklistProxy: ComponentType<any> }) => ({
    default: module.BlocklistProxy,
  })
);

export const blocklistRoute: Route = {
  title: [COMMON_TRANSLATION_KEYS.blocklist],
  newTheme: true,
  path: PAGE_URLS.blocklist,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CIRCUIT_BREAKER,
  },
  component: BlocklistProxy,
};
