import { ButtonDeprecated } from "@volt_developers/components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { TEST_IDS } from "../../constants/TestIds";

import type { ButtonDeprecatedProps } from "@volt_developers/components";

type RedirectableCancel = {
  cancelUrl?: string;
  handleCancelClick?: never;
};

type ClickableCancel = {
  cancelUrl?: never;
  handleCancelClick?: () => void;
};

export type CancelButtonProps = {
  cancelDisabled?: boolean;
} & (ClickableCancel | RedirectableCancel);

export function CancelButton({
  cancelUrl,
  handleCancelClick,
  cancelDisabled,
}: CancelButtonProps) {
  if (cancelUrl) {
    return (
      <ButtonComponent
        component={Link}
        disabled={cancelDisabled}
        to={cancelUrl}
      />
    );
  }

  if (handleCancelClick) {
    return (
      <ButtonComponent disabled={cancelDisabled} onClick={handleCancelClick} />
    );
  }

  return null;
}

function ButtonComponent<T extends React.ElementType = "button">(
  props: ButtonDeprecatedProps<T>
) {
  const { t } = useTranslation("common");

  return (
    <ButtonDeprecated
      color="grey"
      data-testid={TEST_IDS.formCancelButton}
      size="medium"
      sx={{ mr: 3 }}
      variant="outlined"
      {...props}
    >
      {t(COMMON_TRANSLATION_KEYS.cancel)}
    </ButtonDeprecated>
  );
}
