export const ORDER_ASC: "asc" = "asc";
export const ORDER_DESC: "desc" = "desc";
export type Order = typeof ORDER_ASC | typeof ORDER_DESC;

export interface List<T> {
  list: T[];
}

export interface PaginatedList<T> extends List<T> {
  currentLimit: number;
  currentPage: number;
  totalItems: number;
}

interface PaginationAndSortInputProps<T> {
  limit: number;
  page: number;
  order?: Order;
  orderBy?: "createdAt" | keyof T;
}

interface PaginationAndSortOutputProps extends Object {
  page: number;
  limit?: number;
}

export function addPaginationAndSort<T>(
  props: PaginationAndSortInputProps<T>
): PaginationAndSortOutputProps {
  const { page = 0, order = null, orderBy = null, limit } = props;

  let params: PaginationAndSortOutputProps = {
    page: page + 1,
  };

  if (limit) {
    params.limit = limit;
  }

  // Should be generic and typed
  if (order && orderBy && typeof orderBy === "string") {
    let property = `order[${orderBy}]`;
    params = {
      ...params,
      [property]: order === ORDER_ASC ? "ASC" : "DESC",
    };
  }

  return params;
}

export function getPaginationInfoFromHeaders(headers: any) {
  const totalItems = parseInt(headers["total-items"], 10);
  const currentPage = parseInt(headers["page"], 10) - 1;
  const currentLimit = parseInt(headers["limit"], 10);

  return {
    totalItems,
    currentPage,
    currentLimit,
  };
}
