import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";

import { addPaginationAndSort, ORDER_ASC } from "./pagination";

import type {
  Payment,
  PaymentsQueryParams,
} from "modules/payments/models/Payment";

export type QueryParamsWithFilters = PaymentsQueryParams & {
  orderBy: keyof Payment | undefined;
};

export function formatApiDate(date: DateTime) {
  return date.toFormat("yyyyLLddHHmmss");
}

export function addSort(
  parameters: Record<string, any>,
  queryParams: QueryParamsWithFilters
) {
  let params = {};
  const { order, orderBy } = queryParams;

  params = {
    ...parameters,
    [`order[${orderBy}]`]: order === ORDER_ASC ? "ASC" : "DESC",
  };

  return params;
}

/**
 * @deprecated This one should not be used for RTK API
 */
export function createFilterParams(
  queryParams: QueryParamsWithFilters,
  { pagination = false }
) {
  const {
    statuses,
    startDate,
    endDate,
    minAmount,
    maxAmount,
    search,
    country,
    currency,
    customerHierarchyId,
    licences,
    noBankChosen,
  } = queryParams;

  let params: Record<string, any> = pagination
    ? addPaginationAndSort<Payment>(queryParams)
    : {};

  if (!pagination && queryParams.order && queryParams.orderBy) {
    params = addSort(params, queryParams);
  }

  if (startDate) {
    const luxonStartDate = DateTime.fromISO(startDate).startOf("day");

    params["createdAt[gte]"] = formatApiDate(luxonStartDate);
  }

  if (endDate) {
    const luxonEndDate = DateTime.fromISO(endDate).endOf("day");

    params["createdAt[lte]"] = formatApiDate(luxonEndDate);
  }

  if (!isEmpty(search)) {
    params["search"] = search;

    return params;
  }

  if (noBankChosen) {
    params["showAll"] = 1;
  }

  if (!isEmpty(statuses)) {
    params.status = statuses;
  }

  if (!isEmpty(licences)) {
    params.licence = licences;
  }

  if (!isNil(minAmount)) {
    params["amount[gte]"] = minAmount;
  }

  if (!isNil(maxAmount)) {
    params["amount[lte]"] = maxAmount;
  }

  if (country) {
    params["bank[country]"] = country;
  }

  if (currency) {
    params["currency"] = currency;
  }

  if (!isNil(customerHierarchyId)) {
    params["customerHierarchyId"] = customerHierarchyId;
  }

  return params;
}
