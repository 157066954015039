import { MUI_Z_INDEX } from "modules/common/utils/zIndex";

import type { ZIndex } from "@mui/material/styles/zIndex";

export const zIndex = {
  mobileStepper: MUI_Z_INDEX.MOBILE_STEPPER,
  fab: MUI_Z_INDEX.FAB,
  speedDial: MUI_Z_INDEX.SPEED_DIAL,
  appBar: MUI_Z_INDEX.APP_BAR,
  drawer: MUI_Z_INDEX.DRAWER,
  modal: MUI_Z_INDEX.MODAL,
  snackbar: MUI_Z_INDEX.SNACKBAR,
  tooltip: MUI_Z_INDEX.TOOLTIP,
} satisfies ZIndex;
