import isEqual from "lodash/isEqual";
import { useCallback, useRef } from "react";

import type { DependencyList, EffectCallback } from "react";

function deepCompareEquals(itemA: unknown, itemB: unknown) {
  return isEqual(itemA, itemB);
}

function useDeepCompareMemoize(value: DependencyList) {
  const ref = useRef<DependencyList>([]);

  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

/**
 * @deprecated Do not use it in the new lists code base. It was used for operation states and fetching data - right now RTK Query cares about caching and status flags
 */
export function useDeepCompareCallback(
  callback: EffectCallback,
  dependencies: DependencyList
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, useDeepCompareMemoize(dependencies));
}
