import i18n from "i18next";
import each from "lodash/each";
import { initReactI18next } from "react-i18next";

import dateTimeFormats from "constants/dateTimeFormats";

import enGb from "./en-GB";
import {
  formatCurrency,
  formatDateTime,
  formatRelativeDateTime,
} from "./utils";

i18n.use(initReactI18next).init({
  resources: [],
  lng: "en-GB",
  // TODO: re-enable before starting to support other languages
  // debug: process.env.NODE_ENV || process.env.NODE_ENV === "development",
  debug: false,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
    format: function (value, format, lng) {
      if (dateTimeFormats.includes(format)) {
        return value ? formatDateTime(value, format, lng) : null;
      }

      switch (format) {
        case "RELATIVE_DATE_TIME":
          return formatRelativeDateTime(value, lng);
        case "CURRENCY":
          return formatCurrency(value, lng);
        default:
          return value;
      }
    },
  },
});

each(enGb, (translations, key) => {
  i18n.addResourceBundle("en-GB", key, translations);
});

export default i18n;
