import { styled, Typography } from "@mui/material";
import { Tag } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import type { PropsWithChildren } from "react";

export interface ConfigureCardTagProps extends PropsWithChildren {
  "data-testId"?: string;
  isConfigured?: boolean;
}

export function ConfigureCardTag({
  isConfigured = false,
  "data-testId": dataTestid,
  children,
}: ConfigureCardTagProps) {
  const { t } = useTranslation("common");

  if (isConfigured) {
    return null;
  }

  return (
    <TagWrapper>
      <Typography variant="h7">{children}</Typography>
      <Tag data-testid={dataTestid} label={t(COMMON_TRANSLATION_KEYS.notConfigured)} inactive />
    </TagWrapper>
  );
}

const TAG_WRAPPER_WIDTH = 130;

const TagWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minWidth: TAG_WRAPPER_WIDTH,
  gap: theme.spacing(1),
}));
