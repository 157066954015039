import { ActionBar } from "@volt_developers/components";
import { createPortal } from "react-dom";

import { usePageWrapperRef } from "./PageWrapperWithRefContext";

import type { ReactNode } from "react";

export interface PageActionBarProps {
  children: ReactNode;
  isOpen: boolean;
}

export function PageActionBar({ children, isOpen }: PageActionBarProps) {
  const { ref } = usePageWrapperRef();

  if (!isOpen || !ref.current) {
    return null;
  }

  return createPortal(
    <ActionBar.Wrapper childrenArrangement="space-between" position="bottom">
      {children}
    </ActionBar.Wrapper>,
    ref.current
  );
}
