import {
  ButtonDeprecated,
  ToastDeprecated as VoltToast,
} from "@volt_developers/components";
import { toast } from "react-toastify";

import { useToast } from "hooks/toasts/useToast";

import type { ReactNode } from "react";
import type { ToastOptions } from "react-toastify";

import type { ToastVariant } from "hooks/toasts/useToast";

export type ToastProps = SingleButtonToastProps & {
  config?: ToastOptions;
};

export interface SingleButtonToastProps {
  bodyContent: ReactNode;
  buttonText: ReactNode;
  handleClick: () => void;
  variant: ToastVariant;
  toastProps?: ToastOptions;
}

export function SingleButtonToast({
  bodyContent,
  buttonText,
  handleClick,
  toastProps,
  variant,
}: SingleButtonToastProps) {
  const { buttonVariant, buttonColor, iconComponent } = useToast(variant);

  return (
    <VoltToast
      data-testid={(toastProps?.toastId as string) ?? "toast"}
      variant={variant}
    >
      <VoltToast.RootWrapper>
        <VoltToast.IconWrapper>{iconComponent}</VoltToast.IconWrapper>
        <VoltToast.ContentWrapper>
          {bodyContent}
          <VoltToast.ContentFooter>
            <ButtonDeprecated
              color={buttonColor}
              variant={buttonVariant}
              onClick={handleClick}
            >
              {buttonText}
            </ButtonDeprecated>
          </VoltToast.ContentFooter>
        </VoltToast.ContentWrapper>
      </VoltToast.RootWrapper>
    </VoltToast>
  );
}

export const showSingleButtonToast = ({
  config,
  ...propsForToast
}: ToastProps) => toast(<SingleButtonToast {...propsForToast} />, config);
