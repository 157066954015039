import { common } from "i18n/en-GB/common";
import { createTranslationKeysObject } from "utils/createTranslationKeysObject";

export const COMMON_TRANSLATION_KEYS = createTranslationKeysObject(common);

/**
 * @deprecated Do not use it in the new code base.
 * Use ns: 'common' option of useTranslation hook
 */
export function getFromCommonTranslationKeys(
  key: keyof typeof COMMON_TRANSLATION_KEYS
) {
  return `common:${key}`;
}
