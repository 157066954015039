import { DateTime } from "luxon";
import * as Yup from "yup";

import { DATE_FORMAT_DD_MM_YYYY } from "utils/time";

declare module "yup" {
  export interface StringSchema {
    validateDate(message: string, format?: string): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateDate",
  function (message, format = DATE_FORMAT_DD_MM_YYYY) {
    return this.test(
      "validate-date",
      message,
      function validateDate(value?: string) {
        if (typeof value !== "string") {
          return false;
        }

        const parsedDate = DateTime.fromFormat(value, format);

        return parsedDate.isValid;
      }
    );
  }
);
