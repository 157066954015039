import { createContext, useContext, useMemo } from "react";

import { SCA } from "../services/sca";

import type { ReactNode} from "react";

type SCAContextData = {
  sca: SCA | null;
};

type SCAProviderProps = { children: ReactNode };

const SCAContextInitialState = {
  sca: null,
};

const SCAContext = createContext<SCAContextData>(SCAContextInitialState);

export function SCAProvider({ children }: SCAProviderProps) {
  const sca = useMemo(() => {
    const baseUrl = import.meta.env.FUZEBOX_HANKO_API_URL;
    const tenantId = import.meta.env.FUZEBOX_HANKO_TENANT_ID;

    if (!baseUrl || !tenantId) {
      return null;
    }

    return new SCA({
      baseUrl,
      tenantId,
    });
  }, []);

  return (
    <SCAContext.Provider
      value={{
        sca,
      }}
    >
      {children}
    </SCAContext.Provider>
  );
}

export function useSCAContext() {
  const scaData = useContext(SCAContext);

  if (!scaData) {
    throw new Error("useSCAContext should be wrapped in useSCAContextProvider");
  }

  return scaData;
}
