import { useParams } from "react-router-dom";

import { useAbility } from "hooks/useAbility";
import { useActive } from "hooks/useActive";
import { useEnvironmentStatus } from "hooks/useEnvironmentStatus";
import { ACTION_APPROVE } from "models/role/action";
import { SUBJECT_REFUND } from "models/role/subject";
import { useSCA } from "modules/auth/hooks/useSCA";

import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";
import { useApproveRefund } from "../../../hooks/useApproveRefund";

import type { ObjectWithId } from "utils/ObjectWithId";

export function useApproveButton() {
  const { approveRefund, isApproveRefundLoading } = useApproveRefund();
  const { id } = useParams<ObjectWithId>();
  const { hasSCA, isLoading: isSCALoading } = useSCA();
  const { isSandbox, isProduction } = useEnvironmentStatus();
  const ability = useAbility();
  const { isActive, toggleActive } = useActive(false);

  const canApproveRefund = ability.can(ACTION_APPROVE, SUBJECT_REFUND);

  const tooltipTitleTranslationKey = getTooltipTitleTranslationKey(
    hasSCA,
    isSandbox
  );

  const isDisabled = Boolean(tooltipTitleTranslationKey);

  const handleButtonClick = async () => {
    if (isProduction) {
      toggleActive();
    } else {
      await approveRefund(id);
    }
  };

  const handleApproveRefund = async (scaToken?: string) => {
    await approveRefund(id, scaToken);
  };

  const isVisible = canApproveRefund;

  const isLoading = isSCALoading || isApproveRefundLoading;

  return {
    handleButtonClick,
    isVisible,
    tooltipTitleTranslationKey,
    isDisabled,
    isLoading,
    isModalActive: isActive,
    toggleIsModalActive: toggleActive,
    handleApproveRefund,
  };
}

function getTooltipTitleTranslationKey(hasSCA: boolean, isSandbox: boolean) {
  if (isSandbox || hasSCA) {
    return null;
  }

  return TRANSLATION_KEYS.scaRequiredToApproveARefund;
}
