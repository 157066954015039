import { createAction, createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { Bank } from "modules/banks/models/banks";
import type { RootState } from "store";
import type { ObjectWithId } from "utils/ObjectWithId";

import type {
  BlockList,
  RiskOverviewResponse,
  RiskScore,
} from "../../models/RiskOverview";

interface SliceState {
  blockList: (BlockList | Pick<BlockList, "ruleResults">) & {
    bank?: Bank;
  };
  riskScore:
    | Pick<RiskScore, "matchedRules" | "score" | "threshold">
    | RiskScore;
}

export const initialState: SliceState = {
  blockList: {
    ruleResults: [],
  },
  riskScore: {
    matchedRules: [],
    score: 0,
    threshold: 0,
  },
};

export const riskOverviewOperationPaths = {
  details: ["riskOverview"],
};

export const slice = createSlice({
  name: "riskOverview",
  initialState,
  reducers: {
    setRiskOverviewData: (
      state,
      action: PayloadAction<RiskOverviewResponse>
    ) => {
      if (Array.isArray(action.payload)) {
        state.blockList.ruleResults = [];
        state.riskScore.matchedRules = [];
      } else {
        state.blockList = action.payload.blockList;
        state.riskScore = action.payload.riskScore;
      }
    },
    setRiskOverviewBank: (state, action: PayloadAction<Bank | undefined>) => {
      state.blockList.bank = action.payload;
    },
    clearRiskOverviewData: (state) => {
      state.blockList = { ruleResults: [] };
      state.riskScore = { matchedRules: [], score: 0, threshold: 0 };
    },
  },
});

export const {
  setRiskOverviewData,
  setRiskOverviewBank,
  clearRiskOverviewData,
} = slice.actions;

export const fetchRiskOverview =
  createAction<ObjectWithId>("fetchRiskOverview");

export const selectBlockList = (state: RootState): SliceState["blockList"] =>
  state.riskOverview.blockList;
export const selectRiskScore = (state: RootState): SliceState["riskScore"] =>
  state.riskOverview.riskScore;
export const selectOverallRisk = (state: RootState) => {
  const { score, threshold } = state.riskOverview.riskScore;

  return { score, threshold };
};

export const riskOverviewReducer = slice.reducer;
