import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { showMessageToast } from "components/toasts/MessageToast";
import { showSingleButtonToast } from "components/toasts/SingleButtonToast";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import type { ReactNode } from "react";

export interface UseSomethingWentWrongToastButtonOptions {
  buttonText: ReactNode;
  handleClick: () => void;
}

export function useSomethingWentWrongToast(
  isError: boolean,
  buttonOptions?: UseSomethingWentWrongToastButtonOptions,
  toastId?: string
) {
  const { t } = useTranslation("common");
  const { buttonText, handleClick } = buttonOptions ?? {};
  const bodyContent = t(COMMON_TRANSLATION_KEYS.sorrySomethingWentWrong);

  useEffect(() => {
    if (!isError) {
      return;
    }

    if (buttonText && handleClick) {
      showSingleButtonToast({
        bodyContent,
        variant: "failure",
        buttonText: buttonText,
        handleClick: handleClick,
        config: { toastId },
      });

      return;
    }

    showMessageToast({
      bodyContent,
      variant: "failure",
      config: { toastId },
    });
  }, [isError, buttonText, handleClick, bodyContent, toastId]);
}
