import { ToastDeprecated as VoltToast } from "@volt_developers/components";
import { toast } from "react-toastify";

import { useToast } from "hooks/toasts/useToast";

import type { ReactNode } from "react";
import type { ToastOptions } from "react-toastify";

import type { ToastVariant} from "hooks/toasts/useToast";

export interface ToastProps {
  bodyContent: ReactNode;
  variant: ToastVariant;
  config?: ToastOptions;
}

interface MessageToastProps {
  bodyContent: ReactNode;
  variant: ToastVariant;
  toastProps?: ToastOptions;
}

export function MessageToast({
  bodyContent,
  variant,
  toastProps,
}: MessageToastProps) {
  const { iconComponent } = useToast(variant);

  return (
    <VoltToast
      data-testid={(toastProps?.toastId as string) ?? "toast"}
      variant={variant}
    >
      <VoltToast.RootWrapper>
        <VoltToast.IconWrapper>{iconComponent}</VoltToast.IconWrapper>
        <VoltToast.ContentWrapper>{bodyContent}</VoltToast.ContentWrapper>
      </VoltToast.RootWrapper>
    </VoltToast>
  );
}

export const showMessageToast = ({ config, ...propsForToast }: ToastProps) => {
  return toast(<MessageToast {...propsForToast} />, config);
};
