import { Link, makeStyles, withTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@volt_developers/react-ui-components";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useOperationState } from "hooks/operationsState";
import { useTranslatedToasts } from "hooks/toasts/useTranslatedToasts";
import { useCurrentUser } from "modules/auth/hooks/useCurrentUser";
import { logoutRoute } from "modules/auth/routes/logout";

import { TEST_IDS } from "../../constants/testIds";
import { TOASTS } from "../../constants/Toasts";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { useTermsAndConditions } from "../../hooks/useTermsAndConditions";
import {
  acceptLicenceAgreement,
  operationPath as operationPaths,
} from "../../store";

import type { ChangeEvent, UIEvent } from "react";

const PRIVACY_STATEMENT_URL = "https://www.volt.io/generalprivacy";
const READ_LICENCE_THRESHOLD_IN_PX = 10;

interface AgreeAndAccessButtonProps {
  disabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
}

interface LicenceAgreementContentProps {
  onIsLicenceRead: () => void;
}

function LogoutButton() {
  const { t } = useTranslation("userSettings");
  const history = useHistory();

  const onClick = () => {
    history.push(logoutRoute.path);
  };

  return (
    <Button
      data-testid={TEST_IDS.termsAndConditionsLogoutButton}
      variant="outlined"
      onClick={onClick}
    >
      {t(TRANSLATION_KEYS.logout)}
    </Button>
  );
}

const AgreeAndAccessButton = forwardRef<
  HTMLSpanElement, // react-ui-component's button does not forward ref
  AgreeAndAccessButtonProps
>(function AgreeAndAccessButton({ isLoading, disabled, onSubmit }, ref) {
  const { t } = useTranslation("userSettings");

  return (
    <Button
      color="primary"
      data-testid={TEST_IDS.termsAndConditionsAcceptButton}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onSubmit}
    >
      <span ref={ref}>{t(TRANSLATION_KEYS.agreeAndAccess)}</span>
    </Button>
  );
});

function LicenceAgreementContent({
  onIsLicenceRead: onIsRead,
}: LicenceAgreementContentProps) {
  const wrapperRef = useRef<HTMLElement>();
  const licenceRef = useRef<HTMLPreElement | null>(null);
  const { operationSuccess: licenceAgreementFileOperationSuccess } =
    useOperationState(operationPaths.licenceAgreementFile);

  const classes = useStyles();
  const {
    licenceAgreement: { file },
  } = useTermsAndConditions();

  useEffect(() => {
    const wrapperHeight = wrapperRef.current?.offsetHeight ?? 0;
    const licenceHeight = licenceRef.current?.offsetHeight ?? 0;

    const licenceCantBeScrolled = wrapperHeight > licenceHeight;

    if (licenceAgreementFileOperationSuccess && licenceCantBeScrolled) {
      onIsRead();
    }
  }, [wrapperRef, licenceRef, onIsRead, licenceAgreementFileOperationSuccess]);

  if (!file) {
    return (
      <DialogContent
        classes={{ root: classes.dialogContent }}
        data-testid={TEST_IDS.termsAndConditionsContent}
      >
        <Skeleton width="100%" />
      </DialogContent>
    );
  }

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop, clientHeight },
  }: UIEvent<HTMLDivElement>) => {
    const bottom =
      scrollHeight - scrollTop < clientHeight + READ_LICENCE_THRESHOLD_IN_PX;

    if (bottom) {
      onIsRead();
    }
  };

  const parsedContent = file.content
    .trim()
    .split("\n")
    .filter(Boolean)
    .join("\n\n");

  return (
    <DialogContent
      classes={{ root: classes.dialogContent }}
      data-testid={TEST_IDS.termsAndConditionsContent}
      innerRef={wrapperRef}
      onScroll={handleScroll}
    >
      <LicenceAgreementContentTitle>{file.title}</LicenceAgreementContentTitle>
      <pre className={classes.pre} ref={licenceRef}>
        {parsedContent}
      </pre>
    </DialogContent>
  );
}

export function TermsAndConditionsDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();

  const [isLicenceRead, setIsLicenceRead] = useState(false);
  const [isLicenceAccepted, setIsLicenceAccepted] = useState(false);

  const buttonRef = useRef<HTMLSpanElement>(null);

  const operationPath = operationPaths.acceptLicenceAgreement;
  const { operationLoading: acceptLicenceAgreementLoading } =
    useOperationState(operationPath);

  const { t } = useTranslation("userSettings");
  const { showNotReadLicenceAgreementToast } = useTranslatedToasts(
    TOASTS,
    "userSettings"
  );

  const doesUserNeedToUpdateAcceptance = Boolean(
    currentUser?.metadata?.termsAcceptanceDate
  );

  const dialogTitle = doesUserNeedToUpdateAcceptance
    ? t(TRANSLATION_KEYS.actionRequiredWeHaveUpdated)
    : t(TRANSLATION_KEYS.evaluationLicenceAgreementForVolt);

  const onSubmit = () => {
    dispatch(acceptLicenceAgreement());
  };

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLicenceAccepted(event.target.checked);

    if (event.target.checked && buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onCheckboxClick = (event: MouseEvent) => {
    const isLinkClicker = event.target instanceof HTMLAnchorElement;

    if (!isLicenceRead && !isLinkClicker) {
      showNotReadLicenceAgreementToast();
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      data-testid={TEST_IDS.termsAndConditionsDialog}
      open
    >
      <StyledDialogTitle data-testid={TEST_IDS.termsAndConditionsTitle}>
        {dialogTitle}
      </StyledDialogTitle>
      <StyledDialogSubtitle data-testid={TEST_IDS.termsAndConditionsSubtitle}>
        {t(TRANSLATION_KEYS.pleaseReadCarefullyBeforeAccessing)}
      </StyledDialogSubtitle>
      <StyledDialogDescription
        data-testid={TEST_IDS.termsAndConditionsDescription}
      >
        {t(TRANSLATION_KEYS.toAccessTheVoltSandboxEnvironment)}
      </StyledDialogDescription>

      <LicenceAgreementContent onIsLicenceRead={() => setIsLicenceRead(true)} />
      <StyledCheckbox
        data-testid={TEST_IDS.termsAndConditionsCheckbox}
        disabled={!isLicenceRead}
        variant="outlined"
        label={
          <Trans i18nKey="licenceAgreementDialogCheckboxLabel" t={t}>
            I have read and agree to the Volt Evaluation Licence and{" "}
            <Link component="a" href={PRIVACY_STATEMENT_URL} target="_blank">
              privacy statement
            </Link>
            , and I am of legal age and confirm that I am authorised to enter
            into this agreement on the Company’s behalf, and bind the Company to
            its terms and conditions.
          </Trans>
        }
        onChange={onCheckboxChange}
        onClick={onCheckboxClick}
      />
      <StyledButtonsWrapper>
        <LogoutButton />
        <AgreeAndAccessButton
          disabled={!isLicenceAccepted}
          isLoading={acceptLicenceAgreementLoading}
          ref={buttonRef}
          onSubmit={onSubmit}
        />
      </StyledButtonsWrapper>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    maxWidth: 640,
    color: "#56606B",
  },
  pre: {
    whiteSpace: "pre-line",
    fontSize: 12,
    fontWeight: "normal",
    margin: 0,
  },
  dialogContent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    minHeight: "30vh",
    maxHeight: 288,
  },
}));

const StyledDialogTitle = withTheme(styled(DialogTitle)`
  padding: 0;

  h3 {
    color: #363a40;
    text-align: left;
    margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
  }
`);

const StyledButtonsWrapper = withTheme(styled.div`
  margin: ${({ theme }) => theme.spacing(3, 0, 0, 0)};

  button {
    margin: ${({ theme }) => theme.spacing(0, 3, 0, 0)};
  }
`);

const StyledCheckbox = withTheme(styled(Checkbox)`
  margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};

  .MuiIconButton-root {
    align-self: flex-start;
  }

  .MuiFormControlLabel-label {
    line-height: 16px;
    font-size: 12px;
  }
`);

const StyledDialogSubtitle = withTheme(styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 1.5, 0)};
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  color: #363a40;
  font-family: ${(props) => props.theme.typography.fontFamily};
`);

const StyledDialogDescription = withTheme(styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
`);
const LicenceAgreementContentTitle = withTheme(styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
  font-family: ${(props) => props.theme.typography.fontFamily};
`);
