import { Dialog } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { DialogActions } from "./DialogActions";
import { DialogContent } from "./DialogContent";
import { useRejectRefundConfirmModal } from "./useRejectRefundConfirmModal";

export interface RejectRefundConfirmModalProps {
  isOpen: boolean;
  onClose(): void;
}

export function RejectRefundConfirmModal({
  isOpen,
  onClose,
}: RejectRefundConfirmModalProps) {
  const { handleConfirm, isLoading } = useRejectRefundConfirmModal({ onClose });

  const { t } = useTranslation("refunds");

  return (
    <Dialog.Wrapper maxWidth="xl" open={isOpen}>
      <Dialog.Title sx={{ pt: 3, pb: 0.5 }} onClose={onClose}>
        {t(TRANSLATION_KEYS.rejectingRefund)}
      </Dialog.Title>
      <DialogContent />
      <DialogActions
        isLoading={isLoading}
        onCancel={onClose}
        onConfirm={handleConfirm}
      />
    </Dialog.Wrapper>
  );
}
