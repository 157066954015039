import { EyeClosedIcon, EyeOpenIcon } from "@volt_developers/components";

const SX_PROPERTIES = {
  mt: 0.25,
  ml: 1,
  fontSize: 20,
  color: "primary.main",
  cursor: "pointer",
};

export interface ShowSecretButtonProps {
  isSecretVisible: boolean;
  onClick: () => void;
  "data-testid"?: string;
}

export function ShowSecretButton({
  "data-testid": dataTestId,
  isSecretVisible,
  onClick,
}: ShowSecretButtonProps) {
  if (isSecretVisible) {
    return (
      <EyeClosedIcon
        data-testid={dataTestId}
        role="button"
        sx={SX_PROPERTIES}
        onClick={onClick}
      />
    );
  }

  return (
    <EyeOpenIcon
      data-testid={dataTestId}
      role="button"
      sx={SX_PROPERTIES}
      onClick={onClick}
    />
  );
}
