import { useTranslation } from "react-i18next";

import { PageError } from "components/PageError";
import { PageWrapper } from "components/PageWrapper";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { dashboardRoutes } from "modules/dashboard/routes";

import type { ReactNode } from "react";

export interface Page403Props {
  buttonLabel?: ReactNode;
  goBackLink?: string;
}

export function Page403({ buttonLabel, goBackLink }: Page403Props) {
  const { t } = useTranslation("common");

  return (
    <PageWrapper>
      <PageError
        description={t(COMMON_TRANSLATION_KEYS.youDoNotHavePermissions)}
        goBackLink={goBackLink ?? dashboardRoutes.path}
        title={t(COMMON_TRANSLATION_KEYS.forbiddenError)}
        buttonLabel={
          buttonLabel ?? t(COMMON_TRANSLATION_KEYS.returnToDashboard)
        }
      />
    </PageWrapper>
  );
}
