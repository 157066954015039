import { useSelector } from "react-redux";

import { defineAbilityFor } from "context/Ability.context";
import { selectAuthCustomer , selectCurrentCustomerId , selectCurrentUserId , selectCurrentUserPermissions } from "modules/auth/store/auth.slice";

export function useDefineAbility() {
  const userId = useSelector(selectCurrentUserId);
  const permissionGroups = useSelector(selectCurrentUserPermissions);
  const customerId = useSelector(selectCurrentCustomerId);
  const customer = useSelector(selectAuthCustomer);

  return defineAbilityFor({
    permissionGroups,
    userId,
    customerId,
    hierarchyIds: customer?.globalFilterIds ?? [],
  });
}
