import type { NotificationSettings } from "models/NotificationSettings";
import type { UUID } from "utils";

export interface CashManagementNotificationSettings
  extends NotificationSettings {}

export type PayoutNotifications = {
  ownerId: UUID;
  source: string;
  url: string;
  secret: string;
  maxAttempts: number;
  email: string;
  signatureHeaders: string[];
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ReportNotifications = {
  ownerId: UUID;
  source: string;
  url: string;
  secret: string;
  maxAttempts: number;
  email: string;
  signatureHeaders: string[];
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CreditNotifications = {
  ownerId: UUID;
  source: string;
  url: string;
  secret: string;
  maxAttempts: number;
  email: string;
  signatureHeaders: string[];
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export const PROPERTY_PAYOUT_NOTIFICATIONS_SECRET =
  "payout-notifications-secret";
export const PROPERTY_PAYOUT_NOTIFICATIONS_STATUS =
  "payout-notifications-status";
