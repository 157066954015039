import { DateTime } from "luxon";
import * as Yup from "yup";

import { MORE_THEN_NOW } from "constants/errors";
import { DATE_FORMAT_DD_MM_YYYY } from "utils/time";

declare module "yup" {
  export interface StringSchema {
    validateDateMin(
      afterDate?: string,
      format?: string,
      message?: string
    ): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateDateMin",
  function (
    afterDate,
    format = DATE_FORMAT_DD_MM_YYYY,
    message = MORE_THEN_NOW
  ) {
    return this.test(
      "validate-date-min",
      message,
      function validateDateMin(value?: string) {
        if (typeof value !== "string") {
          return false;
        }

        const parsedDate = DateTime.fromFormat(value, format);
        const parsedAfterDate = afterDate
          ? DateTime.fromFormat(afterDate, format)
          : DateTime.now().plus({ days: 1 });

        return parsedDate >= parsedAfterDate;
      }
    );
  }
);
