import { fork, put, takeLatest } from "redux-saga/effects";

import * as CountriesApi from "api/Countries";
import { setError, setSuccess, startLoading } from "store/appSlice";
import {
  fetchProcessableCountries,
  setProcessableCountries,
} from "store/valuesSlice";

import { callApi } from "./helpers/api";

function* fetchProcessableCountriesSaga() {
  const operationPath = "values.processableCountries";

  yield put(startLoading(operationPath));
  try {
    // @ts-ignore noImplicitAny
    const data = yield callApi(CountriesApi.getProcessableCountries);

    yield put(setProcessableCountries(data));
    yield put(setSuccess(operationPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* watchFetchProcessableCountries() {
  yield takeLatest(
    fetchProcessableCountries().type,
    fetchProcessableCountriesSaga
  );
}

export function* rootSaga() {
  yield fork(watchFetchProcessableCountries);
}
