import axios from "axios";

import { resolveApiUrl } from "./helpers/resolveApiUrl";

axios.interceptors.request.use(async (config) => {
  config.baseURL = resolveApiUrl(config.environment);
  delete config.environment;

  return config;
});
