import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { PROPERTY_ID, SUBJECT_CUSTOMER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { RedirectCheckoutBranding } from "../components/RedirectCheckoutBranding";
import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const AddressEdit = lazyRetry(
  () => import("../pages/address/Edit"),
  "CustomerManage"
);

const List = lazyRetryWithPreload(
  () => import("../pages/List"),
  "CustomerView"
);

const Edit = lazyRetry(() => import("../pages/Edit"), "CustomerManage");

const Create = lazyRetry(() => import("../pages/Create"), "CustomerManage");

const Details = lazyRetry(() => import("../pages/Details"), "CustomerDetails");

const Countries = lazyRetry(
  () => import("../pages/Countries"),
  "CustomerCountries"
);

export const customersRoutes: Route = {
  path: PAGE_URLS.list,
  title: [COMMON_TRANSLATION_KEYS.customers, COMMON_TRANSLATION_KEYS.list],
  component: List,
  showChildrenInSidebar: false,
  newTheme: true,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CUSTOMER,
  },
  children: [
    {
      title: [
        COMMON_TRANSLATION_KEYS.customers,
        COMMON_TRANSLATION_KEYS.editAddress,
      ],
      path: PAGE_URLS.addressEdit,
      newTheme: true,
      component: AddressEdit,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CUSTOMER,
        field: PROPERTY_ID,
      },
    },
    {
      title: [
        COMMON_TRANSLATION_KEYS.customers,
        COMMON_TRANSLATION_KEYS.editPaymentConfigurationCountries,
      ],
      path: PAGE_URLS.countries,
      newTheme: true,
      component: Countries,
    },
    {
      title: [COMMON_TRANSLATION_KEYS.customers, COMMON_TRANSLATION_KEYS.new],
      path: PAGE_URLS.create,
      component: Create,
      newTheme: true,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_CUSTOMER,
        field: PROPERTY_ID,
      },
    },
    {
      title: [
        COMMON_TRANSLATION_KEYS.customers,
        COMMON_TRANSLATION_KEYS.editDetails,
      ],
      path: PAGE_URLS.edit,
      component: Edit,
      newTheme: true,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CUSTOMER,
        field: PROPERTY_ID,
      },
    },
    {
      path: PAGE_URLS.checkoutBrandingRedirect,
      title: COMMON_TRANSLATION_KEYS.redirecting,
      component: RedirectCheckoutBranding,
      newTheme: true,
      hasNestedSwitch: true,
    },
    {
      // title for this path is defined inside details routing
      path: PAGE_URLS.details,
      component: Details,
      newTheme: true,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_CUSTOMER,
      },
      hasNestedSwitch: true,
    },
  ],
};
