import { createAction, createSlice } from "@reduxjs/toolkit";

import { listFactory } from "store/listFactory";

import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { User } from "../models/User";
import type { UserForm } from "../models/UserForm";

const { slicePart, selectors } = listFactory<User>("users");

export const initialState = {
  ...slicePart.initialState,
};

export const slice = createSlice({
  ...slicePart,
  initialState,
  reducers: {
    setItem: slicePart.reducers.setItem,
    setList: slicePart.reducers.setList,
    addItem: slicePart.reducers.addItem,
    updateItem: slicePart.reducers.updateItem,
    removeItem: slicePart.reducers.removeItem,
  },
});

export const { setList, setItem, addItem, updateItem, removeItem } =
  slice.actions;

export const fetchUsers = createAction<{ customerId?: UUID } | undefined>(
  "fetchUsers"
);
export const fetchUser = createAction<ObjectWithId>("fetchUser");
export const createUser = createAction<{ data: UserForm }>("createUser");
export const updateUser = createAction<{ data: UserForm; id: UUID }>(
  "updateUser"
);
export const sendProductInterest = createAction<{
  productName: string;
  userId: UUID;
}>("productInterest");

export const userOperationPaths = (id?: UUID) => {
  return {
    fetchUsers: ["users", "list"],
    fetchUser: ["users", "list", id],
    createUser: ["users", "list", "create"],
    updateUser: ["users", "list", "update", id],
    productInterest: ["users", "product-interest"],
  };
};

export const { selectList: selectUsers, selectItem: selectUser } = selectors;

export const userManagementReducer = slice.reducer;
