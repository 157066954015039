export function pruneObject<Object extends Record<string, unknown>>(
  object: Object
): Object {
  const prunedEntries = Object.entries(object).filter(([_, value]) => {
    if (value === undefined || Number.isNaN(value)) {
      return false;
    }

    return true;
  });

  const mappedPrunedEntries = prunedEntries.map(([key, value]) => {
    if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null &&
      value instanceof Date === false
    ) {
      return [key, pruneObject({ ...value })];
    }

    return [key, value];
  });

  return Object.fromEntries(mappedPrunedEntries);
}
