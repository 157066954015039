import { portalApi, TAGS } from "api/rtkApi";
import { fetchCurrentUser } from "modules/auth/store/auth.slice";
import { pessimisticUpdate } from "utils/pessimisticUpdate";

import type {
  CredentialCreationOptionsJSON,
  PublicKeyCredentialWithAttestationJSON,
} from "@github/webauthn-json";

import type { ObjectWithId } from "utils/ObjectWithId";

type InitializeSCAMutationSuccessResponse = CredentialCreationOptionsJSON;

type FinalizeScaSetupMutation = {
  credential: PublicKeyCredentialWithAttestationJSON;
} & ObjectWithId;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (build) => ({
    initializeSCA: build.mutation<InitializeSCAMutationSuccessResponse, void>({
      query: () => ({
        url: "/users/settings/sca/setup/initialize",
        body: {},
        method: "POST",
      }),
    }),
    finalizeSCASetup: build.mutation<void, FinalizeScaSetupMutation>({
      query: ({ credential }) => ({
        url: "/users/settings/sca/setup/finalize",
        body: JSON.stringify(credential),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            portalApi.util.invalidateTags([
              {
                type: TAGS.USER,
                id,
              },
            ])
          );

          dispatch(fetchCurrentUser());
        });
      },
    }),
  }),
});

export const { useInitializeSCAMutation, useFinalizeSCASetupMutation } =
  extendedApi;
