import type { CountryCode } from "models/CountryCode";
import type { Currency } from "models/Currency";
import type { CurrencyCode } from "models/CurrencyCode";
import type { Order } from "models/helpers";
import type { TransferType } from "models/TransferType";
import type { Account } from "modules/bank-accounts/models/Account";
import type { Bank } from "modules/banks/models/banks";
import type { PaymentProvider } from "modules/customers/models/PaymentProvider";
import type { UUID } from "utils";

import type { PaymentBankResponses } from "./PaymentBankResponses";
import type { PaymentBeneficiary } from "./PaymentBeneficiary";
import type { PaymentInternationalClearing } from "./PaymentInternationalClearing";
import type { PaymentLicence } from "./PaymentLicence";
import type { PaymentSender } from "./PaymentSender";
import type { PaymentStatus } from "./PaymentStatus";
import type { PaymentStatusUpdate } from "./PaymentStatusUpdate";
import type { PaymentTransaction } from "./PaymentTransaction";
import type { OscilloscopeRefund } from "./Refund";

export type IBAN = string;

export interface OscilloscopePayment {
  amount: number;
  bankCountry: CountryCode | null;
  bankId: UUID;
  bankName: string;
  beneficiary: Beneficiary;
  checkoutType: string;
  clientId: UUID;
  clientName: string;
  createdAt: string;
  currency: CurrencyCode;
  customerId: UUID;
  customerLegalName: string;
  customerName: string;
  id: UUID;
  initiationChannel: "api" | "hosted" | "sca_app" | null;
  licence: PaymentLicence | null;
  merchantInternalReference: string | null;
  payByLink: boolean;
  paymentScheme: null;
  paymentType: string;
  provider: string;
  refundsData: OscilloscopeRefund[];
  status: PaymentStatus;
  summaryDetails: null;
  uniqueReference: string;
}

type Beneficiary = {
  id: UUID;
  clientId: UUID;
  countryId: CountryCode;
  customerId: UUID;
};

export interface Payment {
  account: Account;
  active: boolean;
  amount: number;
  createdAt: string;
  currency: Currency;
  id: UUID;
  initiationChannel: string | null;
  isConnect: boolean;
  merchantInternalReference: string | null;
  pblValidityPeriod: number | null;
  status: PaymentStatus;
  statusUpdates: PaymentStatusUpdate[];
  type: string;
  uniqueReference: string;
  bank?: Bank;
  bankResponses?: PaymentBankResponses;
  beneficiary?: PaymentBeneficiary;
  internationalClearing?: PaymentInternationalClearing;
  licence?: PaymentLicence | null;
  provider?: Pick<PaymentProvider, "id" | "name">;
  sender?: PaymentSender;
  transaction?: PaymentTransaction;
  transferType?: TransferType;
}

export const PROPERTY_BENEFICIARY_ACCOUNT_IDENTIFICATIONS =
  "beneficiary.accountIdentifications";
export const PROPERTY_BANK_RESPONSES = "bankResponses";
export const PROPERTY_PROVIDER = "provider";
export const PROPERTY_SENDER = "sender";
export const PROPERTY_SENDER_VOLT_CONNECT = "sender.voltConnect";
export const PROPERTY_SENDER_PAYMENT_PROVIDER = "sender.paymentProvider";
export const PROPERTY_SENDER_CHECKOUT = "sender.checkout";
export const PROPERTY_SENDER_ACCOUNT_NAME = "sender.accountName";

export type PaymentsQueryParams = {
  country: string | null;
  currency: CurrencyCode | null;
  endDate: string | null;
  licences: string[];
  limit: number;
  maxAmount: number | null;
  minAmount: number | null;
  order: Order;
  orderBy: OrderableColumns;
  page: number;
  search: string;
  startDate: string | null;
  statuses: string[];
  customerHierarchyId?: UUID;
  noBankChosen?: boolean;
};

export type OrderableColumns =
  | "amount"
  | "createdAt"
  | "currency"
  | "status"
  | "uniqueReference";
