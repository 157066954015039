import { SentryRoute } from "sentry";

import type { RouteProps } from "react-router-dom";

export interface UniversalRouteProps extends Omit<RouteProps, "render"> {
  render: NonNullable<RouteProps["render"]>;
}

// Route that is always shown regardless of the user's authentication state
export function UniversalRoute({ ...props }: UniversalRouteProps) {
  return <SentryRoute {...props} />;
}
