import { isUUID } from "./uuid";

import type { Order } from "models/helpers";

export interface PaginationParams<OrderableColumns> {
  order: Order;
  sortBy: OrderableColumns;
  favouriteOrder?: Order;
  limit?: number;
  page?: number;
  search?: string;
}

interface PreparePaginationParamsOptions {
  useSearchAsId?: boolean;
}

/**
 * Used in RTK-Query list queries
 * Formats query pagination parameters before sending request
 */
export function preparePaginationParams<OrderableColumns>(
  {
    order,
    search,
    sortBy,
    favouriteOrder,
    ...rest
  }: PaginationParams<OrderableColumns>,
  options?: PreparePaginationParamsOptions
) {
  const { useSearchAsId = true } = options ?? {};

  const trimmedSearchValue = search?.trim() ?? "";
  const searchIsUUID = isUUID(trimmedSearchValue);
  const appropriateValue = searchIsUUID
    ? trimmedSearchValue.toLowerCase()
    : trimmedSearchValue;

  const optimizedSearch =
    searchIsUUID && useSearchAsId
      ? { id: appropriateValue }
      : { search: appropriateValue };

  const favouriteOrderParam = favouriteOrder
    ? { "order[isFavourite]": favouriteOrder }
    : {};

  return {
    ...(appropriateValue && optimizedSearch),
    ...favouriteOrderParam,
    [`order[${sortBy}]`]: order,
    ...rest,
  };
}
