import { matchPath } from "react-router-dom";

import { NON_ENV_BASED_ROUTES } from "constants/nonEnvBasedRoutes";

import type { History } from "history";
import type { match} from "react-router-dom";

const PREFIX_FOR_PARAM = ":";

const checkIfNonEnvBasedRoutedUrl = (url: string, history: History<unknown>) =>
  Boolean(
    NON_ENV_BASED_ROUTES.find((nonEnvBasedRoute) =>
      matchPath(history.location.pathname, {
        path: nonEnvBasedRoute,
        strict: true,
      })
    )
  );

const removeNullishAndRoutesWithParams = (
  matchObject: match | null
): matchObject is match =>
  Boolean(matchObject) && !matchObject?.path.includes(PREFIX_FOR_PARAM);

/**
 * Returns the closest possible matched route or base one (when url includes
 * non env reliant path) without params
 *
 * @param history
 * @param routes all available routes in app
 * @return match
 */
export const getClosestParamlessParent = ({
  history,
  routes,
}: {
  history: History<unknown>;
  routes: readonly string[];
}): match => {
  if (checkIfNonEnvBasedRoutedUrl(history.location.pathname, history)) {
    return {
      path: history.location.pathname,
      url: history.location.pathname,
      params: {},
      isExact: true,
    };
  }

  return routes
    .map((path) => matchPath(history.location.pathname, { path }))
    .filter(removeNullishAndRoutesWithParams)
    .reduce((acc, nextMatch) =>
      acc.path.length > nextMatch.path.length ? acc : nextMatch
    );
};
