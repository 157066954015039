type ErrorEither<T> = { isError: false; result: T } |  { isError: true };

const awaitAndCatchAllErrors = async <T>(
  query: Promise<T>
): Promise<ErrorEither<T>> => {
  try {
    const result = await query;

    return { isError: false, result };
  } catch (e) {
    return { isError: true };
  }
};


export const pessimisticUpdate =
  <T>(promise: Promise<T>) =>
    async (callback: (arg: T) => void) => {
      const either = await awaitAndCatchAllErrors(promise);

      if (!either.isError) {
        callback(either.result);
      }
    };