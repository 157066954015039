import React from "react";

import { AuthRoute } from "./AuthRoute";

import type { JSX } from "react";

import type { AuthRouteProps } from "./AuthRoute";

export interface AuthorizedRouteProps
  extends Omit<
    AuthRouteProps,
    "invalidAuthStateRedirect" | "requiredAuthState"
  > {}

export function AuthorizedRoute(props: AuthorizedRouteProps): JSX.Element {
  return (
    <AuthRoute {...props} invalidAuthStateRedirect="/login" requiredAuthState />
  );
}
