import { format } from "date-fns";
import { DateTime } from "luxon";

export const DATE_FORMAT_DD_MM_YYYY = "dd/MM/yyyy";
export const DATE_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";
const TIME_FORMAT_HH_MM = "HH:mm";
const TIME_FORMAT_HH_MM_SS = "HH:mm:ss";

export function transformFormat(
  date: string,
  inputFormat: string,
  outputFormat: string = DATE_FORMAT_DD_MM_YYYY
) {
  if (!date) {
    return date;
  }

  return DateTime.fromFormat(date, inputFormat).toFormat(outputFormat);
}

export function getUTCTime(date: Date | string) {
  const isoDate = new Date(date).toISOString();

  return `${isoDate.substring(11, 16)}`;
}

export function getUTCFullDate(date: Date | string) {
  const isoDate = new Date(date).toISOString();

  return format(new Date(isoDate.substring(0, 10)), DATE_FORMAT_DD_MM_YYYY);
}

export function getFullDate(date: Date | number) {
  return format(date, DATE_FORMAT_DD_MM_YYYY);
}

export function getTime(date: Date | number, withSeconds = false) {
  if (withSeconds) {
    return format(date, TIME_FORMAT_HH_MM_SS);
  }

  return format(date, TIME_FORMAT_HH_MM);
}

// for UTC date string
export function formatUTCFullDate(date: string) {
  const [year, month, day] = date.substring(0, 10).split("-");

  return `${day}/${month}/${year}`;
}

// for UTC date string
export function formatUTCTime(date: string, withSeconds = false) {
  if (withSeconds) {
    return date.substring(11, 19);
  }

  return date.substring(11, 16);
}
