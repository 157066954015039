import { TRANSLATION_KEYS } from "./TranslationKeys";

import type { ToastProps } from "components/toasts/MessageToast";

export const PAYOUTS_TOASTS_IDS = {
  usernamesFailure: "user-names-error-toast-id",
  createFailure: "payout-create-failure-toast",
  createSuccess: "payout-create-success-toast",
  payoutsListFailure: "payouts-list-error-toast-id",
  payoutsRejectSuccess: "payouts-reject-success-toast-id",
  payoutsApproveSuccess: "payouts-approve-success-toast-id",
  payoutsApproveFailure: "payouts-approve-failure-toast-id",
  validationError: "payout-validation-error-toast",
} as const;

export const PAYOUTS_TOASTS = {
  listFailure: {
    bodyContent: TRANSLATION_KEYS.cannotLoadPayoutsList,
    variant: "failure",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.payoutsListFailure,
    },
  },
  validationError: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.validationError,
    },
  }),
  creationError: {
    bodyContent: TRANSLATION_KEYS.weWereUnableToRequest,
    variant: "failure",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.createFailure,
    },
  },
  creationSuccess: (bodyContent: string): ToastProps => ({
    bodyContent,
    variant: "success",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.createSuccess,
    },
  }),
  usernamesFailure: {
    bodyContent: TRANSLATION_KEYS.cannotLoadUsernames,
    variant: "failure",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.usernamesFailure,
    },
  },
  rejectSuccess: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "success",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.payoutsRejectSuccess,
    },
  }),
  approveSuccess: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "success",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.payoutsApproveSuccess,
    },
  }),
  approveFailure: {
    bodyContent: TRANSLATION_KEYS.cannotApprovePayout,
    variant: "failure",
    config: {
      toastId: PAYOUTS_TOASTS_IDS.payoutsApproveFailure,
    },
  },
} as const;
