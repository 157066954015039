import { isValidIBAN } from "ibantools";
import * as yup from "yup";

declare module "yup" {
  export interface StringSchema {
    validateIBAN(message?: string): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validateIBAN",
  function (message = "Please, provide valid IBAN") {
    return this.test(
      "validate-iban",
      message,
      function validateIBAN(iban?: string) {
        if (!iban) {
          return true;
        }

        return isValidIBAN(iban);
      }
    );
  }
);
