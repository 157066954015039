import type { CustomerDiscriminator } from "../models/Customer";

export type OrderableColumns = "active" | "discr" | "name";

export const CUSTOMER_DISCRIMINATOR = {
  PSP: "payment_service_provider",
  MERCHANT: "merchant",
  TSP: "technical_service_provider",
} as const;

export const CUSTOMER_DISCRIMINATOR_LIST: CustomerDiscriminator[] = [
  CUSTOMER_DISCRIMINATOR.PSP,
  CUSTOMER_DISCRIMINATOR.TSP,
  CUSTOMER_DISCRIMINATOR.MERCHANT,
];

export const CUSTOMER_DISCR_TO_SHORTHAND = new Map<
  CustomerDiscriminator,
  string
>([
  ["technical_service_provider", "TSP"],
  ["payment_service_provider", "PSP"],
]);
