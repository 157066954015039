import { prepareCommonFiltersForApi } from "modules/common/utils/prepareCommonFiltersForApi";
import { pruneObject } from "modules/common/utils/pruneObject";

import type { CommonFilters } from "modules/common/utils/prepareCommonFiltersForApi";
import type { UUID } from "utils";

export function prepareSettlementsFilters(
  filters?: CommonFilters & { customerId?: UUID }
) {
  if (!filters) {
    return {};
  }

  const {
    status,
    amountFrom,
    amountTo,
    trigger,
    currency,
    customerId,
    ...restFilters
  } = filters;
  const params = prepareCommonFiltersForApi(restFilters);

  if (amountFrom) {
    Object.assign(params, { "amount[gte]": amountFrom });
  }

  if (amountTo) {
    Object.assign(params, { "amount[lte]": amountTo });
  }

  if (status) {
    Object.assign(params, { "status[]": status });
  }

  if (trigger) {
    Object.assign(params, { "triggeredType[]": trigger });
  }

  if (currency) {
    Object.assign(params, { currency: currency });
  }

  if (customerId) {
    Object.assign(params, { customerId: customerId });
  }

  return pruneObject(params);
}
