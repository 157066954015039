import type { Order } from "models/helpers";
import type { Bank } from "modules/banks/models/banks";
import type { UUID } from "utils";

import type { BLOCK_RULE_ALL_TYPES } from "../constants/Rules";

export type BlockRuleType = (typeof BLOCK_RULE_ALL_TYPES)[number];

export interface BlockRule {
  active: boolean;
  blockedAt: string;
  // only in duplicate create response
  customerId: UUID;
  id: UUID;
  type: BlockRuleType;
  userId: UUID;
  bank?: Pick<
    Bank,
    "active" | "branchName" | "country" | "icon" | "id" | "logo" | "name"
  >;
  username?: string;
  value?: string;
}

export type SelectedBlockRule = Pick<BlockRule, "active" | "id">;

export type BlockRulePostBody = Pick<BlockRule, "type" | "value">;

export type BlockRuleActiveState = "no" | "yes";

export const BlockRuleActiveStates: Record<string, BlockRuleActiveState> = {
  YES: "yes",
  NO: "no",
} as const;

export type BlockRulesQueryParams = {
  active: BlockRuleActiveState[];
  customerId: string;
  limit: number;
  order: Order;
  orderBy: keyof BlockRule;
  page: number;
  search: string;
  types: BlockRuleType[];
};

export type BlockRulesFilterParams = Partial<
  Pick<BlockRulesQueryParams, "active" | "types">
>;
