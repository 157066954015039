import { ActionBar } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { useActionLabel } from "./useActionLabel";

export function ActionLabel() {
  const { labelTranslationKey } = useActionLabel();
  const { t } = useTranslation("refunds");

  if (!labelTranslationKey) {
    return null;
  }

  return <ActionBar.Label>{t(labelTranslationKey)}</ActionBar.Label>;
}
