import { DateTime } from "luxon";

import { formatApiDate } from "api/helpers/createFilterParams";

import { pruneObject } from "../pruneObject";

export interface CommonFilters {
  [key: string]: unknown;
  endDate?: string | null;
  startDate?: string | null;
}

export function prepareCommonFiltersForApi(filters?: CommonFilters) {
  if (!filters) {
    return {};
  }

  const { startDate, endDate, ...param } = filters;

  if (startDate) {
    const formattedDate = Number(
      formatApiDate(
        DateTime.fromJSDate(new Date(startDate), { zone: "UTC" }).startOf("day")
      )
    );

    Object.assign(param, {
      "createdAt[gte]": formattedDate,
    });
  }

  if (endDate) {
    const formattedDate = Number(
      formatApiDate(
        DateTime.fromJSDate(new Date(endDate), { zone: "UTC" }).endOf("day")
      )
    );

    Object.assign(param, { "createdAt[lte]": formattedDate });
  }

  return pruneObject(param);
}
