import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import type { Route } from "routes/RouteModel";

const Login = lazyRetryWithPreload(() => import("../pages/Login"), "AuthLogin");
const ForgotPassword = lazyRetryWithPreload(
  () => import("../pages/ForgotPassword"),
  "AuthPassword"
);
const ResetPassword = lazyRetryWithPreload(
  () => import("../pages/ResetPassword"),
  "AuthPassword"
);
const ProxyLogin = lazyRetryWithPreload(
  () => import("../pages/ProxyLogin"),
  "ProxyLogin"
);

export const authRoutes: Route[] = [
  {
    title: [COMMON_TRANSLATION_KEYS.login],
    path: "/login",
    component: Login,
    newTheme: true,
  },
  {
    title: [COMMON_TRANSLATION_KEYS.forgotPassword],
    path: "/forgot-password",
    component: ForgotPassword,
    newTheme: true,
  },
  {
    title: [COMMON_TRANSLATION_KEYS.resetPassword],
    path: "/reset-password/:token",
    component: ResetPassword,
    newTheme: true,
  },
];

export const universalRoutes: Route[] = [
  {
    title: "Proxy login",
    path: "/login-proxy",
    component: ProxyLogin,
    newTheme: true,
  },
];
