import { TEST_IDS } from "./TestIds";
import { TRANSLATION_KEYS } from "./TranslationKeys";

import type { ToastProps } from "components/toasts/MessageToast";

export const USER_TOASTS_IDS = {
  createFailure: TEST_IDS.userCreateFailureToast,
  createSuccess: TEST_IDS.userCreateSuccessToast,
  editFailure: TEST_IDS.userEditFailureToast,
  editSuccess: TEST_IDS.userEditSuccessToast,
  scaRemovalSuccess: TEST_IDS.scaRemovalSuccessToast,
  scaRemovalFailure: TEST_IDS.scaRemovalFailureToast,
} as const;

export const USER_TOASTS = {
  createUserSuccess: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "success",
    config: {
      toastId: USER_TOASTS_IDS.createSuccess,
      updateId: "user-create-success-update-id",
    },
  }),
  createUserFailure: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: USER_TOASTS_IDS.createFailure,
      updateId: "user-create-failure-update-id",
    },
  }),
  editUserSuccess: (
    message: string = TRANSLATION_KEYS.theUserHasBeenModified
  ): ToastProps => ({
    bodyContent: message,
    variant: "success",
    config: {
      toastId: USER_TOASTS_IDS.editSuccess,
      updateId: "user-edit-success-update-id",
    },
  }),
  editUserFailure: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: USER_TOASTS_IDS.editFailure,
      updateId: "user-edit-failure-update-id",
    },
  }),
  scaRemovalSuccess: {
    bodyContent: TRANSLATION_KEYS.scaHasBeenSuccessfullyRemovedForThisUser,
    variant: "success",
    config: {
      toastId: USER_TOASTS_IDS.scaRemovalSuccess,
      updateId: "user-sca-removal-success-id",
    },
  },
  scaRemovalFailure: {
    bodyContent: TRANSLATION_KEYS.couldNotRemoveSca,
    variant: "failure",
    config: {
      toastId: USER_TOASTS_IDS.scaRemovalFailure,
      updateId: "user-sca-removal-failure-id",
    },
  },
} as const;
