/**
 * @deprecated use translations in the bank-accounts module
 */
export const accounts = {
  "Bank accounts": "Bank accounts",
  productionHelp: `Here you should provide details of the bank accounts you'd like to receive customer payments into
in your production environment. Please note that you are not able to send payments into these accounts 
from the Volt sandbox environment as we use model banks only.`,
  sandboxHelp: `Here are some automatically generated dummy accounts which you can use to send test payments to
on our sandbox environment only. Please don't attempt to send payments to these accounts from your production environment!`,
  "New bank account": "New bank account",
  "Location and accepted payments": "Location and accepted payments",
  Country: "Country",
  Currency: "Currency",
  "Payment types accepted": "Payment types accepted",
  domesticRoutingHelp:
    "Complete this section if you need to collect payments from customers within the country that this bank account is held.",
  "Account number": "Account number",
  "Sort code": "Sort code",
  "International routing": "International routing",
  internationalRoutingHelp:
    "Complete this section if you intend to accept payments from customers outside the country that this bank account is held.",
  IBAN: "IBAN",
  "SWIFT BIC": "SWIFT BIC",
  accountEnabled: "<0>Enabled</0> - payments can be sent to this account",
  accountDisabled: "<0>Disabled</0> - payments cannot be sent to this account",
  "Add a new bank account": "Add a new bank account",
  "Account identifier": "Account identifier",
  "Unique ID for API": "Unique ID for API",
  "Your name for this account": "Your name for this account",
  "Domestic routing": "Domestic routing",
  "Account activity": "Account activity",
  "Account status": "Account status",
  "Last payment instructed": "Last payment instructed",
  Cancel: "Cancel",
  "Save bank account": "Save bank account",
  "Add bank account": "Add bank account",
  "Invalid official name": "Invalid official name",
  Search: "Search",
  "No options": "No options",
  "Enter more than one character": "Enter more than one character",
};
