import { AuthRoute } from "./AuthRoute";

import type { AuthRouteProps } from "./AuthRoute";

export interface UnauthorizedRouteProps
  extends Omit<
    AuthRouteProps,
    "invalidAuthStateRedirect" | "requiredAuthState"
  > {}

export function UnauthorizedRoute(props: UnauthorizedRouteProps) {
  return (
    <AuthRoute
      {...props}
      invalidAuthStateRedirect="/"
      requiredAuthState={false}
    />
  );
}
