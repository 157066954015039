import deepEqual from "deep-equal";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMount, useSessionStorage } from "react-use";

import { getActiveFiltersCount } from "../../utils/getActiveFiltersCount";
import { useGetUrlFilters } from "../useGetUrlFilters/useGetUrlFilters";
import { usePersistedFiltersToast } from "./usePersistedFiltersToast";

import type {
  FilterType,
  UseGetUrlFiltersParams,
} from "../useGetUrlFilters/useGetUrlFilters";

export interface UsePersistedFiltersParams<
  Filters extends FilterType = FilterType,
> extends UseGetUrlFiltersParams<Filters> {
  key: string;
  dateKeys?: string[];
}

export function usePersistedFilters<Filters extends FilterType>({
  defaultFilters,
  filtersConfiguration,
  key,
  dateKeys = DATE_OMITTED_KEYS,
}: UsePersistedFiltersParams<Filters>) {
  const filtersFromUrl = useGetUrlFilters<Filters>({
    defaultFilters,
    filtersConfiguration,
  });
  const [persistedFilters, setPersistedFilters] = useSessionStorage<Filters>(
    key,
    filtersFromUrl
  );
  const [isSyncedWithUrl, setIsSyncedWithUrl] = useState(false);
  const filtersToShow = isSyncedWithUrl ? persistedFilters : filtersFromUrl;
  const keysToOmit = OMITTED_KEYS.concat(dateKeys);
  const activeFiltersCount = filtersToShow
    ? getActiveFiltersCount(filtersToShow, keysToOmit)
    : 0;

  useMount(() => {
    setPersistedFilters(filtersFromUrl);
    setIsSyncedWithUrl(true);
  });

  useEffect(() => {
    if (!isSyncedWithUrl) {
      return;
    }

    const omittedValue = dateKeys.reduce(
      (values, key) => Object.assign(values, { [key]: filtersFromUrl[key] }),
      {}
    );
    const persistedValue = dateKeys.reduce(
      (values, key) => Object.assign(values, { [key]: persistedFilters[key] }),
      {}
    );

    if (!deepEqual(omittedValue, persistedValue, { strict: true })) {
      setPersistedFilters({ ...persistedFilters, ...omittedValue });
    }
  }, [
    filtersFromUrl,
    dateKeys,
    persistedFilters,
    setPersistedFilters,
    isSyncedWithUrl,
  ]);

  const resetPersistedFilters = () => {
    const filtersToSet = Object.fromEntries(
      Object.entries(persistedFilters).map(([key, value]) =>
        keysToOmit.includes(key) ? [key, value] : [key, defaultFilters[key]]
      )
    ) as Filters;

    setPersistedFilters(filtersToSet);
  };

  usePersistedFiltersToast({
    activeFiltersCount,
    toastId: TOAST_ID,
    handleReset: resetPersistedFilters,
  });

  const handleSetPersistedFilters = useCallback(
    (filters: Filters) => {
      if (toast.isActive(TOAST_ID)) {
        toast.dismiss(TOAST_ID);
      }

      setPersistedFilters(filters);
    },
    [setPersistedFilters]
  );

  return {
    persistedFilters: filtersToShow,
    setPersistedFilters: handleSetPersistedFilters,
    resetPersistedFilters,
  };
}

const TOAST_ID = "filters-applied-toast";
export const FILTER_KEY_PREFIX = "filters";
const DATE_OMITTED_KEYS = ["startDate", "endDate"];
const OMITTED_KEYS = ["query", "search"];

export function getPersistedFilterKey(key: string) {
  return `${FILTER_KEY_PREFIX}-${key}`;
}
