import isArray from "lodash/isArray";

import { showMessageToast } from "components/toasts/MessageToast";

import type { ApiError, ApiValidationFormError } from "./handleApiErrors";

const TOAST_FAILURE_VARIANT = "failure";

/** @deprecated */
function isApiValidationFormErrors(
  errors: ApiError | ApiValidationFormError[]
): errors is ApiValidationFormError[] {
  return isArray(errors);
}

/** @deprecated */
export function toastForApiErrors(
  errors: ApiError | ApiValidationFormError[],
  dataTestId?: string
) {
  if (isApiValidationFormErrors(errors)) {
    errors.forEach(({ message }) => {
      showMessageToast({
        variant: TOAST_FAILURE_VARIANT,
        bodyContent: message,
        config: { toastId: dataTestId },
      });
    });
  } else {
    const preparedMessage =
      errors.message?.length > 0
        ? errors.message
        : "Sorry, something went wrong. Please try again later or contact support.";

    showMessageToast({
      variant: TOAST_FAILURE_VARIANT,
      bodyContent: preparedMessage,
    });
  }
}
