import {
  createSlice
} from "@reduxjs/toolkit";

import type {
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers} from "@reduxjs/toolkit";

type ListItemId = number | string;
type ListItemBase = { id: ListItemId };

export interface GenericListState<T> {
  list: T[];
  item?: T;
}

export type ListSliceState<T, A> = GenericListState<T> & A;

export const createListSlice = <
  T extends ListItemBase,
  A,
  Reducers extends SliceCaseReducers<ListSliceState<T, A>>,
>({
  name,
  initialState,
  reducers,
}: {
  initialState: ListSliceState<T, A>;
  name: string;
  reducers: ValidateSliceCaseReducers<ListSliceState<T, A>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
      setList: (state: ListSliceState<T, A>, action: PayloadAction<T[]>) => {
        state.list = action.payload;
      },
      clearList: (state: ListSliceState<T, A>) => {
        state.list = [];
      },
      replaceListItem: (
        state: ListSliceState<T, A>,
        action: PayloadAction<T>
      ) => {
        const newItem = action.payload;
        const index = state.list.findIndex((item) => item.id === newItem.id);

        if (index > -1) {
          state.list.splice(index, 1, newItem);
        }
      },
      removeListItem: (state, action) => {
        const id = action.payload;
        const index = state.list.findIndex((item) => item.id === id);

        state.list.splice(index, 1);
      },
      setItem: (state: ListSliceState<T, A>, action: PayloadAction<T>) => {
        const { payload } = action;

        state.item = payload;
      },
      updateItem: (
        state: ListSliceState<T, A>,
        action: PayloadAction<Partial<T>>
      ) => {
        const { payload } = action;

        Object.assign(state.item ?? {}, payload);
      },
      ...reducers,
    },
  });
};

export const createListSelectors = <T extends ListItemBase>(name: string) => {
  return {
    selectList: (state: any): T[] => {
      const { list } = state[name] as GenericListState<T>;

      return list;
    },
    selectItem:
      (id: string) =>
      (state: any): T | undefined => {
        const { item } = state[name] as GenericListState<T>;

        return item;
      },
    selectItemFromList:
      (id: string) =>
      (state: any): T | undefined => {
        const { list } = state[name] as GenericListState<T>;

        return list.find((item) => item.id === id);
      },
  };
};
