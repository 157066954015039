import {
  create as createPasskey,
  get as getPasskey,
} from "@github/webauthn-json";
import { WebauthnSupport } from "@teamhanko/hanko-frontend-sdk";
import { tenant } from "@teamhanko/passkeys-sdk";

import { ERRORS } from "../constants/Errors";

import type {
  CredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from "@github/webauthn-json/dist/types/basic/json";
import type { Tenant } from "@teamhanko/passkeys-sdk";

interface ScaConfig {
  baseUrl: string;
  tenantId: string;
}

type TenantLogin = ReturnType<typeof tenant>["login"];

type LoginOptions = Awaited<ReturnType<TenantLogin["initialize"]>>;
type FinalizeOptions = Parameters<TenantLogin["finalize"]>[0];

export class SCA {
  private passkeyApi: Tenant;

  public constructor({ baseUrl, tenantId }: ScaConfig) {
    // @ts-expect-error, apiKey is optional but typed as required
    this.passkeyApi = tenant({
      baseUrl,
      tenantId,
    });
  }

  public async authenticate(credential: FinalizeOptions) {
    try {
      const { token } = await this.passkeyApi.login.finalize(credential);

      if (!token) {
        throw new Error(ERRORS.TOKEN_MISSING);
      }

      return token;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Check if the platform authenticator is supported by the device.
   *
   * @return {boolean}
   * @memberof SCA
   */
  public checkSupport() {
    return WebauthnSupport.supported();
  }

  public async createPasskey(data: CredentialCreationOptionsJSON) {
    const credential = await createPasskey(data);

    return credential;
  }

  public async getPasskey(signal: AbortSignal) {
    const { publicKey } = await this.startAuthenticate();
    const credential = await getPasskey({
      publicKey: publicKey as PublicKeyCredentialRequestOptionsJSON,
      signal,
    });

    return credential;
  }

  /**
   * The authentication process needs to be fulfilled on the SDK site,
   * then results need to be sent to our BE service using onSuccess callback to complete the process.
   * @return {(Promise<string | undefined>)} SCA Token to send via "X-Volt-SCA-Token" header
   * @memberof SCA
   */
  public async startAuthenticate(): Promise<LoginOptions> {
    try {
      const loginOptions = await this.passkeyApi.login.initialize();

      return loginOptions;
    } catch (error) {
      throw error;
    }
  }
}

export const SCA_ERRORS = {
  CANCEL: "WebAuthn request cancelled",
  UNAUTHORIZED: "User needs to login",
  TECHNICAL_ERROR: "Technical error", // default error from SDK (sth like 500 on BE)
} as const;
