/*eslint perfectionist/sort-objects: "error"*/
export const TEST_IDS = {
  settingsBreadcrumbLink: "settings-nav-link",
  termsAndConditionsAcceptButton: "terms-and-conditions-dialog-accept-button",
  termsAndConditionsCheckbox: "terms-and-conditions-dialog-checkbox",
  termsAndConditionsContent: "terms-and-conditions-dialog-content",
  termsAndConditionsDescription: "terms-and-conditions-dialog-description",
  termsAndConditionsDialog: "terms-and-conditions-dialog",
  termsAndConditionsErrorToast: "terms-and-condition-toast-error",
  termsAndConditionsLogoutButton: "terms-and-conditions-dialog-logout-button",
  termsAndConditionsSubtitle: "terms-and-conditions-dialog-subtitle",
  termsAndConditionsSuccessToast: "terms-and-condition-toast-success",
  termsAndConditionsTitle: "terms-and-conditions-dialog-title",
  userSettings2FAStatus: "user-settings-twofa-status",
} as const;
