import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { useExactRouteChild } from "hooks/useExactRouteChild";
import { useStorage } from "hooks/useStorage";

import type {
  ReactNode,
  SetStateAction} from "react";

interface HelpHeaderProviderProps {
  children: ReactNode;
}

export type HelpHeaderContextData = {
  isEnabled: boolean;
  setIsActive: (value: SetStateAction<boolean>) => void;
  setIsEnabled: (value: SetStateAction<boolean>) => void;
  showHelpHeaderContent: boolean;
  toggleMenu: () => void;
};

export const HelpHeaderContext = createContext<HelpHeaderContextData>(null!);

export function HelpHeaderProvider({ children }: HelpHeaderProviderProps) {
  const [isActive, setIsActive] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [{ visitedPlaces }, setVisitedPlaces] = useStorage<{
    visitedPlaces: string[];
  }>(LOCAL_STORAGE_KEYS.helpHeader, { visitedPlaces: [] });
  const { route } = useExactRouteChild();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const wasRouteVisited = visitedPlaces.includes(route.path);

    if (!wasRouteVisited) {
      setIsActive(true);
      setVisitedPlaces({ visitedPlaces: [...visitedPlaces, route.path] });
    }
  }, [isEnabled, route, setVisitedPlaces, visitedPlaces]);

  const showHelpHeaderContent = isActive && isEnabled;
  const toggleMenu = () => setIsActive((prev) => !prev);

  return (
    <HelpHeaderContext.Provider
      value={{
        isEnabled,
        setIsActive,
        setIsEnabled,
        showHelpHeaderContent,
        toggleMenu,
      }}
    >
      {children}
    </HelpHeaderContext.Provider>
  );
}

export function useHelpHeader(watch: boolean = true) {
  const {
    isEnabled,
    setIsActive,
    setIsEnabled,
    showHelpHeaderContent,
    toggleMenu,
  } = useContext(HelpHeaderContext);

  useEffect(() => {
    if (watch && setIsEnabled) {
      setIsEnabled(true);
    }
  }, [setIsEnabled, watch]);

  useEffect(() => {
    return () => {
      if (watch) {
        setIsActive(false);
        setIsEnabled(false);
      }
    };
  }, [setIsActive, setIsEnabled, watch]);

  if (
    isEnabled === undefined ||
    showHelpHeaderContent === undefined ||
    toggleMenu === undefined
  ) {
    throw new Error(
      "useHelpHeader hook parent should be wrapped by HelpHeaderProvider"
    );
  }

  return {
    isEnabled,
    showHelpHeaderContent,
    toggleMenu,
  };
}
