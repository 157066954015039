import { Toast } from "@volt_developers/react-ui-components";
import { SnackbarContent } from "notistack";
import { forwardRef } from "react";

import type { ReactNode } from "react";

export interface SnackbarProps {
  message: ReactNode | string;
  type?: string;
}

const TOAST_TYPES = {
  info: "info",
  success: "success",
  error: "error",
  sticky: "sticky",
} as const;

const isVariant = (type: string): type is keyof typeof TOAST_TYPES =>
  Object.keys(TOAST_TYPES).includes(type);

const getVariant = (type: string) => {
  if (isVariant(type)) {
    return TOAST_TYPES[type];
  }

  return TOAST_TYPES.info;
};

/**
 * @deprecated Old toast, do not use `Snackbar`, use `MessageToast` from `components/toast` instead
 * @see MessageToast
 * @see SingleButtonToast
 * @see DoubleButtonToast
 */
export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ message, type = "info" }, ref) => {
    const variant = getVariant(type);

    return (
      <SnackbarContent ref={ref}>
        <Toast type={variant}>
          <Toast.ToastTypography>{message as string}</Toast.ToastTypography>
        </Toast>
      </SnackbarContent>
    );
  }
);
