import type { LocalStorageValues } from "../constants/LocalStorageItems";

export abstract class LocalStorage {
  public static getItem<T>(
    key: LocalStorageValues,
    defaultValue: T = {} as T
  ): T {
    const jsonWithData = localStorage.getItem(key)!;

    return jsonWithData ? JSON.parse(jsonWithData) : defaultValue;
  }

  public static removeItem(key: LocalStorageValues) {
    localStorage.removeItem(key);
  }

  public static saveItem<T>(key: LocalStorageValues, item: T) {
    localStorage.setItem(key, JSON.stringify(item));
  }
}
