import type { UUID } from "utils";

export const BASE_URL = "/administration/workflows";

export const PAGE_URLS = {
  create: `${BASE_URL}/create`,
  dashboard: "/",
  details: `${BASE_URL}/:id`,
  edit: `${BASE_URL}/:id/edit`,
  list: `${BASE_URL}`,
  getDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getEdit: (id: UUID) => `${BASE_URL}/${id}/edit`,
} as const;
