import type { UUID } from "utils";

const BASE_URL = "/configuration/customers";

export const PAGE_URLS = {
  addressEdit: `${BASE_URL}/:id/address`,
  list: BASE_URL,
  create: `${BASE_URL}/create`,
  checkoutBrandingRedirect: `${BASE_URL}/:id/checkout-branding`,
  checkoutBrandingRedirectDestination: "/checkout-branding",
  dynamicBeneficiaries: `${BASE_URL}/:id/dynamic-beneficiaries`,
  dynamicBeneficiariesCreate: `${BASE_URL}/:id/dynamic-beneficiaries/create`,
  paymentConfiguration: `${BASE_URL}/:id/payment-configuration`,
  hierarchy: `${BASE_URL}/:id/hierarchy`,
  details: `${BASE_URL}/:id`,
  countries: `${BASE_URL}/:id/payment-configuration/countries`,
  edit: `${BASE_URL}/:id/edit`,
  cashManagementRedirect: `${BASE_URL}/:id/cash-management`,
  getEdit: (id: UUID) => `${BASE_URL}/${id}/edit`,
  getAddressEdit: (id: UUID) => `${BASE_URL}/${id}/address`,
  getCreateDynamicBeneficiaries: (id: UUID) =>
    `${BASE_URL}/${id}/dynamic-beneficiaries/create`,
  getCountries: (id: UUID) =>
    `${BASE_URL}/${id}/payment-configuration/countries`,
  getPublicKeys: (id: UUID) =>
    `${BASE_URL}/${id}/payment-configuration/public-keys`,
  getDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getDynamicBeneficiaries: (id: UUID) =>
    `${BASE_URL}/${id}/dynamic-beneficiaries`,
  getPaymentConfiguration: (id: UUID) =>
    `${BASE_URL}/${id}/payment-configuration`,
  getHierarchy: (id: UUID) => `${BASE_URL}/${id}/hierarchy`,
} as const;
