type PermissionGroupObject = Record<
  `PERMISSION_GROUP_${string}`,
  `PERMISSION_GROUP_${string}`
>;

export const PERMISSION_STANDARD_GROUPS = {
  PERMISSION_GROUP_CUSTOMER_VIEW: "PERMISSION_GROUP_CUSTOMER_VIEW",
  PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE:
    "PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE",
  PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW:
    "PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW",
  PERMISSION_GROUP_CUSTOMER_CREDENTIALS_MANAGE:
    "PERMISSION_GROUP_CUSTOMER_CREDENTIALS_MANAGE",
  PERMISSION_GROUP_BANKS_VIEW: "PERMISSION_GROUP_BANKS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW",
  PERMISSION_GROUP_APPLICATIONS_MANAGE: "PERMISSION_GROUP_APPLICATIONS_MANAGE",
  PERMISSION_GROUP_APPLICATIONS_VIEW: "PERMISSION_GROUP_APPLICATIONS_VIEW",
  PERMISSION_GROUP_CIRCUIT_BREAKER_MANAGE:
    "PERMISSION_GROUP_CIRCUIT_BREAKER_MANAGE",
  PERMISSION_GROUP_CIRCUIT_BREAKER_VIEW:
    "PERMISSION_GROUP_CIRCUIT_BREAKER_VIEW",
  PERMISSION_GROUP_BANK_ACCOUNTS_MANAGE:
    "PERMISSION_GROUP_BANK_ACCOUNTS_MANAGE",
  PERMISSION_GROUP_BANK_ACCOUNTS_VIEW: "PERMISSION_GROUP_BANK_ACCOUNTS_VIEW",
  PERMISSION_GROUP_PAYMENTS_VIEW: "PERMISSION_GROUP_PAYMENTS_VIEW",
  PERMISSION_GROUP_BENEFICIARY_VIEW: "PERMISSION_GROUP_BENEFICIARY_VIEW",
  PERMISSION_GROUP_SENSITIVE_DATA_VIEW: "PERMISSION_GROUP_SENSITIVE_DATA_VIEW",
  PERMISSION_GROUP_USERS_VIEW: "PERMISSION_GROUP_USERS_VIEW",
  PERMISSION_GROUP_USERS_MANAGE: "PERMISSION_GROUP_USERS_MANAGE",
  PERMISSION_GROUP_REFUNDS_AND_PAYBACKS_VIEW:
    "PERMISSION_GROUP_REFUNDS_AND_PAYBACKS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS:
    "PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS",
  PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS:
    "PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS",
  PERMISSION_GROUP_PRODUCT_ACTIVATION_MANAGE:
    "PERMISSION_GROUP_PRODUCT_ACTIVATION_MANAGE",
  PERMISSION_GROUP_VERIFICATION_VIEW: "PERMISSION_GROUP_VERIFICATION_VIEW",
  PERMISSION_GROUP_AIS_NOTIFICATION_VIEW:
    "PERMISSION_GROUP_AIS_NOTIFICATION_VIEW",
  PERMISSION_GROUP_AIS_NOTIFICATION_MANAGE:
    "PERMISSION_GROUP_AIS_NOTIFICATION_MANAGE",
  PERMISSION_GROUP_MANAGE_SETTLEMENT_BATCHES:
    "PERMISSION_GROUP_MANAGE_SETTLEMENT_BATCHES",
  PERMISSION_GROUP_AIS_VIEW: "PERMISSION_GROUP_AIS_VIEW",
  PERMISSION_GROUP_AIS_MANAGE: "PERMISSION_GROUP_AIS_MANAGE",
  PERMISSION_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW",
  PERMISSION_GROUP_PAY_BY_LINK_MANAGE: "PERMISSION_GROUP_PAY_BY_LINK_MANAGE",
  PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE:
    "PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE",
  PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE:
    "PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE",
  PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS:
    "PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS",
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS:
    "PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS",
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE:
    "PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW",
  PERMISSION_GROUP_SETTLEMENT_BATCHES_VIEW:
    "PERMISSION_GROUP_SETTLEMENT_BATCHES_VIEW",
  PERMISSION_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING:
    "PERMISSION_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING",
  PERMISSION_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING:
    "PERMISSION_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING",
  PERMISSION_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING:
    "PERMISSION_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING",
  PERMISSION_GROUP_VCM_MERCHANT_ROLE: "PERMISSION_GROUP_VCM_MERCHANT_ROLE",
  PERMISSION_GROUP_VCM_MERCHANT_VIEW_ROLE:
    "PERMISSION_GROUP_VCM_MERCHANT_VIEW_ROLE",
  PERMISSION_GROUP_VCM_PAYOUTS_CREATE: "PERMISSION_GROUP_VCM_PAYOUTS_CREATE",
  PERMISSION_GROUP_VCM_TRANSACTIONS_VIEW:
    "PERMISSION_GROUP_VCM_TRANSACTIONS_VIEW",
  PERMISSION_GROUP_VCM_ACCOUNTS_VIEW: "PERMISSION_GROUP_VCM_ACCOUNTS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW:
    "PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW",
  PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE:
    "PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE",
} as const satisfies PermissionGroupObject;

type PermissionVoltGroupObject = Record<
  `PERMISSION_VOLT_GROUP_${string}`,
  `PERMISSION_VOLT_GROUP_${string}`
>;
export const PERMISSION_VOLT_GROUPS = {
  PERMISSION_VOLT_GROUP_CUSTOMER_VIEW: "PERMISSION_VOLT_GROUP_CUSTOMER_VIEW",
  PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE:
    "PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE",
  PERMISSION_VOLT_GROUP_CUSTOMER_MANAGE:
    "PERMISSION_VOLT_GROUP_CUSTOMER_MANAGE",
  PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW:
    "PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW",
  PERMISSION_VOLT_GROUP_CUSTOMER_CREATION:
    "PERMISSION_VOLT_GROUP_CUSTOMER_CREATION",
  PERMISSION_VOLT_GROUP_CUSTOMER_CREDENTIALS_MANAGE:
    "PERMISSION_VOLT_GROUP_CUSTOMER_CREDENTIALS_MANAGE",
  PERMISSION_VOLT_GROUP_PRODUCT_ACTIVATION_MANAGE:
    "PERMISSION_VOLT_GROUP_PRODUCT_ACTIVATION_MANAGE",
  PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_VIEW:
    "PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_VIEW",
  PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_UPDATE:
    "PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_UPDATE",
  PERMISSION_VOLT_GROUP_BANKS_MANAGE: "PERMISSION_VOLT_GROUP_BANKS_MANAGE",
  PERMISSION_VOLT_GROUP_BANKS_VIEW: "PERMISSION_VOLT_GROUP_BANKS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_CONFIGURE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_CONFIGURE",
  PERMISSION_VOLT_GROUP_APPLICATIONS_MANAGE:
    "PERMISSION_VOLT_GROUP_APPLICATIONS_MANAGE",
  PERMISSION_VOLT_GROUP_APPLICATIONS_VIEW:
    "PERMISSION_VOLT_GROUP_APPLICATIONS_VIEW",
  PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_VIEW:
    "PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_VIEW",
  PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_MANAGE:
    "PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_MANAGE",
  PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_VIEW:
    "PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_VIEW",
  PERMISSION_VOLT_GROUP_PAYMENTS_VIEW: "PERMISSION_VOLT_GROUP_PAYMENTS_VIEW",
  PERMISSION_VOLT_GROUP_BENEFICIARY_VIEW:
    "PERMISSION_VOLT_GROUP_BENEFICIARY_VIEW",
  PERMISSION_VOLT_GROUP_SENSITIVE_DATA_VIEW:
    "PERMISSION_VOLT_GROUP_SENSITIVE_DATA_VIEW",
  PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_MANAGE:
    "PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_MANAGE",
  PERMISSION_VOLT_GROUP_USERS_VIEW: "PERMISSION_VOLT_GROUP_USERS_VIEW",
  PERMISSION_VOLT_GROUP_USERS_MANAGE: "PERMISSION_VOLT_GROUP_USERS_MANAGE",
  PERMISSION_VOLT_GROUP_REFUNDS_AND_PAYBACKS_VIEW:
    "PERMISSION_VOLT_GROUP_REFUNDS_AND_PAYBACKS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS",
  PERMISSION_VOLT_GROUP_PROVIDER_BANK_MANAGE:
    "PERMISSION_VOLT_GROUP_PROVIDER_BANK_MANAGE",
  PERMISSION_VOLT_GROUP_TRAFFIC_RELAY_CONFIG:
    "PERMISSION_VOLT_GROUP_TRAFFIC_RELAY_CONFIG",
  PERMISSION_VOLT_GROUP_AIS_SCOPE_MANAGE:
    "PERMISSION_VOLT_GROUP_AIS_SCOPE_MANAGE",
  PERMISSION_VOLT_GROUP_AIS_MANAGE: "PERMISSION_VOLT_GROUP_AIS_MANAGE",
  PERMISSION_VOLT_GROUP_VERIFICATION_VIEW:
    "PERMISSION_VOLT_GROUP_VERIFICATION_VIEW",
  PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_VIEW:
    "PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_VIEW",
  PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_MANAGE:
    "PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_MANAGE",
  PERMISSION_VOLT_GROUP_MANAGE_SETTLEMENT_BATCHES:
    "PERMISSION_VOLT_GROUP_MANAGE_SETTLEMENT_BATCHES",
  PERMISSION_VOLT_GROUP_AIS_VIEW: "PERMISSION_VOLT_GROUP_AIS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW",
  PERMISSION_VOLT_GROUP_PAY_BY_LINK_MANAGE:
    "PERMISSION_VOLT_GROUP_PAY_BY_LINK_MANAGE",
  PERMISSION_VOLT_GROUP_LATAM_SETTLEMENTS_MANAGE:
    "PERMISSION_VOLT_GROUP_LATAM_SETTLEMENTS_MANAGE",
  PERMISSION_VOLT_GROUP_LATAM_REFUNDS_MANAGE:
    "PERMISSION_VOLT_GROUP_LATAM_REFUNDS_MANAGE",
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_VIEW:
    "PERMISSION_VOLT_GROUP_FEATURE_FLAG_VIEW",
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_MANAGE:
    "PERMISSION_VOLT_GROUP_FEATURE_FLAG_MANAGE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_INTERNAL:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_INTERNAL",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW",
  PERMISSION_VOLT_GROUP_SETTLEMENT_BATCHES_VIEW:
    "PERMISSION_VOLT_GROUP_SETTLEMENT_BATCHES_VIEW",
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_CREATE:
    "PERMISSION_VOLT_GROUP_FEATURE_FLAG_CREATE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_MANAGE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_MANAGE",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_VIEW",
  PERMISSION_VOLT_GROUP_WORKFLOWS_APPROVE:
    "PERMISSION_VOLT_GROUP_WORKFLOWS_APPROVE",
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_ADMIN_MANAGE:
    "PERMISSION_VOLT_GROUP_BILLING_ENGINE_ADMIN_MANAGE",
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_VIEW:
    "PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_VIEW",
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_MANAGE:
    "PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_MANAGE",
  PERMISSION_VOLT_GROUP_SETTLEMENT_CANCEL:
    "PERMISSION_VOLT_GROUP_SETTLEMENT_CANCEL",
  PERMISSION_VOLT_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING:
    "PERMISSION_VOLT_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING",
  PERMISSION_VOLT_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING:
    "PERMISSION_VOLT_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING",
  PERMISSION_VOLT_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING:
    "PERMISSION_VOLT_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING",
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ADMIN_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_FINOPS_ADMIN_ROLE",
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ANALYST_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_FINOPS_ANALYST_ROLE",
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_ROLE",
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_EXT_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_EXT_ROLE",
  PERMISSION_VOLT_GROUP_VCM_COMPLIANCE_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_COMPLIANCE_ROLE",
  PERMISSION_VOLT_GROUP_VCM_PERMISSIONS_ADMIN_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_PERMISSIONS_ADMIN_ROLE",
  PERMISSION_VOLT_GROUP_VCM_MERCHANT_VIEW_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_MERCHANT_VIEW_ROLE",
  PERMISSION_VOLT_GROUP_VCM_MERCHANT_ROLE:
    "PERMISSION_VOLT_GROUP_VCM_MERCHANT_ROLE",
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_REQUEST:
    "PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_REQUEST",
  PERMISSION_VOLT_GROUP_VCM_ACCOUNTS_VIEW:
    "PERMISSION_VOLT_GROUP_VCM_ACCOUNTS_VIEW",
  PERMISSION_VOLT_GROUP_VCM_FAILED_CREDITS_VIEW:
    "PERMISSION_VOLT_GROUP_VCM_FAILED_CREDITS_VIEW",
  PERMISSION_VOLT_GROUP_VCM_TRANSACTIONS_VIEW:
    "PERMISSION_VOLT_GROUP_VCM_TRANSACTIONS_VIEW",
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_ACCOUNT_LOCKS_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_ACCOUNT_LOCKS_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_CONFIG_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_SYSTEM_CONFIG_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_SUPPORT_REPROCESS_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_SUPPORT_REPROCESS_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_TRUSTED_ACCOUNTS_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_TRUSTED_ACCOUNTS_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_PAYOUTS_CREATE:
    "PERMISSION_VOLT_GROUP_VCM_PAYOUTS_CREATE",
  PERMISSION_VOLT_GROUP_VCM_ROLES_MANAGE:
    "PERMISSION_VOLT_GROUP_VCM_ROLES_MANAGE",
  PERMISSION_VOLT_GROUP_VCM_ADMIN: "PERMISSION_VOLT_GROUP_VCM_ADMIN",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW",
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE:
    "PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE",
} as const satisfies PermissionVoltGroupObject;

export const PERMISSION_GROUPS = {
  ...PERMISSION_STANDARD_GROUPS,
  ...PERMISSION_VOLT_GROUPS,
} as const;

export type PERMISSION_GROUP_LIST_ALL = keyof typeof PERMISSION_GROUPS;
export type PERMISSION_GROUP_LIST_STANDARD =
  keyof typeof PERMISSION_STANDARD_GROUPS;
export type PERMISSION_GROUP_LIST_VOLT = keyof typeof PERMISSION_VOLT_GROUPS;

export function isGroupNameCorrect(
  groupName: string
): groupName is PERMISSION_GROUP_LIST_ALL {
  return PERMISSION_GROUPS.hasOwnProperty(groupName);
}
