export const ACTION_CREATE = "create";
export const ACTION_LIST = "list";
export const ACTION_UPDATE = "update";
export const ACTION_DELETE = "delete";
export const ACTION_DETAILS = "details";
export const ACTION_TEST = "test";
export const ACTION_GLOBAL_FILTER = "globalFilter";
export const ACTION_APPROVE = "approve";

export type Action =
  | typeof ACTION_APPROVE
  | typeof ACTION_CREATE
  | typeof ACTION_DELETE
  | typeof ACTION_DETAILS
  | typeof ACTION_GLOBAL_FILTER
  | typeof ACTION_LIST
  | typeof ACTION_TEST
  | typeof ACTION_UPDATE;

export const ACTIONS_READ: Action[] = [ACTION_DETAILS, ACTION_LIST];
export const ACTIONS_WRITE: Action[] = [ACTION_CREATE, ACTION_UPDATE];
export const ACTIONS_CRU: Action[] = [...ACTIONS_READ, ...ACTIONS_WRITE];
