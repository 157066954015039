import { useTranslation } from "react-i18next";

import { PageError } from "components/PageError";
import { PageWrapper } from "components/PageWrapper";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { usePaymentsToTransactionRedirect } from "modules/payments/hooks/usePaymentsToTransactionRedirect";
import { usePaymentsToRefundsRedirect } from "modules/refunds/hooks/usePaymentsToRefundsRedirect";

import type { ReactNode } from "react";

export interface Page404Props {
  buttonLabel?: ReactNode;
  description?: ReactNode;
  goBackLink?: string;
  title?: ReactNode;
}

export function Page404({
  buttonLabel,
  description,
  goBackLink,
  title,
}: Page404Props) {
  const { t } = useTranslation("common");

  // Redirect users from old bookmarked URL to the new one
  usePaymentsToTransactionRedirect();
  usePaymentsToRefundsRedirect();

  return (
    <PageWrapper>
      <PageError
        goBackLink={goBackLink ?? "/"}
        title={title ?? t(COMMON_TRANSLATION_KEYS.notFoundError)}
        buttonLabel={
          buttonLabel ?? t(COMMON_TRANSLATION_KEYS.returnToDashboard)
        }
        description={
          description ?? t(COMMON_TRANSLATION_KEYS.sorryPageNotFound)
        }
      />
    </PageWrapper>
  );
}
