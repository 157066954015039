import { useSelector } from "react-redux";

import { ENV_PRODUCTION, ENV_SANDBOX } from "constants/environment";

import type { RootState } from "store";

export function useAppEnvironmentSelector() {
  return useSelector((state: Pick<RootState, "app">) => state.app.environment);
}

export function useIsProductionSelector() {
  return useSelector(
    (state: Pick<RootState, "app">) => state.app.environment === ENV_PRODUCTION
  );
}

export function useIsSandboxSelector() {
  return useSelector(
    (state: Pick<RootState, "app">) => state.app.environment === ENV_SANDBOX
  );
}
