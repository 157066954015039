import { createContext, useCallback, useState } from "react";

import type { ReactNode} from "react";

import type { BreadcrumbItem } from "../../models/breadcrumbs";

export interface BreadcrumbsContextParams {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: (items: BreadcrumbItem[]) => void;
}

export const BreadcrumbsContext =
  createContext<BreadcrumbsContextParams | null>(null);

export interface BreadcrumbsProviderProps {
  children: ReactNode;
}

export function BreadcrumbsProvider({ children }: BreadcrumbsProviderProps) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const handleSetBreadcrumbs = useCallback(
    (newBreadcrumbs: BreadcrumbItem[]) => {
      setBreadcrumbs(newBreadcrumbs);
    },
    [setBreadcrumbs]
  );

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs: handleSetBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
}
