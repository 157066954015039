import { useActive } from "hooks/useActive";
import { useStorage } from "hooks/useStorage";

export function useApplicationShell() {
  const [isOpen, setIsOpen] = useStorage("isSidebarOpen", true);
  const { isActive, toggleActive } = useActive(isOpen);
  const {
    isActive: isSidebarAnimationInProgress,
    activate: activateSidebarAnimationIn,
    deactivate: deactivateSidebarAnimation,
  } = useActive(false);

  function toggleSidebar() {
    toggleActive();
    setIsOpen(!isOpen);
    activateSidebarAnimationIn();
  }

  function handleTransitionEnd() {
    deactivateSidebarAnimation();
  }

  return {
    isSidebarOpen: isActive,
    toggleSidebar,
    handleTransitionEnd,
    isSidebarAnimationInProgress,
  };
}
