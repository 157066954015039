interface UseBreadcrumbParams {
  isLast: boolean;
  isDisabledByConfig?: boolean;
  url?: string | null;
}

export function useBreadcrumb({
  isLast,
  isDisabledByConfig,
  url,
}: UseBreadcrumbParams) {
  return {
    isDisabled: isLast || isDisabledByConfig || !url,
    isActive: isLast,
  };
}
