import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import styled from "styled-components";

import { theme } from ".";

import type { PropsWithChildren } from "react";

const generateClassName = createGenerateClassName({
  seed: "legacy",
});

const StyledScopedCssBaseline = styled(ScopedCssBaseline)`
  background: transparent;
  width: 100%;

  .legacy-MuiInputBase-input {
    box-sizing: content-box;
  }
`;

/**
 * @deprecated Do not use it in the new code base.
 * @todo Remove it with material-ui library
 */
export const OldTheme = ({ children }: PropsWithChildren<{}>) => (
  <MuiThemeProvider theme={theme}>
    <StylesProvider generateClassName={generateClassName}>
      <StyledScopedCssBaseline>{children}</StyledScopedCssBaseline>
    </StylesProvider>
  </MuiThemeProvider>
);
