/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  beAtLeast7CharactersLong: "Be at least 7 characters long",
  byLoginBelowYouConsentToTppToInitiatePayment:
    "By login below, you consent to {{tpp}} to initiate a payment",
  cancel: "Cancel",
  confirm: "Confirm",
  confirmPassword: "Confirm password",
  containAtLeastOneDigit: "Contain at least one digit",
  containAtLeastOneUppercaseAndOneLowercaseLetter:
    "Contain at least one uppercase and one lowercase letter",
  continue: "Continue",
  dontHaveAnAccountYet: "Don't have an account yet?",
  emailAddress: "Email address",
  enterEmailAndWeWillSendResetLink:
    "Enter the email address associated with your account and we'll send you a link to reset your password.",
  forgotPassword: "Forgot password",
  goToLogInPage: "Go to log in page",
  login: "Login",
  logIn: "Log in",
  manageCookiePreferences: "Manage cookie preferences",
  password: "Password",
  passwordHasBeenChanged: "Password has been changed.",
  paymentAuthentication: "Payment authentication",
  paymentDay: "Payment Date",
  paymentTotal: "Payment total",
  pleaseConfirmYourIdentityUsingSelectedSCAMethod:
    "Please confirm your identity using the selected SCA method.",
  pleaseConfirmYourRequestUsingSelectedSCAMethod:
    "Please confirm your request using the selected SCA method.",
  pleaseEnterANewPasswordAboveYourPasswordShould:
    "Please enter a new password above. Your password should",
  proxyLoginFailed: "Proxy login failed.",
  proxyLoginInvalidToken: "Proxy login invalid token.",
  reference: "Reference",
  rememberMyEmail: "Remember my email",
  resetYourPassword: "Reset your password",
  returnToLogin: "Return to login",
  sca: "SCA",
  scaLoginHasBeenCancelled: "SCA login has been cancelled.",
  scaProcessHasBeenCanceledPleaseTryAgain:
    "SCA process has been canceled. Please try again.",
  setNewPassword: "Set new password",
  setupSCA: "Set up SCA",
  signUp: "Sign Up",
  somethingWentWrongPleaseTryAgainLaterOrContactSupport:
    "Something went wrong, please try again later or contact support.",
  strongCustomerAuthenticationHasBeenEnabled:
    "Strong customer authentication has been enabled.",
  submit: "Submit",
  theEmailHasBeenSentCheckYourInboxForFurtherInstructions:
    "The email has been sent! Check your inbox for further instructions.",
  tokenIsNotValid: "Token is not valid",
  transactionFinalizationSigningFailed:
    "An issue occurred during the finalization of transaction signing.",
  transactionInitiationSigningFailed:
    "An issue occurred during the initiation of transaction signing.",
  tryAgain: "Try again",
  twoFA: "2FA",
  verifyingRecaptcha: "Verifying recaptcha",
  verifyingRecaptchaFailedClickHereToTryAgain:
    "Verifying recaptcha failed. Click here to try again",
};
