import { filterAndTrimKeysByPrefix } from "../filterAndTrimKeysByPrefix";

import type { Range, Step } from "../types";

// For reference how we approach z-index in Fuzebox please check this link:
// https://www.notion.so/welcome-to-volt/ADR-007-Consistent-semantic-and-bug-free-way-of-describing-z-indexes-f776d65c3eaa4372b9664f7a45ddff15?pvs=4
const Z_INDEX_ALL = {
  FUZEBOX_SIDEBAR_HIDDEN: -1 as const,
  FUZEBOX_FILTERS: 5 as const,
  MUI_MOBILE_STEPPER: 1000 as const,
  MUI_FAB: 1050 as const,
  MUI_SPEED_DIAL: 1050 as const,
  MUI_APP_BAR: 1100 as const,
  MUI_DRAWER: 1200 as const,
  FUZEBOX_HEADER: 1225 as const,
  FUZEBOX_SIDEBAR_VISIBLE: 1250 as const,
  MUI_MODAL: 1300 as const,
  MUI_SNACKBAR: 1400 as const,
  MUI_TOOLTIP: 1500 as const,
  // Below const is fully controlled by React Toastify & Volt Component. We are keeping it here as a reference only.
  FUZEBOX_TOAST: 9999 as const,
} satisfies Record<
  `FUZEBOX_${string}` | `MUI_${string}` | `TOASTIFY_${string}`,
  -1 | 9999 | Range<1000, Step<5>, 2000>
>;

export const Z_INDEX = filterAndTrimKeysByPrefix(Z_INDEX_ALL, "FUZEBOX_");

export const MUI_Z_INDEX = filterAndTrimKeysByPrefix(Z_INDEX_ALL, "MUI_");
