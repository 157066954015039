import { DateTime } from "luxon";

import {
  BLOCK_RULE_TYPE_BANK,
  BLOCK_RULE_TYPE_DOCUMENT_ID,
  BLOCK_RULE_TYPE_FINGERPRINT,
  BLOCK_RULE_TYPE_IBAN,
  BLOCK_RULE_TYPE_IP_ADDRESS,
  BLOCK_RULE_TYPE_MAIL,
  BLOCK_RULE_TYPE_PAYER,
  VOLT_ADMIN,
} from "../constants/Rules";

import type { BlockRule, BlockRuleType } from "../models/BlockRules";

const TYPE_TO_TEXT: Record<BlockRuleType, string> = {
  [BLOCK_RULE_TYPE_BANK]: "bank branch",
  [BLOCK_RULE_TYPE_FINGERPRINT]: "device fingerprint",
  [BLOCK_RULE_TYPE_DOCUMENT_ID]: "document ID",
  [BLOCK_RULE_TYPE_MAIL]: "email address",
  [BLOCK_RULE_TYPE_IBAN]: "IBAN",
  [BLOCK_RULE_TYPE_IP_ADDRESS]: "IP Address",
  [BLOCK_RULE_TYPE_PAYER]: "payer ID",
};

export function translateBlockRuleToDuplicateError(
  blockRule: BlockRule
): string {
  // TODO rework to "rich" content. Task: https://supercharged.atlassian.net/browse/MP-660
  const blockType = TYPE_TO_TEXT[blockRule.type];
  const value = Boolean(blockRule.bank)
    ? blockRule.bank?.branchName
    : blockRule.value;
  const user = blockRule.username ?? VOLT_ADMIN;
  const date = DateTime.fromISO(blockRule.blockedAt)
    .setLocale("en-GB")
    .toLocaleString(DateTime.DATETIME_MED);

  return `The ${blockType} ${
    value ?? "---"
  } has already been blocked by ${user} at ${date}.`;
}
