import { useSelector } from "react-redux";

import { useOperationState } from "hooks/operationsState";

import { authOperationPath, selectCurrentUser } from "../store/auth.slice";

export function useCurrentUser() {
  const currentUser = useSelector(selectCurrentUser);
  const { operationLoading, operationError } = useOperationState(
    authOperationPath.currentUser
  );

  return {
    currentUser,
    isLoading: operationLoading,
    isError: operationError,
  };
}
