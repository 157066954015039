import {
  createApi,
  fetchBaseQuery
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { ENV_PRODUCTION, SERVICES } from "constants/environment";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
/* eslint-disable no-restricted-imports */
// Exception: Import from inside of module is needed to remove error in tests
// "TypeError: Cannot read properties of undefined (reading 'injectEndpoints')".
import {
  logout,
  selectAccessToken,
  selectRefreshToken,
  setAccessTokenData,
} from "modules/auth/store/auth.slice";
import { prepareTokenData } from "modules/auth/store/utils";
/* eslint-enable no-restricted-imports */
import { clearGlobalFilteredCustomer, selectEnvironment } from "store/appSlice";
import { paramsSerializer } from "utils/paramsSerializer";

import { ENV_INSENSITIVE_SERVICES_URLS } from "./helpers/EnvInsensitiveServicesUrls";
import { resolveApiUrl } from "./helpers/resolveApiUrl";
import { TAGS } from "./helpers/tags";
import {
  isEnvInsensitiveService,
  isEnvSensitiveService,
  isLoginApiResult,
} from "./helpers/typeGuards";

import type { RootState } from "store";

import type {
  ExtraOptions,
  ExtraOptionsEnvInsensitive,
  ExtraOptionsEnvSensitive,
} from "./helpers/optionModels";

const entryPointBaseQuery = fetchBaseQuery({
  baseUrl: resolveApiUrl(ENV_PRODUCTION),
  paramsSerializer,
});

const nonEnvironmentAwareBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError,
  ExtraOptionsEnvInsensitive
> = async function (args, api, extraOptions) {
  const { service, ignoreAuthorizationInterception } = extraOptions;

  const rawBaseQuery = getQuery(ENV_INSENSITIVE_SERVICES_URLS[service], {
    ignoreAuthorizationInterception,
  });

  return rawBaseQuery(args, api, extraOptions);
};

const environmentAwareBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError,
  ExtraOptionsEnvSensitive
> = async (args, api, extraOptions) => {
  const { service = SERVICES.API, env } = extraOptions ?? {};
  const environment = selectEnvironment(api.getState() as RootState);

  const rawBaseQuery = getQuery(resolveApiUrl(env ?? environment, service));

  return rawBaseQuery(args, api, extraOptions);
};

const unauthorizedMutex = new Mutex();

const authAwareBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const options = { service: SERVICES.API, ...(extraOptions ?? {}) };

  await unauthorizedMutex.waitForUnlock();
  const isEnvInsensitive = isEnvInsensitiveService(options);
  const isEnvSensitive = isEnvSensitiveService(options);

  if (!isEnvSensitive && !isEnvInsensitive) {
    throw new Error(`Provided service ${extraOptions.service} doesn't exists`);
  }

  let result = await (isEnvInsensitive
    ? nonEnvironmentAwareBaseQuery(args, api, options)
    : environmentAwareBaseQuery(args, api, options));

  if (result.error?.status !== 401) {
    return result;
  }

  if (unauthorizedMutex.isLocked()) {
    await unauthorizedMutex.waitForUnlock();

    return await (isEnvInsensitive
      ? nonEnvironmentAwareBaseQuery(args, api, options)
      : environmentAwareBaseQuery(args, api, options));
  }

  const release = await unauthorizedMutex.acquire();
  const refresh_token = selectRefreshToken(api.getState() as RootState);

  if (!refresh_token) {
    api.dispatch(logout());

    return result;
  }

  try {
    const refreshResult = await entryPointBaseQuery(
      { url: "/token/refresh", method: "POST", body: { refresh_token } },
      api,
      extraOptions
    );

    if (isLoginApiResult(refreshResult.data)) {
      const tokenData = prepareTokenData(refreshResult.data);

      api.dispatch(clearGlobalFilteredCustomer());
      api.dispatch(setAccessTokenData(tokenData));
      result = await (isEnvInsensitive
        ? nonEnvironmentAwareBaseQuery(args, api, options)
        : environmentAwareBaseQuery(args, api, options));
    } else {
      api.dispatch(logout());
    }
  } finally {
    release();
  }

  return result;
};

export const portalApi = createApi({
  reducerPath: "portalApi",
  baseQuery: authAwareBaseQuery,
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: entryPointBaseQuery,
  endpoints: () => ({}),
});

export interface ApiResponseError {
  status: number;
}

export function isApiResponseError(data: unknown): data is ApiResponseError {
  return typeof data === "object" && data !== null && "status" in data;
}

export { TAGS };

type GetQueryOptions = Pick<ExtraOptions, "ignoreAuthorizationInterception">;

function getQuery(baseUrl: string, options?: GetQueryOptions) {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      if (options?.ignoreAuthorizationInterception) {
        return headers;
      }

      const token = selectAccessToken(getState() as RootState);

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
    paramsSerializer,
  });
}
