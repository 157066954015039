import { useParams } from "react-router-dom";

import { useRejectRefund } from "../../hooks/useRejectRefund";

import type { ObjectWithId } from "utils/ObjectWithId";

export interface UseRejectRefundConfirmModalParams {
  onClose(): void;
}

export function useRejectRefundConfirmModal({
  onClose,
}: UseRejectRefundConfirmModalParams) {
  const { id } = useParams<ObjectWithId>();

  const { rejectRefund, isRejectRefundLoading } = useRejectRefund();

  const handleConfirm = async () => {
    await rejectRefund(id);
    onClose();
  };

  return {
    handleConfirm,
    isLoading: isRejectRefundLoading,
  };
}
