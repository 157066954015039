import {
  Bank,
  Fingerprint,
  IBAN,
  ID,
  INCLUSION_STATES,
  InternetProtocol,
  Mail,
  Payer,
} from "@volt_developers/react-ui-components";

import {
  BLOCK_RULE_TYPE_BANK,
  BLOCK_RULE_TYPE_DOCUMENT_ID,
  BLOCK_RULE_TYPE_FINGERPRINT,
  BLOCK_RULE_TYPE_IBAN,
  BLOCK_RULE_TYPE_IP_ADDRESS,
  BLOCK_RULE_TYPE_MAIL,
  BLOCK_RULE_TYPE_PAYER,
} from "./constants/Rules";

import type { TableProps } from "@volt_developers/react-ui-components";
import type { ElementType } from "react";

import type { BlockRule, BlockRuleType } from "./models/BlockRules";
import type { RiskRuleFiltersOptions } from "./models/RiskRule";

export const BLOCKLIST_ICONS: Record<BlockRuleType, ElementType> = {
  [BLOCK_RULE_TYPE_BANK]: Bank,
  [BLOCK_RULE_TYPE_FINGERPRINT]: Fingerprint,
  [BLOCK_RULE_TYPE_DOCUMENT_ID]: ID,
  [BLOCK_RULE_TYPE_MAIL]: Mail,
  [BLOCK_RULE_TYPE_IBAN]: IBAN,
  [BLOCK_RULE_TYPE_IP_ADDRESS]: InternetProtocol,
  [BLOCK_RULE_TYPE_PAYER]: Payer,
};

export const DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_TYPE = BLOCK_RULE_TYPE_BANK;
export const DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_ACTIVE_FLAG = true;
export const VOLT_CONNECT_LINK = "https://www.volt.io/products/connect";

export const BLOCKLIST_COLUMNS: TableProps<BlockRule>["columns"] = [
  {
    key: "type",
    label: "",
    isSortable: false,
    width: 32,
    padding: "none",
  },
  {
    key: "value",
    label: "value",
    // TODO temporarily disabled due to API issues
    isSortable: false,
    width: "40%",
  },
  {
    key: "userId",
    label: "blocked by",
    isSortable: true,
    width: "40%",
  },
  {
    key: "blockedAt",
    label: "at",
    isSortable: true,
  },
];

export const RISK_RULES_DEFAULT_FILTER_OPTIONS: RiskRuleFiltersOptions = {
  currency: {
    hasInclusion: false,
    hasSearch: true,
    inclusion: INCLUSION_STATES.include,
  },
  country: {
    hasInclusion: true,
    hasSearch: true,
    inclusion: INCLUSION_STATES.include,
  },
};
