import { addMethod, string } from "yup";

import { CNPJPattern } from "../../patterns";

declare module "yup" {
  interface StringSchema {
    validatePIXKey(message?: string): this;
  }
}

addMethod(string, "validatePIXKey", function (message = "Invalid CNPJ") {
  return this.matches(CNPJPattern, {
    message,
    name: "validatePIXKey",
    excludeEmptyString: false,
  });
});
