import { useState } from "react";

export function useLoading() {
  const [isLoading, setIsLoading] = useState(false);

  const withLoading =
    <CallbackType extends (...args: unknown[]) => Promise<any>>(
      callback: CallbackType
    ) =>
    async <
      ArgsType extends Parameters<CallbackType>,
      CallbackReturnType extends ReturnType<CallbackType>,
    >(
      args?: ArgsType
    ): Promise<CallbackReturnType> => {
      try {
        setIsLoading(true);

        return await callback(args);
      } finally {
        setIsLoading(false);
      }
    };

  return {
    isLoading,
    withLoading,
  };
}
