import { useAbility } from "@casl/react";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { AbilityContext } from "context/Ability.context";
import { appRoutes } from "routes";

import { useMenu } from "./menu";

import type { Route } from "routes/RouteModel";

const FORCE_DISPLAY = true;

const findCorrectRoute = (routes: Route[], pathname: string) =>
  routes.find((menuElement) =>
    Boolean(
      matchPath(pathname, {
        path: menuElement.path,
        exact: menuElement.path === "/",
      })
    )
  );

const findCorrectChild = (route: Route, pathname: string) => {
  if (!route.children) {
    return null;
  }

  return findCorrectRoute(route.children, pathname);
};

export const useExactRouteChild = () => {
  const [route, setRoute] = useState({} as Route);
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const { menu } = useMenu(appRoutes, ability, FORCE_DISPLAY);

  useEffect(() => {
    const correctRoute = findCorrectRoute(menu, location.pathname);

    if (!correctRoute) {
      return;
    }

    const correctChild = findCorrectChild(correctRoute, location.pathname);

    if (correctChild) {
      return setRoute(correctChild);
    }

    setRoute(correctRoute);
  }, [location.pathname, menu]);

  return { route };
};
