import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_CREATE } from "models/role/action";
import { SUBJECT_PAY_BY_LINK } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PayByLinkPageUrls";

import type { Route } from "routes/RouteModel";

const PayByLinkCreate = lazyRetry(
  () => import("../pages/pay-by-link/Create"),
  "PayByLinkCreate"
);

const PayByLinkCreateRedirect = lazyRetry(
  () => import("../pages/pay-by-link/PayByLinkCreateRedirect"),
  "PayByLinkCreateRedirect"
);

export const payByLinkRoutes: Route[] = [
  {
    title: [
      COMMON_TRANSLATION_KEYS.payments,
      COMMON_TRANSLATION_KEYS.newPayByLink,
    ],
    path: PAGE_URLS.create,
    newTheme: true,
    component: PayByLinkCreate,
    can: {
      do: ACTION_CREATE,
      on: SUBJECT_PAY_BY_LINK,
    },
  },
  {
    title: [
      COMMON_TRANSLATION_KEYS.payments,
      COMMON_TRANSLATION_KEYS.newPayByLink,
    ],
    path: PAGE_URLS.createRedirect,
    newTheme: true,
    component: PayByLinkCreateRedirect,
    can: {
      do: ACTION_CREATE,
      on: SUBJECT_PAY_BY_LINK,
    },
  },
];
