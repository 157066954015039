import { createAction } from "@reduxjs/toolkit";

import type { RequestResetPasswordEmailParams } from "../models/RequestResetPasswordEmailParams";
import type { ResetPasswordParams } from "../models/ResetPasswordParams";

export const sendResetPasswordRequest =
  createAction<RequestResetPasswordEmailParams>("sendResetPasswordRequest");
export const resetPassword = createAction<ResetPasswordParams>("resetPassword");
export const validateResetPasswordToken = createAction<string>(
  "validateResetPasswordToken"
);

export const resetPasswordOperationPaths = {
  request: ["users", "requestPasswordReset"],
  reset: ["users", "resetPassword"],
  validate: ["users", "validateResetToken"],
};
