import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUserAnalyticsProperties } from "modules/auth/hooks/useCurrentUserAnalyticsProperties";

const DEFAULT_PAGE_TITLE = "Volt Fuzebox" as const;

export const useRouterAnalytics = () => {
  const userAnalyticsProperties = useCurrentUserAnalyticsProperties();
  const location = useLocation();
  const [currentPageTitle, setCurrentPageTitle] =
    useState<string>(DEFAULT_PAGE_TITLE);
  const lastReportedPageTitle = useRef("");

  const onHelmetChangeClintState = ({ title }: { title?: string }) => {
    if (title && title !== DEFAULT_PAGE_TITLE) {
      setCurrentPageTitle(title);
    }
  };

  useEffect(() => {
    if (
      currentPageTitle !== DEFAULT_PAGE_TITLE &&
      currentPageTitle !== lastReportedPageTitle.current
    ) {
      lastReportedPageTitle.current = currentPageTitle;
      window.gtag?.("event", "page_view", {
        ...userAnalyticsProperties,
        page_title: currentPageTitle,
        page_location: location.pathname,
      });
    }
  }, [
    currentPageTitle,
    location,
    lastReportedPageTitle,
    userAnalyticsProperties,
  ]);

  return {
    onHelmetChangeClintState,
    defaultPageTitle: DEFAULT_PAGE_TITLE,
  };
};
