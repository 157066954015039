import { useLayoutEffect, useRef, useState } from "react";

import type { MutableRefObject} from "react";

export function useOnScreen(
  ref: MutableRefObject<HTMLElement | null>,
  trigger?: unknown
) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(
    new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting))
  );

  useLayoutEffect(() => {
    const observerValue = observerRef.current;

    if (ref.current) {
      observerValue.observe(ref.current);
    }

    return () => {
      observerValue.disconnect();
    };
  }, [ref, trigger]);

  return isOnScreen;
}
