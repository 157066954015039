import { ButtonDeprecated, Tooltip } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { DisabledTooltipAnchor } from "modules/common/components/DisabledTooltipAnchor";

import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";

import type { ReactNode } from "react";

export interface DisabledApproveButtonProps {
  tooltipTitle: NonNullable<ReactNode>;
}

export function DisabledApproveButton({
  tooltipTitle,
}: DisabledApproveButtonProps) {
  const { t } = useTranslation("refunds");

  return (
    <Tooltip title={tooltipTitle} arrow>
      <DisabledTooltipAnchor>
        <ButtonDeprecated disabled>
          {t(TRANSLATION_KEYS.approve)}
        </ButtonDeprecated>
      </DisabledTooltipAnchor>
    </Tooltip>
  );
}
