import { generateUUID } from "utils";

import { REPORT_STATUS } from "../constants/Reports";
import { formatReportAmount as formatAmount } from "./formatReportAmount";
import { getReportFormatCurrency } from "./getReportFormatCurrency";

import type { GenerateReportProps, Report } from "../models/Reports";

export function buildReportForOptimisticUpdate({
  filters,
}: GenerateReportProps): Report {
  const formatCurrency = getReportFormatCurrency(filters.currency);

  return {
    id: generateUUID(),
    status: REPORT_STATUS.IN_PROGRESS,
    parameters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      statuses: filters.statuses,
      licence: filters.licences,
      currency: filters.currency,
      country: filters.country,
      minAmount:
        filters.minAmount && formatAmount(filters.minAmount, formatCurrency),
      maxAmount:
        filters.maxAmount && formatAmount(filters.maxAmount, formatCurrency),
      customerHierarchyId: filters.customerHierarchyId,
      excludeAbandoned: !filters.noBankChosen,
    },
    generatedAt: null,
  };
}
