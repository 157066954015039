import { addMethod, string } from "yup";

import { brazilianPhoneNumberPattern } from "../../patterns";

declare module "yup" {
  interface StringSchema {
    validateBrazilianPhoneNumber(message?: string): this;
  }
}

addMethod(
  string,
  "validateBrazilianPhoneNumber",
  function (message = "Invalid phone number") {
    return this.matches(brazilianPhoneNumberPattern, {
      message,
      name: "validateBrazilianPhoneNumber",
      excludeEmptyString: false,
    });
  }
);
