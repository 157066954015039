import { useState } from "react";

export function useActive(defaultValue = false) {
  const [isActive, setIsActive] = useState(defaultValue);

  const activate = () => setIsActive(true);
  const deactivate = () => setIsActive(false);
  const toggleActive = () => setIsActive((prev) => !prev);

  return { isActive, activate, deactivate, toggleActive };
}
