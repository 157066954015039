import * as Sentry from "@sentry/react";
import { Route } from "react-router-dom";

import type { History } from "history";

function sentrySanitizeUrl(url: string | undefined) {
  return String(url).replace(/https?:\/\//, "");
}

export const SentryRoute = Sentry.withSentryRouting(Route);

export function sentryConfig(
  environment: string,
  history: History<unknown>
): Sentry.BrowserOptions {
  return {
    dsn: import.meta.env.FUZEBOX_SENTRY_URL,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    // do not add api domains that do not allow CORS headers: "sentry-trace", "baggage"
    tracePropagationTargets: [
      sentrySanitizeUrl(import.meta.env.FUZEBOX_API_URL),
      sentrySanitizeUrl(import.meta.env.FUZEBOX_CONNECT_MAIN_URL),
      sentrySanitizeUrl(import.meta.env.FUZEBOX_USERS_URL),
      sentrySanitizeUrl(import.meta.env.FUZEBOX_NOTIFICATIONS_MAIN_URL),
    ],
    integrations:
      import.meta.env.FUZEBOX_ENV === "rc"
        ? [
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
              maskAllInputs: false,
            }),
          ]
        : [
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
            Sentry.browserTracingIntegration({
              shouldCreateSpanForRequest: () => {
                return true;
              },
              idleTimeout: 2000,
              beforeStartSpan: (context) => {
                return {
                  ...context,
                  name: window.location.pathname
                    .replace(
                      /\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g,
                      "/<uuid>"
                    )
                    .replace(/(\/reset-password\/)[^/|$]+/g, "$1<token>"),
                };
              },
            }),
            Sentry.replayIntegration({
              maskAllText: true,
              blockAllMedia: true,
              maskAllInputs: true,
            }),
          ],
    environment,
    tracesSampler: (samplingContext) => {
      if (
        samplingContext.location?.pathname.match(
          /^\/transactions\/(payments|payouts)$/
        ) ||
        samplingContext.location?.pathname.match(
          /^\/transactions\/payments\/[^/]+\/refunds$/
        )
      ) {
        return 1.0;
      }

      if (samplingContext.location?.pathname.match(/^\/connect$/)) {
        return 0.5;
      }

      if (
        samplingContext.location?.pathname.match(
          /^\/transactions\/(payments|payouts)\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/[^/]+)?/
        ) ||
        samplingContext.location?.pathname.match(
          /^\/transactions\/(payments)\/[^/]+\/refunds\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
        )
      ) {
        return 0.3;
      }

      if (
        samplingContext.location?.pathname.match(
          /^\/configuration\/(applications|users|customers|accounts)$/
        ) ||
        samplingContext.location?.pathname.match(
          /^\/circuit-breaker\/[^/]+$/
        ) ||
        samplingContext.location?.pathname.match(
          /^\/(settings|checkout-branding)$/
        ) ||
        samplingContext.location?.pathname.match(
          /^\/connect(\/provider-config)?$/
        )
      ) {
        return 0.2;
      }

      if (
        samplingContext.location?.pathname.match(
          /^\/configuration\/(applications|users|customers|accounts)\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/[^/]+)?$/
        )
      ) {
        return 0.1;
      }

      if (samplingContext.location?.pathname.match(/^\/login$/)) {
        return 0.05;
      }

      return 0.0;
    },
    beforeSend(event, hint) {
      // https://supercharged.atlassian.net/browse/FBI-299
      const errorMessage = hint.originalException
        ? (hint.originalException as Error).message
        : "";

      const errorsToIgnore = new Set([
        "Cannot read properties of undefined (reading 'stopTimer')",
        "Unhandled Promise Rejection: Can't find variable: FileReader",
      ]);

      if (errorsToIgnore.has(errorMessage)) {
        return null;
      }

      return event;
    },
  };
}
