import { call, delay, put, select, takeLatest } from "redux-saga/effects";

import { portalApi, TAGS } from "api/rtkApi";
import { showMessageToast } from "components/toasts/MessageToast";
import { selectCurrentUserId , setCurrentUserTermsAndConditions } from "modules/auth/store/auth.slice";
import { callApi } from "sagas/helpers/api";
import { setError, setSuccess, startLoading } from "store/appSlice";

import * as TermsAndConditionsApi from "../api/TermsAndConditionsApi";
import { TEST_IDS } from "../constants/testIds";

import {
  acceptLicenceAgreement,
  fetchLicenceAgreementFile,
  fetchLicenceAgreementLastUpdate,
  operationPath as operationPaths,
  setLicenceAgreementFile,
  setLicenceAgreementLastUpdate,
} from "./index";

import type { PayloadAction } from "@reduxjs/toolkit";

import type {
  LicenceAgreementFile,
  LicenceAgreementLastUpdate,
} from "../models/TermsAndConditions";

const MAX_RETRIES = 5;
const RETRY_DELAY_IN_SECONDS = 5;

function* fetchLicenceAgreementLastUpdateSaga() {
  let retries = 0;
  const operationPath = operationPaths.licenceAgreementLastUpdate;

  while (true) {
    if (retries >= MAX_RETRIES) {
      console.info("Failed to fetch licence agreement last update");

      return;
    }

    try {
      yield put(startLoading(operationPath));
      const response: LicenceAgreementLastUpdate = yield call(
        TermsAndConditionsApi.getLicenceAgreementLastUpdate
      );

      yield put(setLicenceAgreementLastUpdate(response));
      yield put(setSuccess(operationPath));

      return;
    } catch (error) {
      yield put(
        setError({
          path: operationPath,
          error,
        })
      );
      yield delay(RETRY_DELAY_IN_SECONDS * 1000);
      retries += 1;
    }
  }
}

interface FetchLicenceAgreementFilePayload {
  version: string;
}

function* fetchLicenceAgreementFileSaga({
  payload,
}: PayloadAction<FetchLicenceAgreementFilePayload>) {
  let retries = 0;
  const { version } = payload;
  const operationPath = operationPaths.licenceAgreementFile;

  while (true) {
    if (retries >= MAX_RETRIES) {
      console.info("Failed to fetch licence agreement file");

      return;
    }

    try {
      yield put(startLoading(operationPath));
      const response: LicenceAgreementFile = yield call(
        TermsAndConditionsApi.getLicenceAgreementFile,
        version
      );

      yield put(setLicenceAgreementFile(response));
      yield put(setSuccess(operationPath));

      return;
    } catch (error) {
      yield put(
        setError({
          path: operationPath,
          error,
        })
      );
      yield delay(RETRY_DELAY_IN_SECONDS * 1000);
      retries += 1;
    }
  }
}

function* acceptLicenceAgreementSaga() {
  const operationPath = operationPaths.acceptLicenceAgreement;

  yield put(startLoading(operationPath));

  try {
    yield callApi(TermsAndConditionsApi.postTermAndConditionsAccept);
    const userId: string = yield select(selectCurrentUserId);

    yield put(portalApi.util.invalidateTags([{ type: TAGS.USER, id: userId }]));
    yield put(
      setCurrentUserTermsAndConditions({ date: new Date().toISOString() })
    );
    yield put(setSuccess(operationPath));
    showMessageToast({
      config: { toastId: TEST_IDS.termsAndConditionsSuccessToast },
      variant: "success",
      bodyContent:
        "Thank you for accepting our updated evaluation licence agreement. You’re now able to use Fuzebox to its full potential.",
    });
  } catch (error) {
    console.error(error);
    showMessageToast({
      config: { toastId: TEST_IDS.termsAndConditionsErrorToast },
      variant: "success",
      bodyContent:
        "Something went wrong. Try again or contact with support@volt.io",
    });
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

export function* rootSaga() {
  yield takeLatest(
    fetchLicenceAgreementLastUpdate.type,
    fetchLicenceAgreementLastUpdateSaga
  );

  yield takeLatest(
    fetchLicenceAgreementFile.type,
    fetchLicenceAgreementFileSaga
  );

  yield takeLatest(acceptLicenceAgreement.type, acceptLicenceAgreementSaga);
}
