import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useErrorCodeTranslation } from "hooks/errors";
import { removeSnackbar } from "store/appSlice";

import { Snackbar } from "./Snackbar";

import type { OptionsObject , SnackbarMessage} from "notistack";

import type { RootState } from "store";
import type { Snackbar as SnackbarType } from "store/appSlice";

type SnackbarId = SnackbarType["key"];

interface SnackbarWithAdditionalData extends Omit<SnackbarType, "options"> {
  message: string;
  options: OptionsObject;
  dismissed?: boolean;
  dynamicContent?: boolean;
}

let displayed: SnackbarId[] = [];

/**
 * @deprecated Old toast engine, do not use `Snackbars`, use helpers base on `React-Toastify`
 * @see useTranslatedToast
 * @see showMessageToast
 */
export function Notifier() {
  const dispatch = useDispatch();
  const snackbars = useSelector(
    (store: RootState) => store.app.snackbars ?? []
  );
  const snackbarsKeys = useSelector(
    (store: RootState) => store.app.snackbarsKeys ?? []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const persistentSnackbarMessages = useRef<Map<number | string, string>>(
    new Map()
  );

  const { et } = useErrorCodeTranslation();

  const storeDisplayed = (id: SnackbarId) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarId) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    (snackbars as SnackbarWithAdditionalData[]).forEach(
      ({
        key,
        message,
        i18nMessage,
        options = {},
        dynamicContent = false,
        dismissed = false,
      }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);

          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key) && !dynamicContent) {
          return;
        }

        const messageText = i18nMessage ? et(i18nMessage) : message;

        if (
          dynamicContent &&
          persistentSnackbarMessages.current.get(key) !== messageText
        ) {
          persistentSnackbarMessages.current.set(key, messageText);
        }

        // display snackbar using notistack
        enqueueSnackbar(messageText, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            if (dynamicContent) {
              persistentSnackbarMessages.current.delete(key);
            }

            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
          content: (key, message: SnackbarMessage = "") => {
            if (!dynamicContent) {
              return <Snackbar message={message} type={options?.variant} />;
            }

            return (
              <Snackbar
                message={persistentSnackbarMessages.current.get(key) ?? ""}
                type={options?.variant}
              />
            );
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [snackbars, snackbarsKeys, closeSnackbar, enqueueSnackbar, dispatch, et]);

  return null;
}
