import { Typography } from "@mui/material";
import { Dialog } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

export function DialogContent() {
  const { t } = useTranslation("refunds");

  return (
    <Dialog.Content
      sx={{
        p: 4,
        pb: 0,
        width: CONTENT_WIDTH,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ p: 4, pb: 3 }} variant="body1">
        {t(TRANSLATION_KEYS.areYouSureYouWantToRejectThisRefund)}
      </Typography>
    </Dialog.Content>
  );
}

const CONTENT_WIDTH = 576;
