import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import jwtDecode from "jwt-decode";

import type { UUID } from "utils";

import type { ROLE_2FA_AUTH_REQUIRED } from "../config/roles";
import type { PERMISSION_GROUP_LIST_ALL } from "../constants/permissionGroups";

export interface LoginApiResult {
  access_token: string;
  expires_in: number;
  refresh_token: string | null;
  token_type: "Bearer";
}

// for JWT size reduction, permissions groups in JWT have shortened prefixes
type RenamePrefix<Name extends string> =
  Name extends `PERMISSION_GROUP_${infer SplitName}`
    ? `PG_${SplitName}`
    : Name extends `PERMISSION_VOLT_GROUP_${infer SplitName}`
    ? `PVG_${SplitName}`
    : never;

type JwtPermission = keyof {
  [Property in PERMISSION_GROUP_LIST_ALL as RenamePrefix<Property>]: RenamePrefix<Property>;
};

export type AllowedStateRoles =
  | never[]
  | readonly [typeof ROLE_2FA_AUTH_REQUIRED];

export type Available2FAType = (keyof typeof TWO_FA_TYPES)[];

export interface JwtPayload {
  customer_id: string;
  roles: AllowedStateRoles;
  user_id: string;
  username: string;
  volt_employee: boolean;
  available_2fa?: Available2FAType;
  permissions?: JwtPermission[];
  sca_subject_id?: UUID;
}

export interface SerializedAuthUser {
  accessToken: string;
  customerId: string;
  expirationTimestamp: number;
  permissions: PERMISSION_GROUP_LIST_ALL[];
  refreshToken: string | null;
  roles: AllowedStateRoles;
  tokenExpiryMs: number;
  userId: string;
  username: string;
  voltEmployee: boolean;
  available_2fa?: Available2FAType;
  sca_subject_id?: UUID;
}

export const TWO_FA_TYPES = {
  email: "email",
  SCA: "SCA",
} as const;

export function prepareTokenData(
  apiResult: LoginApiResult
): SerializedAuthUser {
  const accessToken = apiResult.access_token;
  const tokenPayload = jwtDecode<JwtPayload>(accessToken);

  const tokenExpiryMs = apiResult.expires_in * 1000;

  return {
    accessToken,
    refreshToken: apiResult.refresh_token,
    expirationTimestamp: Date.now() + tokenExpiryMs,
    tokenExpiryMs,
    userId: tokenPayload.user_id,
    username: tokenPayload.username,
    customerId: tokenPayload.customer_id,
    roles: tokenPayload.roles,
    voltEmployee: tokenPayload.volt_employee,
    permissions: rewritePermissionGroupNames(tokenPayload.permissions ?? []),
    available_2fa: tokenPayload.available_2fa,
    sca_subject_id: tokenPayload.sca_subject_id,
  };
}

export function rewritePermissionGroupNames(permissions: JwtPermission[]) {
  return permissions.map((permission) => {
    if (permission.startsWith("PG_")) {
      return `PERMISSION_GROUP_${permission.slice(3)}`;
    }

    if (permission.startsWith("PVG_")) {
      return `PERMISSION_VOLT_GROUP_${permission.slice(4)}`;
    }

    return permission;
  }) as PERMISSION_GROUP_LIST_ALL[];
}

function createBroadcast<T>(channelName: string) {
  const channel = new BroadcastChannel(channelName);
  const elector = createLeaderElection(channel, { fallbackInterval: 500 });

  if (!elector.hasLeader) {
    elector.awaitLeadership();
  }

  elector.onduplicate = () => {
    elector.die().then(() => {
      elector.awaitLeadership();
    });
  };

  return {
    onUpdate: function (cb: (payload: T) => void) {
      channel.onmessage = (data) => {
        cb(data);
      };
    },
    close: function () {
      channel.close();
    },
    sendUpdate: function (data: T) {
      channel.postMessage(data);
    },
    getIsLeader: function () {
      return elector.isLeader;
    },
    getIsDead: function () {
      return elector.isDead;
    },
    validateLeader: async function () {
      if (elector.hasLeader) {
        await elector.die();
        await elector.awaitLeadership();
      }
    },
  };
}

export const tokenBroadcastChannel =
  createBroadcast<SerializedAuthUser>("TOKEN");
export const logoutBroadcastChannel = createBroadcast<string>("LOGOUT");
