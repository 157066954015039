import { Typography } from "@mui/material";
import { Banner, ExclamationMarkIcon } from "@volt_developers/components";

import { useSCAConfirmBanner } from "./useSCAConfirmBanner";

import type { SxProps, Theme} from "@mui/material";
import type { ReactNode } from "react";

interface SCAConfirmBannerProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export function SCAConfirmBanner({ children, sx }: SCAConfirmBannerProps) {
  const { shouldShowBanner } = useSCAConfirmBanner();

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Banner.Wrapper sx={sx ?? { my: 3 }} variant="info" isTransparent>
      <Banner.Content>
        <Banner.IconWrapper>
          <ExclamationMarkIcon />
        </Banner.IconWrapper>
        <Typography variant="h7">{children}</Typography>
      </Banner.Content>
    </Banner.Wrapper>
  );
}
