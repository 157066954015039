import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_CREATE, ACTION_DETAILS } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_ACCOUNT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const CreateAccount = lazyRetry(
  () => import("../pages/accounts/Create"),
  "CreateAccount"
);

const AccountPage = lazyRetry(() => import("../pages/account"), "AccountPage");

export const accountsRoutes: Route[] = [
  {
    title: [
      COMMON_TRANSLATION_KEYS.connectAccounts,
      COMMON_TRANSLATION_KEYS.new,
    ],
    path: PAGE_URLS.accountCreate,
    newTheme: true,
    component: CreateAccount,
    can: {
      do: ACTION_CREATE,
      on: SUBJECT_CASH_MANAGEMENT_ACCOUNT,
    },
  },
  {
    // title for this path is defined inside accountDetails routing
    path: PAGE_URLS.accountDetails,
    component: AccountPage,
    newTheme: true,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_ACCOUNT,
    },
    hasNestedSwitch: true,
  },
];
