import { alpha, Skeleton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { TEST_IDS } from "../../../constants/TestIds";

export interface GlobalFilterLabelProps {
  isLoading: boolean;
  customerName?: string;
}

export function GlobalFilterLabel({
  isLoading,
  customerName,
}: GlobalFilterLabelProps) {
  const { t } = useTranslation("common");

  const globalFilterCustomerLabel = customerName
    ? customerName
    : t(COMMON_TRANSLATION_KEYS.allCustomers);

  if (isLoading) {
    return (
      <GlobalFilterLoader
        data-testid={TEST_IDS.globalFilterLoader}
        variant="text"
        width={150}
      />
    );
  }

  return <span>{globalFilterCustomerLabel}</span>;
}

const GlobalFilterLoader = styled(Skeleton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.ui20, 0.1),
}));
