import { call, put, takeLatest } from "redux-saga/effects";

import { setError, setSuccess, startLoading } from "store/appSlice";

import * as API from "../api/ResetPassword";
import {
  resetPassword,
  resetPasswordOperationPaths,
  sendResetPasswordRequest,
  validateResetPasswordToken,
} from "./resetPassword.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { RequestResetPasswordEmailParams } from "../models/RequestResetPasswordEmailParams";
import type { ResetPasswordParams } from "../models/ResetPasswordParams";

export function* sendResetPasswordRequestSaga({
  payload,
}: PayloadAction<RequestResetPasswordEmailParams>) {
  yield put(startLoading(resetPasswordOperationPaths.request));

  try {
    yield call(API.requestResetPasswordEmail, payload);
    yield put(setSuccess(resetPasswordOperationPaths.request));
  } catch (error) {
    yield put(
      setError({
        path: resetPasswordOperationPaths.request,
        error,
      })
    );
  }
}

export function* resetPasswordSaga({
  payload,
}: PayloadAction<ResetPasswordParams>) {
  yield put(startLoading(resetPasswordOperationPaths.reset));

  try {
    yield call(API.resetPassword, payload);
    yield put(setSuccess(resetPasswordOperationPaths.reset));
  } catch (error) {
    yield put(
      setError({
        path: resetPasswordOperationPaths.reset,
        error,
      })
    );
  }
}

export function* validateResetPasswordTokenSaga({
  payload,
}: PayloadAction<string>) {
  yield put(startLoading(resetPasswordOperationPaths.validate));

  try {
    yield call(API.validateResetPasswordToken, payload);
    yield put(setSuccess(resetPasswordOperationPaths.validate));
  } catch (error) {
    yield put(
      setError({
        path: resetPasswordOperationPaths.validate,
        error,
      })
    );
  }
}

export function* rootSaga() {
  yield takeLatest(sendResetPasswordRequest.type, sendResetPasswordRequestSaga);
  yield takeLatest(resetPassword.type, resetPasswordSaga);
  yield takeLatest(
    validateResetPasswordToken.type,
    validateResetPasswordTokenSaga
  );
}
