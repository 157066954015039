/**
 * @deprecated Do not use it in the new code base.
 */
export const LOADING_STATE = "LOADING_STATE";

/**
 * @deprecated Do not use it in the new code base.
 */
export const ERROR_STATE = "ERROR_STATE";

/**
 * @deprecated Do not use it in the new code base.
 */
export const SUCCESS_STATE = "SUCCESS_STATE";
