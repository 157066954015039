import { useGetCustomerQuery } from "../api/Customers";

import type { ObjectWithId } from "utils/ObjectWithId";

export interface UseGetCustomerParams extends ObjectWithId {
  options?: { skip?: boolean };
}

export function useGetCustomer({ id, options }: UseGetCustomerParams) {
  const { currentData, isLoading, isFetching, isError, refetch } =
    useGetCustomerQuery({ id }, options);

  return {
    customer: currentData,
    isCustomerLoading: isLoading,
    isCustomerFetching: isFetching,
    isCustomerError: isError,
    refetchCustomer: refetch,
  };
}
