import { useActive } from "hooks/useActive";
import { useCurrentUser } from "modules/auth/hooks/useCurrentUser";

import { TEST_IDS } from "../../constants/TestIds";

const AVATAR_MENU_CONFIG = [
  {
    label: "Settings",
    to: "/settings",
    "data-testid": TEST_IDS.avatarMenuSettings,
  },
  {
    label: "Logout",
    to: "/logout",
    "data-testid": TEST_IDS.avatarMenuLogout,
  },
];

const DEFAULT_LABEL = "V";

export function useAvatar() {
  const { currentUser, isLoading } = useCurrentUser();
  const user = currentUser ?? {};
  const { isActive, toggleActive, deactivate } = useActive();

  function getLabel() {
    if (typeof user?.name !== "string") {
      return DEFAULT_LABEL;
    }

    const label = user.name.replace(/[^A-Z]+/g, "").substring(0, 2);

    if (label === "") {
      return user.name.substring(0, 2).toUpperCase();
    }

    return label;
  }

  return {
    menuConfig: AVATAR_MENU_CONFIG,
    isActive,
    isLoading,
    toggleActive,
    deactivate,
    getLabel,
  };
}
