import { lazy } from "react";

import type { ComponentType, LazyExoticComponent } from "react";

export function lazyRetry<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }> | Promise<any>,
  name: string,
  next?: (module: any) => { default: T }
): LazyExoticComponent<T> {
  const storageName = `retry-${name}-refreshed`;

  return lazy(
    () =>
      new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
          sessionStorage.getItem(storageName) || "false"
        );

        componentImport()
          .then((component) => {
            sessionStorage.setItem(storageName, "false");

            resolve(next ? next(component) : component);
          })
          .catch((error) => {
            if (!hasRefreshed) {
              sessionStorage.setItem(storageName, "true");

              return window.location.reload();
            }

            reject(error);
          });
      })
  );
}
