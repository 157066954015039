import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { BASE_URL } from "../constants/PageUrls";

const OLD_BASE_URL = "/payments/transactions";

const getNewUrlFromOldUrl = (url: string): string | null => {
  if (url.startsWith(OLD_BASE_URL)) {
    return url.replace(OLD_BASE_URL, BASE_URL);
  }

  return null;
};

export function usePaymentsToTransactionRedirect() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const newUrl = getNewUrlFromOldUrl(location.pathname);

    if (newUrl) {
      history.push(newUrl);
    }
  }, [location, history]);
}
