import {
  AFTER_OR_SAME_AS_SPECIFIC_DATE,
  ALPHANUMERIC_ONLY,
  BEFORE_OR_SAME_AS_SPECIFIC_DATE,
  CUSTOMER_IS_NOT_VOLT_MEMBER,
  ENTER_CUSTOMER_NAME,
  FIELD_REQUIRED,
  GENERIC_ERROR,
  INVALID_CREDENTIALS,
  INVALID_DATE,
  INVALID_EMAIL_ADDRESS,
  INVALID_GENERIC,
  INVALID_HEX_COLOR,
  INVALID_IP,
  INVALID_PASSWORD,
  INVALID_PASSWORD_SHORT,
  INVALID_RESET_PASSWORD_TOKEN,
  INVALID_SORT_CODE_VALUE,
  INVALID_SWIFT_VALUE,
  INVALID_URL,
  INVALID_UUID,
  LENGTH_EQUAL,
  LENGTH_MAX,
  LENGTH_MIN,
  LESS_THEN_NOW,
  MORE_THAN_ZERO,
  MORE_THEN_NOW,
  NAMED_FIELD_LENGTH_MAX,
  NO_CUSTOMERS_FOUND,
  NUMBERS_ONLY,
  PASSWORDS_MISMATCH,
  PATTERN_MISMATCH,
  REQUEST_FAILED,
  SEARCH_LENGTH_MIN,
  TOO_MANY_REQUESTS,
  TOO_MANY_REQUESTS_PLURAL,
  TOO_MANY_REQUESTS_SHORT_TIME,
} from "constants/errors";

export const errors = {
  [FIELD_REQUIRED]: "This field is required",
  [SEARCH_LENGTH_MIN]: "Please enter more then {{$minLength}} character",
  [INVALID_EMAIL_ADDRESS]: "Please, provide valid E-mail",
  [INVALID_IP]: "Please, provide valid IP",
  [INVALID_UUID]: "Please, provide valid UUID",
  [INVALID_SORT_CODE_VALUE]: "Please, provide valid sort code",
  [INVALID_SWIFT_VALUE]: "Please, provide valid Swift code (BIC)",
  [INVALID_URL]: "Invalid format of URL",
  [INVALID_GENERIC]: "Please enter a valid {{$fieldName}}",
  [PASSWORDS_MISMATCH]: "Passwords do not match.",
  [INVALID_PASSWORD]:
    "Password must be 7 characters and contain at least one digit, one upper case and one lower case letter.",
  [INVALID_PASSWORD_SHORT]:
    "The password is invalid, check the requirements below.",
  [INVALID_CREDENTIALS]: "Incorrect email or password.",
  [INVALID_RESET_PASSWORD_TOKEN]: "Invalid password reset link.",
  [GENERIC_ERROR]: "Something went wrong, please try again.",
  [REQUEST_FAILED]: "Request failed", // TODO: more granular errors
  [NAMED_FIELD_LENGTH_MAX]:
    "{{$fieldName}} cannot be longer than {{$maxLength}} symbols",
  [LENGTH_MIN]: "This field must be at least {{$minLength}} characters long",
  [LENGTH_MAX]: "This field must be max {{$maxLength}} characters long",
  [LENGTH_EQUAL]: "This field must be {{$length}} characters long",
  [INVALID_HEX_COLOR]: "Invalid color, please provide a hex color",
  [TOO_MANY_REQUESTS]:
    "Too many login attempts, please try again in {{count}} second.",
  [TOO_MANY_REQUESTS_PLURAL]:
    "Too many login attempts, please try again in {{count}} seconds.",
  [TOO_MANY_REQUESTS_SHORT_TIME]:
    "Too many login attempts, please try again in short time.",
  [PATTERN_MISMATCH]: "This field accepts only {{$characters}}",
  [AFTER_OR_SAME_AS_SPECIFIC_DATE]: "Date should be after or same as {{$date}}",
  [BEFORE_OR_SAME_AS_SPECIFIC_DATE]:
    "Date should be before or same as {{$date}}",
  [INVALID_DATE]: "Date should be in {{$format}} format",
  [MORE_THEN_NOW]: "Date should be in the future",
  [ENTER_CUSTOMER_NAME]: "Enter customer's name",
  [NO_CUSTOMERS_FOUND]: "No customers found",
  [LESS_THEN_NOW]: "Date should be in the past",
  [CUSTOMER_IS_NOT_VOLT_MEMBER]: "Volt user cannot be assigned to a customer",
  [ALPHANUMERIC_ONLY]:
    "This field accepts only alphanumeric values. No special symbols allowed.",
  [NUMBERS_ONLY]: "This field accepts only numbers",
  [MORE_THAN_ZERO]: "This field must be greater than zero",
};
