import { useTranslation } from "react-i18next";

export type TFunctionResult =
  | Array<object | string>
  | number
  | object
  | string
  | null
  | undefined;

export type InterpolationType = {
  [key: string]: TFunctionResult;
};

const GENERIC_ERROR = "GENERIC_ERROR";

export function useErrorCodeTranslation() {
  const { t } = useTranslation("errors");

  return {
    et: (codeCode: string, interpolation?: InterpolationType) => {
      return t(codeCode || GENERIC_ERROR, interpolation);
    },
  };
}
