import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { SCA_ERRORS } from "../services/sca";

const MAP_ERROR_MESSAGES_TO_TRANSLATION_KEYS = {
  [SCA_ERRORS.TECHNICAL_ERROR]: COMMON_TRANSLATION_KEYS.sorrySomethingWentWrong,
  default: COMMON_TRANSLATION_KEYS.sorrySomethingWentWrong,
} as const;

type ErrorMessage = keyof typeof MAP_ERROR_MESSAGES_TO_TRANSLATION_KEYS;

export function getSCATranslationKey(error: ErrorMessage = "default") {
  if (!Object.keys(MAP_ERROR_MESSAGES_TO_TRANSLATION_KEYS).includes(error)) {
    return MAP_ERROR_MESSAGES_TO_TRANSLATION_KEYS.default;
  }

  return MAP_ERROR_MESSAGES_TO_TRANSLATION_KEYS[error];
}
