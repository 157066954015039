import { call } from "redux-saga/effects";

import type { CallEffect, SagaReturnType } from "redux-saga/effects";

import type { ApiCallParameters } from "sagas/helpers/api";

export function* callAuthApiSaga<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: ApiCallParameters<Parameters<Fn>>
): Generator<any> {
  let response;
  try {
    const callArgs = [...args];

    response = yield call(fn, ...(callArgs as Parameters<Fn>));
  } catch (error) {
    throw error;
  }

  return response;
}

export function callAuthApi<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: ApiCallParameters<Parameters<Fn>>
): CallEffect<SagaReturnType<Fn>> {
  return call(callAuthApiSaga, fn, ...args);
}
