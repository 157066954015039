import { styled } from "@mui/system";
import { ActionBar } from "@volt_developers/components";

import { PageActionBar } from "components/PageActionBar";

import { ActionLabel } from "../ActionLabel";
import { ApproveButton } from "../ApproveButton";
import { RejectButton } from "../RejectButton";

export interface DetailsActionBarProps {
  isOpen: boolean;
}

export function DetailsActionBar({ isOpen }: DetailsActionBarProps) {
  return (
    <PageActionBar isOpen={isOpen}>
      <ActionLabel />
      <ActionsWrapper>
        <ActionBar.Actions>
          <RejectButton />
          <ApproveButton />
        </ActionBar.Actions>
      </ActionsWrapper>
    </PageActionBar>
  );
}

const ActionsWrapper = styled("div")({
  marginLeft: "auto",
});
