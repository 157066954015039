import * as Yup from "yup";

import { INVALID_IP } from "constants/errors";
import { ipPattern } from "utils/patterns";

declare module "yup" {
  export interface StringSchema {
    validateIP(): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, "validateIP", function () {
  return this.matches(ipPattern, INVALID_IP);
});
