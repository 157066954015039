import { useEffect, useState } from "react";

import type { CardClipboardSecretTextProps } from "./CardClipboardSecretText";

interface UseCardClipboardSecretTextParams
  extends Pick<
    CardClipboardSecretTextProps,
    "isVisible" | "onToggleVisibility"
  > {}

export function useCardClipboardSecretText({
  isVisible: isVisibleProp,
  onToggleVisibility,
}: UseCardClipboardSecretTextParams) {
  const [isVisible, setIsSecretVisible] = useState(isVisibleProp ?? false);

  useEffect(() => {
    if (isVisibleProp !== undefined) {
      setIsSecretVisible(isVisibleProp);
    }
  }, [isVisibleProp, setIsSecretVisible]);

  const toggleVisibility = () => {
    if (onToggleVisibility) {
      onToggleVisibility();

      return;
    }

    setIsSecretVisible((prev) => !prev);
  };

  return {
    isVisible,
    toggleVisibility,
  };
}
