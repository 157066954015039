export const paramsSerializer = (params: Record<string, any>) => {
  const urlParams = new URLSearchParams();

  Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .forEach(([key, value]) =>
      Array.isArray(value)
        ? value.forEach((arrayValue) =>
            urlParams.append(`${key}[]`, arrayValue)
          )
        : urlParams.append(key, value)
    );

  return String(urlParams);
};
