import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  StyledEngineProvider,
  ThemeProvider as ThemeProviderV5,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  theme as voltThemeV5,
  ToastDeprecatedContainer,
  Utility,
} from "@volt_developers/components";
import {
  SidebarProvider,
  theme as voltTheme,
} from "@volt_developers/react-ui-components";
import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";

import { AbilityContext } from "context/Ability.context";
import { useDefineAbility } from "hooks/useDefineAbility";
import { SCAProvider } from "modules/auth/context/SCAContext";
import { useResetPersistedFilters } from "modules/common/hooks/usePersistedFilters";
import { MainSwitch } from "routes/switches/MainSwitch";
import { init } from "store/appSlice";
import { theme } from "theme";

export function App() {
  const dispatch = useDispatch();

  useResetPersistedFilters();

  //We want this to run before children useEffects
  //that are not delayed using lazy loading
  useLayoutEffect(() => {
    dispatch(init());
  }, [dispatch]);

  const ability = useDefineAbility();

  return (
    <React.Fragment>
      <Utility.Normalize />
      <AbilityContext.Provider value={ability}>
        <SCAProvider>
          <StyledEngineProvider injectFirst>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <MuiThemeProvider theme={voltTheme}>
                  <ThemeProviderV5 theme={voltThemeV5}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <SidebarProvider>
                        <ToastDeprecatedContainer />
                        <MainSwitch />
                      </SidebarProvider>
                    </LocalizationProvider>
                  </ThemeProviderV5>
                </MuiThemeProvider>
              </ThemeProvider>
            </StylesProvider>
          </StyledEngineProvider>
        </SCAProvider>
      </AbilityContext.Provider>
    </React.Fragment>
  );
}
