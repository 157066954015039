import { useAbility } from "hooks/useAbility";
import { useEnvironmentStatus } from "hooks/useEnvironmentStatus";
import { ACTION_APPROVE } from "models/role/action";
import { SUBJECT_REFUND } from "models/role/subject";
import { useSCA } from "modules/auth/hooks/useSCA";

import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";

export function useActionLabel() {
  const { hasSCA } = useSCA();
  const { isSandbox } = useEnvironmentStatus();
  const ability = useAbility();
  const hasAbilityToApproveRefunds = ability.can(
    ACTION_APPROVE,
    SUBJECT_REFUND
  );
  const labelTranslationKey = getLabelTranslationKey(
    hasSCA,
    isSandbox,
    hasAbilityToApproveRefunds
  );

  return { labelTranslationKey };
}

function getLabelTranslationKey(
  hasSCA: boolean,
  isSandbox: boolean,
  hasAbilityToApproveRefunds: boolean
) {
  if (isSandbox || hasSCA || !hasAbilityToApproveRefunds) {
    return null;
  }

  return TRANSLATION_KEYS.scaRequiredToApproveARefund;
}
