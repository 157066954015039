import { styled } from "@mui/material";
import { ButtonDeprecated, Dialog } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { TEST_IDS } from "../../../refunds/constants";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

export interface DialogActionsProps {
  isLoading: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export function DialogActions({
  onCancel,
  onConfirm,
  isLoading,
}: DialogActionsProps) {
  const { t } = useTranslation("refunds");

  return (
    <Dialog.Actions sx={{ px: 4, pt: 1.5, pb: 3 }}>
      <ButtonsWrapper>
        <ButtonDeprecated color="grey" size="large" variant="outlined" onClick={onCancel}>
          {t(COMMON_TRANSLATION_KEYS.cancel, {
            ns: "common",
          })}
        </ButtonDeprecated>
        <ButtonDeprecated
          color="danger"
          data-testid={TEST_IDS.rejectRefundConfirmModalConfirmButton}
          loading={isLoading}
          size="large"
          onClick={onConfirm}
        >
          {t(TRANSLATION_KEYS.reject)}
        </ButtonDeprecated>
      </ButtonsWrapper>
    </Dialog.Actions>
  );
}

const ButtonsWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
  gap: 24,
});
