import { useDispatch, useSelector } from "react-redux";

import {
  ERROR_STATE,
  LOADING_STATE,
  SUCCESS_STATE,
} from "constants/operationsState";
import {
  clearOperation as clearOperationAction,
  clearResult as clearResultAction,
  selectOperationState,
} from "store/appSlice";

import { useDeepCompareCallback } from "./deepComparison";

import type {
  OperationPath} from "store/appSlice";

/**
 * @deprecated Do not use it in the new code base. RTK Query takes care about status flags
 */
export function useOperationState(
  path?: OperationPath,
  { defaultLoading = false } = {}
) {
  const state = useSelector(selectOperationState(path));
  const dispatch = useDispatch();
  const clearOperation = useDeepCompareCallback(() => {
    dispatch(clearOperationAction(path));
  }, [dispatch, path]);

  const clearResult = useDeepCompareCallback(() => {
    dispatch(clearResultAction(path));
  }, [dispatch, path]);

  return {
    operationLoading:
      state?.type === LOADING_STATE ? true : !state?.type && defaultLoading,
    operationError: state?.type === ERROR_STATE ? state?.error : null,
    operationSuccess: state?.type === SUCCESS_STATE,
    clearOperation,
    clearResult,
  };
}
