import { createAction } from "@reduxjs/toolkit";

import {
  DEFAULT_RULE,
  RISK_RULE_OPERATOR_GREATER_THAN,
  RISK_RULE_PERIOD_HOUR,
  RISK_RULE_TYPE_NUMBER_OF_PAYMENTS,
  RISK_RULE_TYPE_PAYMENT_AMOUNT,
  RISK_RULE_TYPE_VOLUME_OF_TRANSACTIONS,
} from "modules/circuit-breaker/constants/Rules";
import { createListSelectors, createListSlice } from "store/helpers/list";
import { defaultCurrency } from "utils/currencies";
import { generateUniqueKey } from "utils/generateUniqueKey";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { APPLICATION_ENV } from "constants/environment";
import type { RootState } from "store";
import type { OperationPath } from "store/appSlice";
import type { ListSliceState } from "store/helpers/list";
import type { UUID } from "utils";

import type {
  RiskRule,
  RiskRuleAlias,
  RiskRuleDraft,
} from "../../models/RiskRule";

type SliceState = ListSliceState<
  RiskRule,
  {
    draftRules: Record<APPLICATION_ENV, RiskRuleDraft[]>;
    draftsCustomerId?: UUID;
  }
>;

export function getDefaultValues(
  type?: RiskRuleAlias["type"]
): { filter: {}; payload: {} } | Pick<RiskRuleAlias, "filter" | "payload"> {
  switch (type) {
    case RISK_RULE_TYPE_PAYMENT_AMOUNT:
      return {
        payload: {
          currency: defaultCurrency.id,
          value: 100000,
        },
        filter: {
          currency: { values: [defaultCurrency.id], type: "include" },
        },
      };
    case RISK_RULE_TYPE_VOLUME_OF_TRANSACTIONS:
      return {
        payload: {
          currency: defaultCurrency.id,
          period: 1,
          periodType: RISK_RULE_PERIOD_HOUR,
          value: 300000,
        },
        filter: {
          currency: { values: [defaultCurrency.id], type: "include" },
        },
      };
    case RISK_RULE_TYPE_NUMBER_OF_PAYMENTS:
      return {
        payload: {
          value: 10,
          period: 1,
          periodType: RISK_RULE_PERIOD_HOUR,
        },
        filter: {},
      };
    default:
      return { payload: {}, filter: {} };
  }
}

export const slice = createListSlice({
  name: "circuitBreakerRiskRule",
  initialState: {
    list: [],
    draftRules: {
      prod: [],
      sandbox: [],
    },
  } as SliceState,
  reducers: {
    addDraft: (
      state,
      action: PayloadAction<{ customerId: UUID; environment: APPLICATION_ENV }>
    ) => {
      state.draftsCustomerId = action.payload.customerId;
      const selectedDraftRuleArray =
        state.draftRules[action.payload.environment];
      const listOfKeys = selectedDraftRuleArray
        .map((rule) => rule.key)
        .filter((key): key is string => Boolean(key));

      selectedDraftRuleArray.unshift({
        key: generateUniqueKey(listOfKeys),
        score: 30,
        operator: RISK_RULE_OPERATOR_GREATER_THAN,
        active: false,
        payload: {},
        filter: {},
      });
    },
    removeDraft: (
      state,
      action: PayloadAction<{ environment: APPLICATION_ENV; key: string }>
    ) => {
      const { environment, key } = action.payload;

      state.draftRules[environment] = state.draftRules[environment].filter(
        (draftRule) => draftRule.key !== key
      );
    },
    updateDraft: (
      state,
      action: PayloadAction<{
        environment: APPLICATION_ENV;
        rule: RiskRuleDraft;
      }>
    ) => {
      const { environment, rule } = action.payload;

      const foundIndex = state.draftRules[environment].findIndex(
        (draftRule) => draftRule.key === rule.key
      );

      if (foundIndex === -1) {
        return;
      }

      if (state.draftRules[environment][foundIndex].type !== rule.type) {
        const { payload, filter } = getDefaultValues(rule.type);

        state.draftRules[environment][foundIndex] = {
          ...rule,
          operator: RISK_RULE_OPERATOR_GREATER_THAN,
          payload,
          filter: {
            ...state.draftRules[environment][foundIndex].filter,
            ...filter,
          },
        } as RiskRuleDraft;

        return;
      }

      state.draftRules[environment][foundIndex] = rule;
    },
    clearAllDrafts: (state) => {
      state.draftsCustomerId = undefined;
      state.draftRules.prod = [];
      state.draftRules.sandbox = [];
    },
    clearSavedDrafts: (
      state,
      action: PayloadAction<{ environment: APPLICATION_ENV }>
    ) => {
      const { environment } = action.payload;

      state.draftRules[environment] = state.draftRules[environment].filter(
        (draftRule) => !Boolean(draftRule.id)
      );
    },
    linkDraftToRiskRule: (
      state,
      action: PayloadAction<{
        environment: APPLICATION_ENV;
        id: UUID;
        key: string;
      }>
    ) => {
      const { environment, key, id } = action.payload;
      const foundIndex = state.draftRules[environment].findIndex(
        (draftRule) => draftRule.key === key
      );

      if (foundIndex !== -1) {
        state.draftRules[environment][foundIndex] = {
          ...state.draftRules[environment][foundIndex],
          id,
          active: true,
        };
      }
    },
    insertDefaultDraftRule: (
      state,
      action: PayloadAction<{ environment: APPLICATION_ENV }>
    ) => {
      const { environment } = action.payload;

      if (!state.draftRules[environment].length) {
        const hasDefaultDraft = state.draftRules[environment].find(
          (draftRule) => draftRule.key === DEFAULT_RULE.key
        );

        if (!hasDefaultDraft) {
          state.draftRules[environment].unshift(DEFAULT_RULE);
        }
      }
    },
  },
});

export const RiskRulesOperationPaths = {
  list: ["circuitBreaker", "riskRules", "list"],
  create: ["circuitBreaker", "riskRules", "create"],
  update: ["circuitBreaker", "riskRules", "update"],
  remove: ["circuitBreaker", "riskRules", "remove"],
};

export const fetchRiskRules = createAction<{
  customerId: UUID;
  overrideOperationPath?: false | OperationPath;
}>("fetchRiskRules");

export const fetchRiskRulesSuccess = createAction("fetchRiskRulesSuccess");

export const createRiskRule = createAction<{
  customerId: UUID;
  data: RiskRuleDraft;
}>("createRiskRule");

export const updateRiskRule = createAction<{
  customerId: UUID;
  data: RiskRule;
}>("updateRiskRule");

export const removeRiskRule = createAction<{
  customerId: UUID;
  ruleId: UUID;
  draftKey?: string;
}>("removeRiskRule");

export const {
  setList: setRiskRules,
  replaceListItem: replaceRiskRule,
  addDraft: createDraftRiskRule,
  removeDraft: removeDraftRiskRule,
  updateDraft: updateDraftRiskRule,
  clearAllDrafts: clearAllDraftRiskRules,
  clearSavedDrafts: clearSavedDraftRiskRules,
  insertDefaultDraftRule,
  linkDraftToRiskRule,
  removeListItem: deleteRiskRule,
} = slice.actions;

export const selectRiskRuleDrafts =
  (environment: APPLICATION_ENV) => (state: RootState) =>
    state.circuitBreakerRiskRule.draftRules[environment];

export const {
  selectList: selectRiskRules,
  selectItemFromList: selectRiskRule,
} = createListSelectors<RiskRule>("circuitBreakerRiskRule");

export const selectDraftsCustomerId = (state: RootState) =>
  state.circuitBreakerRiskRule.draftsCustomerId;

export const circuitBreakerRiskRulesReducer = slice.reducer;
