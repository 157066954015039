import { ENV_INSENSITIVE_SERVICES_URLS } from "./EnvInsensitiveServicesUrls";

import type { LoginApiResult } from "modules/auth/store/utils";

import type {
  ExtraOptions,
  ExtraOptionsEnvInsensitive,
  ExtraOptionsEnvSensitive,
} from "./optionModels";

export function isLoginApiResult(data: unknown): data is LoginApiResult {
  return (
    typeof data === "object" &&
    data !== null &&
    data.hasOwnProperty("access_token")
  );
}

export function isEnvInsensitiveService(
  options: ExtraOptions
): options is ExtraOptionsEnvInsensitive {
  return (
    !("env" in options) &&
    Object.keys(ENV_INSENSITIVE_SERVICES_URLS).includes(options?.service ?? "")
  );
}

export function isEnvSensitiveService(
  options: ExtraOptions
): options is ExtraOptionsEnvSensitive {
  return !Object.keys(ENV_INSENSITIVE_SERVICES_URLS).includes(
    options?.service ?? ""
  );
}
