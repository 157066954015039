import * as yup from "yup";

import {
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  INVALID_URL,
} from "constants/errors";
import { emailPattern, httpsUrlPattern } from "utils/patterns";

import type { ReportNotificationsForm } from "../models/ReportNotificationsForm";

export const REPORT_NOTIFICATIONS_FORM_SCHEMA: yup.ObjectSchema<ReportNotificationsForm> =
  yup.object({
    url: yup
      .string()
      .required(FIELD_REQUIRED)
      .matches(httpsUrlPattern, INVALID_URL),
    email: yup
      .string()
      .required(FIELD_REQUIRED)
      .matches(emailPattern, INVALID_EMAIL_ADDRESS),
  });
