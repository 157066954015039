export const LIMIT_OF_PAYMENTS = 100000;

export const LIMIT_OF_ONGOING_REPORTS = 5;

export const REPORT_STATUS = {
  REQUESTED: "REQUESTED",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  FAILURE: "FAILURE",
} as const;

export const DOWNLOAD_REPORT_TYPES = {
  CSV: "csv",
  LINK: "link",
} as const;

export const REPORT_TYPES = {
  PAYMENTS: "payments",
};

export const REPORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};
