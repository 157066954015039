import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectIsAuthorized } from "modules/auth/store/auth.slice";

import { FILTER_KEY_PREFIX } from "./usePersistedFilters";

export function useResetPersistedFilters() {
  const isAuthorized = useSelector(selectIsAuthorized);

  useEffect(() => {
    if (!isAuthorized) {
      resetAllPersistedFilters();
    }
  }, [isAuthorized]);
}

function resetAllPersistedFilters() {
  Object.keys(sessionStorage)
    .filter((key) => key.startsWith(FILTER_KEY_PREFIX))
    .forEach((key) => sessionStorage.removeItem(key));
}
