import axios from "axios";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";

import type { AxiosRequestConfig } from "axios";

import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { RiskRule, RiskRuleDraft } from "../models/RiskRule";

export type GetRiskRules = (
  customerId: UUID,
  options?: AxiosRequestConfig
) => Promise<RiskRule[]>;

export const getRiskRules: GetRiskRules = async (customerId, options) => {
  try {
    const response = await axios.get<RiskRule[]>(
      `/circuit-breaker/customers/${customerId}/risk-rules`,
      {
        ...options,
        paramsSerializer,
        params: DEFAULT_PAGINATION,
      }
    );

    return response.data.map((riskRule) => ({
      ...riskRule,
    }));
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export type PostRiskRule = (
  customerId: UUID,
  data: RiskRuleDraft,
  options?: AxiosRequestConfig
) => Promise<ObjectWithId>;

export const postRiskRule: PostRiskRule = async (customerId, data, options) => {
  try {
    const postBody = {
      type: data.type,
      operator: data.operator,
      payload: data.payload,
      score: data.score,
      filter: data.filter,
      active: true,
    };

    const response = await axios.post<Pick<RiskRule, "id">>(
      `/circuit-breaker/customers/${customerId}/risk-rules`,
      postBody,
      options
    );

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export type PutRiskRule = (
  customerId: UUID,
  data: RiskRule,
  options?: AxiosRequestConfig
) => Promise<ObjectWithId>;

export const putRiskRule: PutRiskRule = async (customerId, data, options) => {
  try {
    const postBody = {
      active: data.active,
      type: data.type,
      operator: data.operator,
      payload: data.payload,
      score: data.score,
      filter: data.filter,
    };

    const response = await axios.put<Pick<RiskRule, "id">>(
      `/circuit-breaker/customers/${customerId}/risk-rules/${data.id}`,
      postBody,
      options
    );

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export type DeleteRiskRule = (
  customerId: UUID,
  ruleId: UUID,
  options?: AxiosRequestConfig
) => Promise<void>;

export const deleteRiskRule: DeleteRiskRule = async (
  customerId,
  ruleId,
  options
) => {
  try {
    await axios.delete(
      `/circuit-breaker/customers/${customerId}/risk-rules/${ruleId}`,
      options
    );
  } catch (error) {
    throw getErrorDetails(error);
  }
};
