// eslint-disable-next-line no-restricted-imports
import {
  useFinalizeSCASetupMutation,
  useInitializeSCAMutation,
} from "modules/user-settings/api/ScaApi";

import { useFinalizeSCAAuthMutation } from "../api/Auth";

export function useSCASetup() {
  const [initializeSCASetup] = useInitializeSCAMutation();
  const [finalizeSCASetup] = useFinalizeSCASetupMutation();
  const [finalizeSCA] = useFinalizeSCAAuthMutation();

  return { initializeSCASetup, finalizeSCASetup, finalizeSCA };
}
