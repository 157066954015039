import { isValidBIC } from "ibantools";
import * as yup from "yup";

declare module "yup" {
  export interface StringSchema {
    validateSWIFT(message?: string): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validateSWIFT",
  function (message = "Please, provide valid Swift code (BIC)") {
    return this.test(
      "validate-swift-bic",
      message,
      function validateSWIFT(swiftBic?: string) {
        if (!swiftBic) {
          return true;
        }

        return isValidBIC(swiftBic);
      }
    );
  }
);
