import { TEST_IDS } from "./testIds";
import { TRANSLATION_KEYS } from "./TranslationKeys";

export const TOASTS = {
  notReadLicenceAgreement: {
    config: { toastId: TEST_IDS.termsAndConditionsErrorToast },
    variant: "failure",
    bodyContent: TRANSLATION_KEYS.readLicenceAgreementFirst,
  },
} as const;
