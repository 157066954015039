import type { UUID } from "utils";

export const BASE_URL = "/transactions/refunds";

export const PAGE_URLS = {
  dashboard: "/",
  list: `${BASE_URL}`,
  getLinkToList: (paymentId: UUID) => `${BASE_URL}?transactionId=${paymentId}`,
  getLinkToPaymentRefunds: (paymentId: UUID) =>
    `/transactions/payments/${paymentId}/refunds`,
  create: `${BASE_URL}/create`,
  getLinkToCreate: (paymentId: string) =>
    `${BASE_URL}/create?transactionId=${paymentId}`,
  details: `${BASE_URL}/:id`,
  getLinkToDetails: (refundId: UUID) => `${BASE_URL}/${refundId}`,
  paymentDetails: `${BASE_URL}/:id/payments`,
  getLinkToPaymentDetails: (refundId: UUID) =>
    `${BASE_URL}/${refundId}/payments`,
} as const;
