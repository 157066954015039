import { useSomethingWentWrongToast } from "hooks/useSomethingWentWrongToast";

import { FavouriteCustomersAPI } from "../api";

import type { UUID } from "utils";

export function useFavourites() {
  const [
    addToFavouritesMutation,
    { isLoading: isAddingToFavourites, isError: isAddingToFavouritesError },
  ] = FavouriteCustomersAPI.useAddToFavouritesMutation();

  const [
    removeFromFavouritesMutation,
    {
      isLoading: isDeletingFromFavourites,
      isError: isDeletingFromFavouritesError,
    },
  ] = FavouriteCustomersAPI.useRemoveFromFavouritesMutation();

  useSomethingWentWrongToast(
    isAddingToFavouritesError || isDeletingFromFavouritesError
  );

  const isLoading = isAddingToFavourites || isDeletingFromFavourites;

  const addToFavourites = async (customerId: UUID) => {
    await addToFavouritesMutation({ id: customerId });
  };

  const removeFromFavourites = async (customerId: UUID) => {
    await removeFromFavouritesMutation({ id: customerId });
  };

  return {
    addToFavourites,
    removeFromFavourites,
    isLoading,
  };
}
