import { styled } from "@mui/material";
import { Breadcrumbs as VoltBreadcrumbs } from "@volt_developers/components";

import { TEST_IDS } from "../../constants/TestIds";
import { useBreadcrumbsContext } from "../../context/BreadcrumbsContext";
import { Breadcrumb } from "../Breadcrumb";

export function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbsContext();

  if (!breadcrumbs.length) {
    return (
      <Wrapper
        data-testid={TEST_IDS.breadcrumbsListPlaceholder}
        sx={{ height: BREADCRUMBS_HEIGHT, marginY: BREADCRUMBS_MARGIN_Y }}
      >
        <VoltBreadcrumbs.Text>
          <Placeholder />
        </VoltBreadcrumbs.Text>
      </Wrapper>
    );
  }

  return (
    <VoltBreadcrumbs.Wrapper
      sx={{ height: BREADCRUMBS_HEIGHT, marginY: BREADCRUMBS_MARGIN_Y }}
    >
      {breadcrumbs.map(({ id, ...breadcrumb }, index) => (
        <Breadcrumb
          {...breadcrumb}
          key={id}
          isLast={index === breadcrumbs.length - 1}
        />
      ))}
    </VoltBreadcrumbs.Wrapper>
  );
}

const Wrapper = styled("ul")({
  display: "flex",
  listStyle: "none",
  padding: 0,
  margin: 0,

  "& > li": {
    alignItems: "center",
  },
});

const Placeholder = styled("span")({
  width: 24,
  height: 24,
  display: "block",
});

const BREADCRUMBS_HEIGHT = 32;
const BREADCRUMBS_MARGIN_Y = 0.25;
