import { addMethod, string } from "yup";
import * as yup from "yup";

declare module "yup" {
  interface StringSchema {
    validateAndTrimRequiredString(message?: string): this;
  }
}

addMethod(
  string,
  "validateAndTrimRequiredString",
  function (message = "This field is required") {
    return this.test({
      name: "validate-and-trim-required-string",
      message,
      test: (value) => {
        return yup.string().trim().required().isValidSync(value);
      },
    });
  }
);
