import { areAllReportsGenerating } from "./areAllReportsGenerating";

import type { Report } from "../models/Reports";

export function patchPoolingReports(
  reportsInStore: Report[],
  pooledReports: Report[]
): Report[] {
  if (reportsInStore.length === 0 || areAllReportsGenerating(reportsInStore)) {
    // This branch is called when store is not yet initialized.
    // At this point user might have generated some reports that have been pushed as optimistic updates.
    // We need to merge local and remote state.
    const inStoreReportsMissingInPolledState = reportsInStore.filter(
      (report) => !pooledReports.find(({ id }) => id === report.id)
    );

    return [...inStoreReportsMissingInPolledState, ...pooledReports];
  }

  // This branch is called on all subsequent fetches.
  // Server might not know about our optimistic updated (some POST requests still in progress)
  // so we can't simply persist returned result as new state.
  // We need to diff local and remote state and upsert previously generating reports.
  return reportsInStore.map((report) => {
    const polledReport = pooledReports.find(({ id }) => report.id === id);

    return polledReport ?? report;
  });
}
