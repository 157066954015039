import { styled, Typography } from "@mui/material";
import { Toggle } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { useSwitchEnvironment } from "hooks/useSwitchEnvironment";
import { shouldForwardPropHelper } from "utils/shouldForwardPropHelper";

import { TEST_IDS } from "../../constants/TestIds";

export function EnvironmentSwitcher() {
  const { t } = useTranslation("common");
  const { changeEnvironment, isProductionEnv } = useSwitchEnvironment();
  const label = isProductionEnv
    ? t(COMMON_TRANSLATION_KEYS.productionMode)
    : t(COMMON_TRANSLATION_KEYS.sandboxMode);

  return (
    <Wrapper>
      <SwitcherLabel
        data-testid={TEST_IDS.envSwitcherLabel}
        isProduction={isProductionEnv}
      >
        {label}
      </SwitcherLabel>
      <FlexWrapper>
        <Toggle
          color="progress"
          data-testid={TEST_IDS.envSwitcherToggle}
          value={isProductionEnv}
          onChange={changeEnvironment}
        />
      </FlexWrapper>
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
});

interface SwitcherLabelProps {
  isProduction?: boolean;
}

const SWITCHER_LABEL_PROPS = new Set(["isProduction"]);

const SwitcherLabel = styled(Typography, {
  shouldForwardProp: (prop) =>
    shouldForwardPropHelper(prop, SWITCHER_LABEL_PROPS),
})<SwitcherLabelProps>(({ theme, isProduction }) => ({
  textTransform: "uppercase",
  fontSize: 12,
  marginRight: 12,
  fontWeight: 500,
  letterSpacing: 1,
  color: isProduction ? theme.palette.primary.main : theme.palette.text.low,
}));

const FlexWrapper = styled("div")({
  display: "flex",
});
