import { useMemo, useState } from "react";

import type { Dispatch, SetStateAction} from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useStorage = <T>(
  key: string,
  initialValue: T,
  onlyForCurrentSession?: boolean
): [T, SetValue<T>, () => void] => {
  const storageHandler = useMemo(
    () => (onlyForCurrentSession ? sessionStorage : localStorage),
    [onlyForCurrentSession]
  );
  const [storedValue, setStoredValue] = useState<T>(
    loadDataFromStorage(key, initialValue, storageHandler)
  );

  const setValue: SetValue<T> = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      storageHandler.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(
        `Error setting ${
          onlyForCurrentSession ? "sessionStorage" : "localStorage"
        } key “${key}”:`,
        error
      );
    }
  };

  const reloadValue = () =>
    setStoredValue(() =>
      loadDataFromStorage(key, initialValue, storageHandler)
    );

  return [storedValue, setValue, reloadValue];
};

function loadDataFromStorage<T>(
  key: string,
  initialValue: T,
  storage: Storage
) {
  try {
    const item = storage.getItem(key);

    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
}
