import type { UUID } from "utils";

export const BASE_URL = "/transactions/payouts";

export const PAGE_URLS = {
  beneficiary: `${BASE_URL}/:id/beneficiary`,
  create: `${BASE_URL}/create`,
  dashboard: "/",
  details: `${BASE_URL}/:id`,
  list: `${BASE_URL}`,
  getDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getBeneficiary: (id: UUID) => `${BASE_URL}/${id}/beneficiary`,
} as const;
