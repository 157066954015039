import axios from "axios";
import _get from "lodash/get";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import { authApi, portalApi, TAGS } from "api/rtkApi";
import {
  GENERIC_ERROR,
  INVALID_CREDENTIALS,
  TOO_MANY_REQUESTS,
} from "constants/errors";
import { prepareHeadersForSCA } from "modules/common/utils/prepareHeadersForSCA";
import { pessimisticUpdate } from "utils/pessimisticUpdate";

import { fetchCurrentUser } from "../store/auth.slice";
import { axiosAuthInstance } from "./axiosAuthInstance";

import type { ObjectWithId } from "utils/ObjectWithId";

import type { LoginCredentials } from "../models/LoginCredentials";
import type { LoginApiResult } from "../store/utils";

const genericError = {
  name: GENERIC_ERROR,
  message: GENERIC_ERROR,
  code: GENERIC_ERROR,
} as Error;

export async function authenticate(
  { email, password }: { email: string; password: string },
  options: any
) {
  try {
    const response = await axiosAuthInstance({
      ...options,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/oauth",
      data: {
        grant_type: "portal",
        username: email,
        password,
      },
    });

    const token = _get(response, "data.access_token");

    if (!token) {
      let error = {
        name: INVALID_CREDENTIALS,
        message: INVALID_CREDENTIALS,
        code: INVALID_CREDENTIALS,
      } as Error;
      throw error;
    }

    return response.data;
  } catch (error) {
    switch (error?.response?.status) {
      case 401: {
        let betterError = {
          name: INVALID_CREDENTIALS,
          message: INVALID_CREDENTIALS,
          code: INVALID_CREDENTIALS,
        } as Error;
        throw betterError;
      }
      case 429: {
        let betterError = {
          name: TOO_MANY_REQUESTS,
          message: TOO_MANY_REQUESTS,
          code: TOO_MANY_REQUESTS,
          time: error.response.headers["x-ratelimit-reset"] || false,
        } as Error;
        throw betterError;
      }
      default: {
        throw genericError;
      }
    }
  }
}

export async function refresh(
  { refreshToken }: { refreshToken: string },
  options: any
) {
  try {
    const response = await axios({
      ...options,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/token/refresh",
      data: {
        refresh_token: refreshToken,
      },
    });

    const token = _get(response, "data.access_token");

    if (!token) {
      let error = {
        name: INVALID_CREDENTIALS,
        message: INVALID_CREDENTIALS,
        code: INVALID_CREDENTIALS,
      } as Error;
      throw error;
    }

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export type PostInvalidateRefreshTokenRequest = {
  refreshToken: string;
};

type PostSCARequest = {
  bearerToken: string;
  scaToken: string;
};

export type PostEnableSCARequest = PostSCARequest & ObjectWithId;

export type PostFinalizeSCARequest = PostSCARequest;

type PostFinalizeSCAResponse = LoginApiResult;

export type PostLoginRequest = Omit<LoginCredentials, "rememberEmail"> & {
  headers?: Record<string, string>;
};

const extendedApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    authenticate: build.mutation<LoginApiResult, PostLoginRequest>({
      query: ({ email, password, headers }) => ({
        url: "/oauth",
        method: "POST",
        body: {
          grant_type: "portal",
          username: email,
          password,
        },
        headers,
      }),
    }),

    invalidateRefreshToken: build.mutation<
      undefined,
      PostInvalidateRefreshTokenRequest
    >({
      query: ({ refreshToken: refresh_token }) => ({
        url: "/token/invalidate",
        method: "POST",
        body: {
          refresh_token,
        },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    enableSCA: build.mutation<void, PostEnableSCARequest>({
      query: ({ scaToken, bearerToken }) => ({
        url: "/users/settings/sca/enable",
        body: {},
        method: "POST",
        headers: prepareHeadersForSCA(prepareHeaders(bearerToken), scaToken),
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            portalApi.util.invalidateTags([
              {
                type: TAGS.USER,
                id,
              },
            ])
          );

          dispatch(fetchCurrentUser());
        });
      },
      extraOptions: { maxRetries: 0 },
    }),

    finalizeSCAAuth: build.mutation<
      PostFinalizeSCAResponse,
      PostFinalizeSCARequest
    >({
      query: ({ scaToken, bearerToken }) => ({
        url: "/2fa/authenticate/sca",
        method: "POST",
        body: {},
        headers: prepareHeadersForSCA(prepareHeaders(bearerToken), scaToken),
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAuthenticateMutation,
  useInvalidateRefreshTokenMutation,
  useEnableSCAMutation,
  useFinalizeSCAAuthMutation,
} = extendedApi;

function prepareHeaders(bearerToken: string) {
  const headers: {
    Authorization?: string;
  } = {};

  if (bearerToken) {
    headers["Authorization"] = `Bearer ${bearerToken}`;
  }

  return headers;
}
