import * as Yup from "yup";

import { generateCustomerName } from "modules/customers/utils/generateCustomerName";

import type { Customer } from "modules/customers/models/Customer";

declare module "yup" {
  export interface StringSchema {
    validateEqualsParent(customers: Customer[]): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateEqualsParent",
  function (customersList: Customer[], message = "Please find valid parent") {
    return this.test(
      "validate-equals-parent",
      message,
      function validateEqualsParent(parentSearch?: string) {
        if (!parentSearch) {
          return true;
        }

        if (this.parent.parent === null) {
          return false;
        }

        const parentName = customersList.find(
          (customer) => customer.id === this.parent.parent
        );

        if (!parentName) {
          return false;
        }

        return parentSearch === generateCustomerName(parentName);
      }
    );
  }
);
