export function downloadCSVFile(fileContent: string, fileName: string = "") {
  const link = document.createElement("a");
  const url = URL.createObjectURL(
    new Blob([fileContent], { type: "text/csv" })
  );

  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
}
