import type { ThemeOptions } from "@material-ui/core/styles";

export const props: ThemeOptions["props"] = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: "h6" },
  },
};
