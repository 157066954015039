import { useAbility } from "hooks/useAbility";
import { useActive } from "hooks/useActive";
import { ACTION_UPDATE } from "models/role/action";
import { SUBJECT_REFUND } from "models/role/subject";

export function useRejectButton() {
  const ability = useAbility();

  const {
    isActive: isModalOpen,
    deactivate: closeModal,
    activate: openModal,
  } = useActive();

  const canRejectRefund = ability.can(ACTION_UPDATE, SUBJECT_REFUND);

  const isVisible = canRejectRefund;

  return {
    isVisible,
    isModalOpen,
    closeModal,
    openModal,
  };
}
