type SingularTranslationKeysObject<Keys extends Record<string, string>> = {
  [Key in keyof Keys as Key extends `${infer KeyWithoutSuffix}_plural`
    ? KeyWithoutSuffix
    : Key]: Key extends `${infer KeyWithoutSuffix}_plural`
    ? KeyWithoutSuffix
    : Key;
};

export function createTranslationKeysObject<
  TranslationObject extends Record<string, string>,
>(translationsObject: TranslationObject) {
  const translationKeys =
    {} as SingularTranslationKeysObject<TranslationObject>;

  for (const key of Object.keys(translationsObject)) {
    if (key.endsWith("_plural")) {
      continue;
    }

    Object.assign(translationKeys, { [key]: key });
  }

  return translationKeys;
}
