import {
  ENV_PRODUCTION,
  ENV_SANDBOX,
  SERVICES,
} from "../../constants/environment";

import type {
  APPLICATION_ENV,
  ENV_SENSITIVE_SERVICES} from "../../constants/environment";

const envSensitiveUrls: Record<
  ENV_SENSITIVE_SERVICES,
  Record<APPLICATION_ENV, string>
> = {
  [SERVICES.API]: {
    [ENV_PRODUCTION]: import.meta.env.FUZEBOX_API_URL ?? "",
    [ENV_SANDBOX]: import.meta.env.FUZEBOX_SANDBOX_API_URL ?? "",
  },
  [SERVICES.NOTIFICATIONS]: {
    [ENV_PRODUCTION]: import.meta.env.FUZEBOX_NOTIFICATIONS_MAIN_URL ?? "",
    [ENV_SANDBOX]: import.meta.env.FUZEBOX_NOTIFICATIONS_SANDBOX_URL ?? "",
  },
  [SERVICES.CONNECT]: {
    [ENV_PRODUCTION]: import.meta.env.FUZEBOX_CONNECT_MAIN_URL ?? "",
    [ENV_SANDBOX]: import.meta.env.FUZEBOX_CONNECT_SANDBOX_URL ?? "",
  },
} as const;

export function resolveApiUrl(
  environment: APPLICATION_ENV = ENV_SANDBOX,
  service: ENV_SENSITIVE_SERVICES = SERVICES.API
): string {
  return envSensitiveUrls[service][environment];
}
