/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  activateStrongCustomerAuthentication:
    "Activate strong customer authentication.",
  circuitBreakerDescription:
    "Integrate the first fraud prevention solution developed exclusively for the open banking space.",
  dashboard: "Dashboard",
  goodToSeeYouName: "Good to see you, {{name}}.",
  interestSubmitted: "Interest submitted",
  introducing: "Introducing",
  newFeature: "New feature",
  payByLinkDescription:
    "Payment links and QR codes for easy invoice payments or conversational commerce. These can be embedded into texts, emails or invoices",
  pixDescription:
    "Reach customers in Latin America’s largest economy with Pix, Brazil’s powerful instant payment system.",
  reserveDescription:
    "Set a minimum reserve amount on your account, so you always have cashflow for outgoing payments.",
  setupExtraSecurity: "Setup extra security",
  setupNow: "Set up now",
  submitInterest: "Submit interest",
  thisProductWasAlreadyActivated: "This product was already activated",
  transformerDescription:
    "When a shopper chooses to pay by bank-issued debit or credit card at the checkout, Transformer intelligently recognises who they bank with.",
  verifyDescription:
    "Automate and strengthen how you verify customer bank account ownership with our powerful AIS tool.",
  welcomeToYourFuzeboxDashboardMessage:
    "Welcome to your Fuzebox dashboard. While we build out its full suite of features, why not get to know our latest products? They’ll help you make the most of your Volt experience. ",
  youDoNotHavePermission: "You don't have permission",
};
