import { useTranslation } from "react-i18next";

import { SCAConfirmModal } from "modules/auth/components/SCAConfirmModal";

import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { useApproveRefundConfirmModal } from "./useApproveRefundConfirmModal";

export interface ApproveRefundConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (scaToken: string) => Promise<void>;
}

export function ApproveRefundConfirmModal({
  isOpen,
  onClose,
  onSuccess,
}: ApproveRefundConfirmModalProps) {
  const { t } = useTranslation("refunds");
  const { handleSubmit } = useApproveRefundConfirmModal({ onSuccess, onClose });

  return (
    <SCAConfirmModal
      isOpen={isOpen}
      title={t(TRANSLATION_KEYS.approvingRefunds)}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
