import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import Logout from "../pages/Logout";

import type { Route } from "routes/RouteModel";

export const logoutRoute: Route = {
  title: COMMON_TRANSLATION_KEYS.logout,
  path: "/logout",
  hidden: true,
  component: Logout,
};
