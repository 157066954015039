import * as yup from "yup";

declare module "yup" {
  export interface StringSchema {
    validateSortCode(message?: string): StringSchema;
  }
}

export const SORT_CODE_LENGTH = 6;

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validateSortCode",
  function (message = "Please, provide valid Sort Code") {
    return this.test(
      "validate-sort-code",
      message,
      function validateSortCode(value?: string) {
        if (!value) {
          return true;
        }

        return (
          !Number.isNaN(Number(value)) && value.length === SORT_CODE_LENGTH
        );
      }
    );
  }
);
