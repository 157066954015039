import { portalApi, TAGS } from "api/rtkApi";
import { SERVICES } from "constants/environment";
import { prepareCommonFiltersForApi } from "modules/common/utils/prepareCommonFiltersForApi";
import { prepareHeadersForSCA } from "modules/common/utils/prepareHeadersForSCA";
import { preparePaginationParams } from "utils/preparePaginationParams";
import { trimObjectProperties } from "utils/trimObjectProperties";

import { ERRORS } from "../constants/Errors";
import { isAUD } from "../utils/isAUD";
import { isBeneficiaryAddressDefined } from "../utils/isBeneficiaryAddressDefined";
import { prepareFiltersForApi } from "../utils/prepareFiltersForApi";

import type { RTKMeta } from "models/RTKMeta";
import type { CreatedByParams } from "modules/common/models/CreatedBy";
import type { Headers } from "modules/common/utils/prepareHeadersForSCA";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { PayoutsFilters } from "../constants/PayoutsFilters";
import type {
  ApprovePayoutPayload,
  BeneficiarySearchType,
  CreatePayout,
  CreditorAccount,
  LegacyBeneficiary,
  NotActivePayout,
  OrderableColumns,
  Payout,
  PayoutDetails,
} from "../models";
import type { CreatePayoutForm } from "../models/CreatePayoutForm";

type GlobalFilterUser = {
  customerHierarchyId?: string;
};

type PostCreatePayoutRequest = Omit<CreatePayoutForm, "type">;

type PostCreatePayoutResponse = ObjectWithId;

type PostRejectPayoutRequest = {
  payoutId: UUID;
  id?: UUID;
};

type PostApprovePayoutRequest = ApprovePayoutPayload;

type GetPayoutsRequest = GlobalFilterUser &
  PaginationParams<OrderableColumns> &
  CreatedByParams & {
    filters?: Partial<PayoutsFilters>;
  };
type GetPayoutsResponse = {
  payouts: Payout[];
  totalItems: number;
};

type GetPayoutDetailsRequest = ObjectWithId;
type GetPayoutDetailsResponse = PayoutDetails;

type GetBeneficiariesRequest = Omit<BeneficiarySearchType, "accountName">;
type GetBeneficiariesResponse = LegacyBeneficiary[];

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayouts: builder.query<GetPayoutsResponse, GetPayoutsRequest>({
      query: ({ filters, startDate, endDate, ...rest }) => ({
        url: "portal/payouts",
        params: {
          ...preparePaginationParams(rest),
          ...prepareFiltersForApi(filters),
          ...prepareCommonFiltersForApi({ startDate, endDate }),
        },
        headers: prepareHeaders({ version: "2" }),
      }),
      providesTags: [{ type: TAGS.PAYOUTS_LIST }],
      transformResponse: (
        notActivePayouts: NotActivePayout[],
        meta: RTKMeta
      ): GetPayoutsResponse => ({
        payouts: notActivePayouts.map((payout) => ({
          ...payout,
          active: false,
        })),
        totalItems: Number(meta.response.headers.get("total-items")),
      }),
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getPayoutDetails: builder.query<
      GetPayoutDetailsResponse,
      GetPayoutDetailsRequest
    >({
      providesTags: (_, __, { id }) => [{ type: TAGS.PAYOUT_DETAILS, id }],
      query: ({ id }) => ({
        url: `portal/payouts/${id}`,
        headers: prepareHeaders({ version: "2" }),
      }),
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    approvePayout: builder.mutation<void, PostApprovePayoutRequest>({
      query: ({ payoutId, ...payload }) => ({
        url: `portal/payouts/${payoutId}/approve`,
        method: "POST",
        headers: prepareHeaders(payload),
        body: {},
      }),
      invalidatesTags: (_, __, { payoutId }) => [
        { type: TAGS.PAYOUT_DETAILS, payoutId },
        TAGS.PAYOUTS_LIST,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getBeneficiaries: builder.query<
      GetBeneficiariesResponse,
      GetBeneficiariesRequest
    >({
      providesTags: (beneficiaries) =>
        beneficiaries
          ? [
              ...beneficiaries.map((beneficiary) => ({
                type: TAGS.GET_BENEFICIARIES,
                id: beneficiary.id,
              })),
              { type: TAGS.GET_BENEFICIARIES, id: "LIST" },
            ]
          : [{ type: TAGS.GET_BENEFICIARIES, id: "LIST" }],
      query: (params) => ({
        url: "/portal/payouts/beneficiaries",
        params,
      }),
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    rejectPayout: builder.mutation<void, PostRejectPayoutRequest>({
      query: ({ payoutId }) => ({
        url: `portal/payouts/${payoutId}/reject`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: (_, __, { payoutId }) => [
        { type: TAGS.PAYOUT_DETAILS, payoutId },
        TAGS.PAYOUTS_LIST,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    postCreatePayout: builder.mutation<
      PostCreatePayoutResponse,
      PostCreatePayoutRequest
    >({
      query: (data) => ({
        url: "/portal/payouts",
        method: "POST",
        body: trimObjectProperties(formatFormDataForApi(data)),
      }),
      invalidatesTags: [TAGS.PAYOUTS_LIST],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
  }),
  overrideExisting: false,
});

function formatFormDataForApi(data: PostCreatePayoutRequest): CreatePayout {
  const {
    amount,
    currency,
    customerId,
    paymentTitle,
    street,
    city,
    country,
    buildingNumber,
    postalCode,
  } = data;

  const address = {
    street,
    city,
    country,
    buildingNumber,
    postalCode,
  };

  return {
    amount: Number(amount),
    beneficiary: {
      ...getCreditorAccountData(data),
      ...(isBeneficiaryAddressDefined(address) && {
        address,
      }),
    },
    currency: currency?.id,
    paymentTitle,
    ...(Boolean(customerId) && { customerId }),
  };
}

function getCreditorAccountData(
  data: PostCreatePayoutRequest
): CreditorAccount {
  const beneficiary = {
    ...(data.name && { name: data.name }),
    ...(data.firstName &&
      data.lastName && { firstName: data.firstName, lastName: data.lastName }),
    ...(data.email && { email: data.email }),
  };

  if (
    "taxIdentificationNumber" in data &&
    Boolean(data.taxIdentificationNumber)
  ) {
    return Object.assign(beneficiary, {
      accountNumber: data.accountNumber,
      isbp: data.isbp,
      taxIdentificationNumber: data.taxIdentificationNumber,
    });
  }

  if ("iban" in data && Boolean(data.iban)) {
    return Object.assign(beneficiary, {
      iban: data.iban,
      swiftBic: Boolean(data.swiftBic) ? data.swiftBic : null,
    });
  }

  if ("accountNumber" in data) {
    return Object.assign(beneficiary, {
      accountNumber: data.accountNumber,

      ...(data.currency && isAUD(data.currency)
        ? { bsb: data.bsb }
        : { sortCode: data.sortCode }),
    });
  }

  throw new Error(ERRORS.WRONG_PAYOUTS);
}

type HeadersData = { scaToken?: string; twoFaCode?: string; version?: string };

function prepareHeaders<T>(
  data: T extends HeadersData ? HeadersData : {} = {}
) {
  const DEFAULT_PAYOUT_API_VERSION = "1";
  const version = "version" in data ? data.version : DEFAULT_PAYOUT_API_VERSION;

  const headers: Partial<Headers> = {
    "x-volt-api-version": version,
  };

  if ("scaToken" in data) {
    return prepareHeadersForSCA(headers, data.scaToken);
  }

  return headers;
}

export const {
  useApprovePayoutMutation,
  useGetPayoutsQuery,
  useGetBeneficiariesQuery,
  useGetPayoutDetailsQuery,
  useRejectPayoutMutation,
  usePostCreatePayoutMutation,
} = extendedApi;
