export const LOCAL_STORAGE_KEYS = {
  clearingOptimisationToggleHidden: "clearing-optimisation-toggle-hidden",
  proxyLoginParametersKey: "proxy-login-parameters",
  helpHeader: "help-header",
  payoutsSCA: "payouts-sca",
  expandedPaymentRow: "expanded-payment-row",
  refundsSCA: "refunds-sca",
  requestedFeatures: "requested-features",
  useMSWMockResponses: "use-msw-mock-responses",
  useNewHierarchyTree: "use-new-hierarchy-tree",
  useOscilloscope: "use-oscilloscope",
  settlementsList: "settlements-list",
  settlementLimit: "settlement-limit",
  retriedPaymentReports: "retried-payment-reports",
  connectSettlementSettings: "connect-settlement-settings",
  tokenOnVoltLicence: "token-on-volt-licence",
  tokenOnVoltLicenceConfigured: "token-on-volt-licence-configured",
  tokenOnVoltLicenceWaitingForTokenApproval:
    "token-on-volt-licence-waiting-for-token-approval",
  refundBeneficiaryDetails: "refund-beneficiary-details",
  bankAccountsSettlementDetails: "bank-accounts-settlement-details",
} as const;

export type LocalStorageItems = {
  [LOCAL_STORAGE_KEYS.clearingOptimisationToggleHidden]: boolean;
  [LOCAL_STORAGE_KEYS.proxyLoginParametersKey]: string;
  [LOCAL_STORAGE_KEYS.helpHeader]: HelpHeader;
  [LOCAL_STORAGE_KEYS.requestedFeatures]: RequestedFeatures;
  [LOCAL_STORAGE_KEYS.useMSWMockResponses]: boolean;
  [LOCAL_STORAGE_KEYS.useNewHierarchyTree]: boolean;
  [LOCAL_STORAGE_KEYS.useOscilloscope]: boolean;
};

export type LocalStorageKeys = keyof typeof LOCAL_STORAGE_KEYS;
export type LocalStorageValues = (typeof LOCAL_STORAGE_KEYS)[LocalStorageKeys];

type HelpHeader = {
  visitedPlaces?: string[];
};

type RequestedFeatures = {
  requestedFeatures?: string[];
};
