import { currenciesMap } from "constants/currencies";

import type { Currency } from "models/Currency";
import type { CurrencyCode } from "models/CurrencyCode";

export const defaultCurrency = currenciesMap.get("EUR") as Currency;

export const currencies: Readonly<Currency[]> = Array.from(
  currenciesMap.values()
);

function isCurrencyCode(value: string): value is CurrencyCode {
  return currenciesMap.has(value as CurrencyCode);
}

export { currenciesMap, isCurrencyCode };
