import { lazyRetry } from "./lazyRetry";

import type { ComponentType, LazyExoticComponent } from "react";

// If browser don't support `requestIdleCallback` we will wait FALLBACK_IDLE_TIMEOUT ms to preload the chunk
const FALLBACK_IDLE_TIMEOUT = 1000;

export function lazyRetryWithPreload<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }> | Promise<any>,
  name: string,
  next?: (module: any) => { default: T }
): LazyExoticComponent<T> {
  preloadOnIdle(componentImport);

  return lazyRetry(componentImport, name, next);
}

function preloadOnIdle<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }> | Promise<any>
): void {
  const requestIdle =
    window.requestIdleCallback ||
    ((fn) => setTimeout(fn, FALLBACK_IDLE_TIMEOUT));

  requestIdle(
    async () =>
      // We intentionally swallow all errors. In case of failure `lazyRetry` should retry fetching and handle errors.
      await componentImport().catch(void 0)
  );
}
