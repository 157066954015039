import { addMethod, string } from "yup";

import { PIXRandomKeyPattern } from "../../patterns";

declare module "yup" {
  interface StringSchema {
    validatePIXRandomKey(message?: string): this;
  }
}

addMethod(
  string,
  "validatePIXRandomKey",
  function (message = "Random key must be in a UUID format") {
    return this.matches(PIXRandomKeyPattern, {
      message,
      name: "validatePIXRandomKey",
      excludeEmptyString: false,
    });
  }
);
