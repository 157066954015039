import { getErrorDetails } from "api/helpers/getErrorDetails";

import { axiosAuthInstance } from "./axiosAuthInstance";

import type { RequestResetPasswordEmailParams } from "../models/RequestResetPasswordEmailParams";
import type { ResetPasswordParams } from "../models/ResetPasswordParams";

export async function resetPassword(data: ResetPasswordParams) {
  try {
    await axiosAuthInstance({
      method: "POST",
      url: "/users/password/reset",
      data,
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function requestResetPasswordEmail(
  data: RequestResetPasswordEmailParams
) {
  try {
    await axiosAuthInstance({
      method: "POST",
      url: "/users/password/reset/request",
      data,
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function validateResetPasswordToken(token: string) {
  try {
    await axiosAuthInstance({
      method: "POST",
      url: "/users/password/reset/token",
      data: {
        token,
      },
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
}
