import { DateTime } from "luxon";
import * as Yup from "yup";

import { LESS_THEN_NOW } from "constants/errors";
import { DATE_FORMAT_DD_MM_YYYY } from "utils/time";

declare module "yup" {
  export interface StringSchema {
    validateDateMax(
      beforeDate?: string,
      format?: string,
      message?: string
    ): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateDateMax",
  function (
    beforeDate,
    format = DATE_FORMAT_DD_MM_YYYY,
    message = LESS_THEN_NOW
  ) {
    return this.test(
      "validate-date-max",
      message,
      function validateDateMax(value?: string) {
        if (typeof value !== "string") {
          return false;
        }

        const parsedDate = DateTime.fromFormat(value, format);
        const parsedBeforeDate = beforeDate
          ? DateTime.fromFormat(beforeDate, format)
          : DateTime.now().minus({ days: 1 });

        return parsedDate <= parsedBeforeDate;
      }
    );
  }
);
