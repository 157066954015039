/*eslint perfectionist/sort-objects: "error"*/

import type { UUID } from "utils";

export const TEST_IDS = {
  avatar: "header-avatar",
  avatarMenu: "header-avatar-menu",
  avatarMenuLogout: "header-avatar-menu-logout",
  avatarMenuSettings: "header-avatar-menu-settings",
  breadcrumbsListPlaceholder: "breadcrumbs-list-placeholder",
  comment: "comment",
  customerNotInHierarchyToast: "customer-not-in-hierarchy-toast-id",
  customerSelectionButton: "customer-selection-button",
  detailsAsideSection: "details-aside-section",
  detailsHeaderSection: "details-header-section",
  detailsMainSection: "details-main-section",
  envSwitcherLabel: "env-switcher-label",
  envSwitcherToggle: "env-switcher-toggle",
  getGlobalFilterSearchItem: (id: UUID) => `global-filter-search-item-${id}`,
  getInput: (id: string) => `${id}-input`,
  globalFilterLoader: "global-filter-loader",
  globalFilterSearch: "global-filter-search",
  globalFilterSearchInput: "global-filter-search-input",
  globalFilterSearchList: "global-filter-search-list",
  globalFilterSearchNoResults: "global-filter-search-no-results",
  globalFilterSearchSkeletonList: "global-filter-search-skeleton-list",
  headerLogo: "volt-logo",
  headerToggleSidebar: "header-toggle-sidebar-button",
  linkOrValue: "link-or-value",
  sidebarGlobalFilter: "sidebar-global-filter",
  timelineSkeleton: "timeline-skeleton",
} as const;

export const SIDEBAR_TEST_IDS = {
  ACCOUNTS: "sidebar-configuration-accounts",
  ADMINISTRATION: "sidebar-administration",
  ALLOWLIST: "sidebar-circuit-breaker-allowlist",
  APPLICATIONS: "sidebar-configuration-applications",
  BANKS: "sidebar-banks",
  BLOCKLIST: "sidebar-circuit-breaker-blocklist",
  CHECKOUT_BRANDING: "sidebar-checkout-branding",
  CIRCUIT_BREAKER: "sidebar-circuit-breaker",
  CONFIGURATION: "sidebar-configuration",
  CONNECT: "sidebar-connect",
  CUSTOMERS: "sidebar-configuration-customers",
  DASHBOARD: "sidebar-dashboard",
  PAYMENTS: "sidebar-payments",
  PAYOUTS: "sidebar-payouts",
  REFUNDS: "sidebar-refunds",
  RISK_RULES: "sidebar-circuit-breaker-risk-rules",
  SETTLEMENTS: "settlements",
  TRANSACTIONS: "sidebar-transactions",
  USERS: "sidebar-configuration-users",
  WORKFLOWS: "sidebar-workflows",
} as const;

export const NOTIFICATIONS_TEST_IDS = {
  queued: "notifications-queued",
  queuedItem: "notifications-queued-item",
  sendTest: "send-test-notification",
} as const;

export const CARDS_TEST_IDS = {
  accountNumberItem: "account-number-card-item",
  amountNumberItem: "amount-card-item",
  bankNameItem: "bank-name-card-item",
  bankNicknameItem: "bank-nickname-card-item",
  bsbItem: "bsb-card-item",
  countryItem: "country-card-item",
  currencyItem: "currency-card-item",
  customerItem: "customer-card-item",
  ibanItem: "iban-card-item",
  pixItem: "pix-card-item",
  routingNumberItem: "routing-number-card-item",
  sortCode: "sort-code-card-item",
  swiftBicItem: "swift-bic-card-item",
} as const;

export const FILTERS_TEST_IDS = {
  actions: "filters-actions",
  applyAction: "filters-apply-action",
  button: "filters-button",
  cancelAction: "filters-cancel-action",
  content: "filters-content",
  getCheckbox: (id: string) => `${id}-checkbox`,
  getInput: (id: string) => `${id}-tag-select-input-list`,
  getQuickFilter: (filter: string) => `${filter}-quick-filter`,
  modal: "filters-modal",
  number: "filters-number",
  quickFilters: "quick-filters",
  resetAction: "filters-reset-action",
  selectAllButton: "filters-select-all-button",
  statusFilter: "filters-status-filter",
};

export const CAROUSEL_TEST_IDS = {
  closePreviewButton: (prefix: string) => `${prefix}-close-preview-button`,
  nextSlideButton: (prefix: string) => `${prefix}-next-slide-button`,
  previewImage: (prefix: string) => `${prefix}-preview-image`,
  previousSlideButton: (prefix: string) => `${prefix}-previous-slide-button`,

  showSlidePreviewButton: (prefix: string) => `${prefix}-slide-image-preview`,

  slideImage: (prefix: string, slideNumber: number) =>
    `${prefix}-slide-image-${slideNumber}`,
  slideMainButton: (prefix: string) => `${prefix}-slide-main-button`,
};
