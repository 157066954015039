import * as Yup from "yup";

import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import { getLastTwoMonthsDateRange } from "../utils/getLastTwoMonthsDateRange";
import { TRANSLATION_KEYS } from "./TranslationKeys";

const { start, end } = getLastTwoMonthsDateRange();

export const FILTERS_KEYS = {
  AMOUNT_MIN: "amountMin",
  AMOUNT_MAX: "amountMax",
  CURRENCY: "currency",
  COUNTRIES: "country",
  END_DATE: "endDate",
  LICENCE: "licence",
  START_DATE: "startDate",
  STATUS: "status",
  NO_BANK_CHOSEN: "noBankChosen",
} as const;

export const FILTERS_SCHEMA = Yup.object().shape({
  amountMin: Yup.number().nullable(),
  amountMax: Yup.number()
    .nullable()
    .when("amountMin", ([amountMin], schema) => {
      if (Boolean(amountMin)) {
        return schema
          .nonNullable(TRANSLATION_KEYS.thisValueShouldBeGreaterThan)
          .min(amountMin, TRANSLATION_KEYS.thisValueShouldBeGreaterThan);
      }

      return schema;
    }),
});

export const DEFAULT_FILTERS = {
  [FILTERS_KEYS.LICENCE]: [],
  [FILTERS_KEYS.STATUS]: [],
  [FILTERS_KEYS.CURRENCY]: [],
  [FILTERS_KEYS.COUNTRIES]: [],
  [FILTERS_KEYS.AMOUNT_MIN]: null,
  [FILTERS_KEYS.AMOUNT_MAX]: null,
  [FILTERS_KEYS.START_DATE]: null,
  [FILTERS_KEYS.END_DATE]: null,
  [FILTERS_KEYS.NO_BANK_CHOSEN]: false,
};

export const INITIAL_FILTERS = {
  [FILTERS_KEYS.LICENCE]: [],
  [FILTERS_KEYS.STATUS]: [],
  [FILTERS_KEYS.CURRENCY]: [],
  [FILTERS_KEYS.COUNTRIES]: [],
  [FILTERS_KEYS.AMOUNT_MIN]: null,
  [FILTERS_KEYS.AMOUNT_MAX]: null,
  [FILTERS_KEYS.START_DATE]: start.toISODate(),
  [FILTERS_KEYS.END_DATE]: end.toISODate(),
  [FILTERS_KEYS.NO_BANK_CHOSEN]: false,
};

export const DEFAULT_FILTERS_CONFIGURATION = {
  [FILTERS_KEYS.LICENCE]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.STATUS]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.CURRENCY]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.COUNTRIES]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.START_DATE]: {
    array: undefined,
    defaultValue: null,
  },
  [FILTERS_KEYS.END_DATE]: {
    array: undefined,
    defaultValue: null,
  },
  [FILTERS_KEYS.NO_BANK_CHOSEN]: {
    array: undefined,
    defaultValue: false,
  },
  [FILTERS_KEYS.AMOUNT_MIN]: {
    array: undefined,
    defaultValue: null,
  },
  [FILTERS_KEYS.AMOUNT_MAX]: {
    array: undefined,
    defaultValue: null,
  },
};

export const INITIAL_FILTERS_CONFIGURATION = Object.assign(
  {},
  DEFAULT_FILTERS_CONFIGURATION,
  {
    [FILTERS_KEYS.START_DATE]: {
      array: undefined,
      defaultValue: start.toISODate(),
    },
    [FILTERS_KEYS.END_DATE]: {
      array: undefined,
      defaultValue: end.toISODate(),
    },
  }
);

export const FILTERS_PERSISTED_KEY = getPersistedFilterKey("new.payments");
