import { put, takeLatest } from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import { setError, setSuccess, startLoading } from "store/appSlice";

import * as PaymentResultsApi from "../../api/PaymentResults";
import { BLOCK_RULE_TYPE_BANK } from "../../constants/Rules";
import {
  fetchRiskOverview,
  riskOverviewOperationPaths,
  setRiskOverviewBank,
  setRiskOverviewData,
} from "./riskOverview.slice";

import type { Bank } from "modules/banks/models/banks";

import type { RiskOverviewResponse } from "../../models/RiskOverview";

function* fetchRiskOverviewSaga({
  payload: { id },
}: ReturnType<typeof fetchRiskOverview>) {
  const { details } = riskOverviewOperationPaths;

  try {
    yield put(startLoading(details));
    const data: RiskOverviewResponse = yield callApi(
      PaymentResultsApi.getResultForPayments,
      id
    );

    yield put(setRiskOverviewData(data));

    if ("blockList" in data) {
      const bankRuleResult = data.blockList.ruleResults.find(
        (ruleResult) => ruleResult.type === BLOCK_RULE_TYPE_BANK
      );

      if (bankRuleResult) {
        try {
          const bankData: Bank = yield callApi(() => {}, bankRuleResult.value);

          if ("id" in bankData) {
            yield put(setRiskOverviewBank(bankData));
          }
        } catch (bankError) {
          console.error(bankError);
          // ignore error
        }
      }
    }

    yield put(setSuccess(details));
  } catch (error) {
    console.error(error);

    yield put(
      setError({
        path: details,
        error,
      })
    );
  }
}

export function* riskOverviewSaga() {
  yield takeLatest(fetchRiskOverview.type, fetchRiskOverviewSaga);
}
