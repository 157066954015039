export const USERS_URL = "/configuration/users";

export const MAX_USER_NAME_LENGTH = 100;

export * from "./DefaultPaginationConfiguration";
export * from "./PagesUrls";
export * from "./TestIds";
export * from "./TranslationKeys";
export * from "./User";
export * from "./UserToasts";
