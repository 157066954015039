import { ClickAwayListener, styled } from "@mui/material";
import {
  Avatar as AvatarComponent,
  ListBox,
  ListItem,
} from "@volt_developers/components";
import { Link as RouterLink } from "react-router-dom";

import { shouldForwardPropHelper } from "utils/shouldForwardPropHelper";

import { TEST_IDS } from "../../constants/TestIds";
import { useAvatar } from "./useAvatar";

const AVATAR_MENU_WIDTH = 250;

export function Avatar() {
  const {
    menuConfig,
    isActive,
    toggleActive,
    deactivate,
    getLabel,
    isLoading,
  } = useAvatar();

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={deactivate}>
        <div data-testid={TEST_IDS.avatar} onClick={toggleActive}>
          <AvatarComponent.Wrapper isLoading={isLoading}>
            <AvatarComponent.Text
              label={getLabel()}
              sx={{ lineHeight: "24px" }}
            />
          </AvatarComponent.Wrapper>
        </div>
      </ClickAwayListener>
      <Menu data-testid={TEST_IDS.avatarMenu} isActive={isActive}>
        {menuConfig.map((item) => (
          <ListItem key={item["data-testid"]}>
            <Link {...item}>{item.label}</Link>
          </ListItem>
        ))}
      </Menu>
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  position: "relative",
});

interface MenuProps {
  isActive: boolean;
}

const MENU_PROPS = new Set(["isActive"]);

const Menu = styled(ListBox, {
  shouldForwardProp: (prop) => shouldForwardPropHelper(prop, MENU_PROPS),
})<MenuProps>(({ isActive }) => ({
  display: isActive ? "block" : "none",
  right: 0,
  width: AVATAR_MENU_WIDTH,
  top: 32,
  position: "absolute",

  "> li": {
    height: "auto",
    padding: 0,
  },
}));

const Link = styled(RouterLink)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  width: "100%",
  color: theme.palette.text.high,
  lineHeight: "20px",
  padding: theme.spacing(1, 0.5, 1, 1.25),
}));
