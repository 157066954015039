import { useSomethingWentWrongToast } from "hooks/useSomethingWentWrongToast";

import { useTranslatedToasts } from "../../../hooks/toasts/useTranslatedToasts";
import { useApproveRefundMutation } from "../api/Refunds";
import { REFUNDS_TOASTS } from "../constants/Toasts";

import type { UUID } from "utils";

export function useApproveRefund() {
  const [approveRefund, { isError, isLoading }] = useApproveRefundMutation();
  const { showApproveSuccessToast } = useTranslatedToasts(
    REFUNDS_TOASTS,
    "refunds"
  );

  useSomethingWentWrongToast(isError);

  const handleApproveRefund = async (refundId: UUID, scaToken?: string) => {
    await approveRefund({ id: refundId, scaToken }).unwrap();
    showApproveSuccessToast();
  };

  return {
    approveRefund: handleApproveRefund,
    isApproveRefundLoading: isLoading,
  };
}
