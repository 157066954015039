import { call, fork, put, select, take, takeLatest } from "redux-saga/effects";

import { portalApi } from "api/rtkApi";
import {
  ENV_PRODUCTION,
  ENV_SANDBOX,
} from "constants/environment";
import { history } from "store";
import {
  selectEnvironment,
  setEnvironment,
  setGlobalFilteredCustomer,
  toggleEnvironment,
} from "store/appSlice";
import { getClosestParamlessParent } from "utils/getClosestParamlessParent";

import type { PayloadAction } from "@reduxjs/toolkit";

import type {
  APPLICATION_ENV} from "constants/environment";

function* toggleEnvironmentSaga({
  payload,
}: PayloadAction<{ allRoutes: string[] }>) {
  const currentEnvironment: APPLICATION_ENV = yield select(selectEnvironment);

  const newEnvironment =
    currentEnvironment === ENV_PRODUCTION ? ENV_SANDBOX : ENV_PRODUCTION;

  yield put(setEnvironment(newEnvironment));
  yield call(returnToNearestParentUrl, payload.allRoutes);
  yield put(portalApi.util.resetApiState());
}

function returnToNearestParentUrl(allRoutes: string[]) {
  const longestMatchedUrl = getClosestParamlessParent({
    history,
    routes: allRoutes,
  });

  history.push({
    pathname: longestMatchedUrl.path,
    search: history.location.search,
  });
}

function* watchToggleEnvironment() {
  yield takeLatest(toggleEnvironment.type, toggleEnvironmentSaga);
}

function* watchSetGlobalFilteredCustomer() {
  yield take(setGlobalFilteredCustomer.type);
}

export function* rootSaga() {
  yield fork(watchToggleEnvironment);
  yield fork(watchSetGlobalFilteredCustomer);
}
