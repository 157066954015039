const generatePseudoRandomKey = () =>
  Math.random().toString(36).substring(2, 11);

/**
 * @description Generates unique key based on list of existing keys
 * @deprecated Do not use it in the new code base.
 * @todo Used only for risk rules draft - remove once flow has been changed
 */
export const generateUniqueKey = (list: string[]) => {
  let newKey: string;

  do {
    newKey = generatePseudoRandomKey();
  } while (list.includes(newKey));

  return newKey;
};
