import { REPORT_STATUS } from "../constants/Reports";

import type { Report, ReportStatus } from "../models/Reports";

const GENERATING_STATUSES: ReportStatus[] = [
  REPORT_STATUS.IN_PROGRESS,
  REPORT_STATUS.REQUESTED,
];

export function isReportGenerating(report: Pick<Report, "status">) {
  return GENERATING_STATUSES.includes(report.status);
}
