import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { LocalStorage } from "utils/LocalStorage";

const environment = import.meta.env.FUZEBOX_ENV || "local";

export function initializeFeatureFlags(): void {
  LocalStorage.saveItem(
    LOCAL_STORAGE_KEYS.clearingOptimisationToggleHidden,
    environment === "prod"
  );
  LocalStorage.saveItem(
    LOCAL_STORAGE_KEYS.useMSWMockResponses,
    LocalStorage.getItem(LOCAL_STORAGE_KEYS.useMSWMockResponses, false)
  );
}
