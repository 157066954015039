import type { PERMISSION_GROUP_LIST_ALL } from "modules/auth/constants/permissionGroups";
import type { Customer } from "modules/customers/models/Customer";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

export interface UserMetadata {
  termsAcceptanceDate?: string;
}

export interface User extends ObjectWithId {
  active: boolean;
  email: string;
  locked: boolean;
  name: string;
  permissions: PERMISSION_GROUP_LIST_ALL[];
  scaAuthentication: UserScaAuthentication;
  voltEmployee: boolean;
  customer?: Pick<Customer, "active" | "discr" | "id" | "name">;
}

export interface UserScaAuthentication {
  active: boolean;
  subjectId: UUID | null;
}

export interface CurrentUser extends User {
  lastSuccessfulLogin: string | null;
  metadata: UserMetadata;
}

export interface UserForm {
  email: string;
  name: string;
  permissions: PERMISSION_GROUP_LIST_ALL[];
  customer?: UUID | null;
  id?: UUID;
  newPassword?: string;
}

export interface UserPatch extends UserForm {
  active?: boolean;
  locked?: false;
}

export type BasicUser = ObjectWithId & {
  customerId: UUID;
  name: string;
};

export const PROPERTY_ACTIVE = "active";
export const PROPERTY_VOLT_ADMIN = "voltAdmin";

export type UserNames = Record<UUID, string | undefined>;
