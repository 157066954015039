import { styled } from "@mui/material";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { createGlobalStyle } from "styled-components";

import { Header, HEADER_HEIGHT } from "./Header";

import type { Theme } from "@mui/material";
import type { ReactNode } from "react";

export interface BasicLayoutProps {
  children?: ReactNode;
}

export function BasicLayout({ children }: BasicLayoutProps) {
  return (
    <Root>
      <GlobalStyle />
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.FUZEBOX_RECAPTCHA_KEY ?? ""}
      >
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </GoogleReCaptchaProvider>
    </Root>
  );
}

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  html,
  body,
  #root {
    height: 100%;
  }

  *, ::after, ::before {
    box-sizing: inherit;
  }

  body {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

const Root = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    gap: "24px",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "520px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  margin: "0 auto",
  marginBottom: HEADER_HEIGHT["regular"],

  "@media (max-height: 675px)": {
    margin: "24px auto",
    flexGrow: 0,
  },

  [theme.breakpoints.down("md")]: {
    margin: "0 auto",
    flexGrow: 0,
  },
}));
