import { createAction, createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store";

import type {
  LicenceAgreementFile,
  LicenceAgreementLastUpdate,
} from "../models/TermsAndConditions";

interface InitialState {
  code: string | null;
  licenceAgreement: {
    file: LicenceAgreementFile | null;
    lastUpdate: LicenceAgreementLastUpdate | null;
  };
}

const initialState: InitialState = {
  code: null,
  licenceAgreement: {
    lastUpdate: null,
    file: null,
  },
};

export const operationPath = {
  licenceAgreementLastUpdate: [
    "userSettings",
    "licenceAgreement",
    "lastUpdate",
  ],
  licenceAgreementFile: ["userSettings", "licenceAgreement", "file"],
  acceptLicenceAgreement: ["userSettings", "licenceAgreement", "accept"],
};

export const slice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setLicenceAgreementLastUpdate: (
      state,
      action: PayloadAction<LicenceAgreementLastUpdate>
    ) => {
      state.licenceAgreement.lastUpdate = action.payload;
    },
    setLicenceAgreementFile: (
      state,
      action: PayloadAction<LicenceAgreementFile>
    ) => {
      state.licenceAgreement.file = action.payload;
    },
  },
});

export const { setLicenceAgreementLastUpdate, setLicenceAgreementFile } =
  slice.actions;

export const fetchLicenceAgreementLastUpdate = createAction(
  "fetchLicenceAgreementLastUpdate"
);

export const fetchLicenceAgreementFile = createAction<{ version: string }>(
  "fetchLicenceAgreementFile"
);

export const acceptLicenceAgreement = createAction("acceptLicenceAgreement");

export const userSettingsReducer = slice.reducer;

export const selectLicenceAgreement = (state: RootState) =>
  state.userSettings.licenceAgreement;
