import axios from "axios";

import { createFilterParams } from "api/helpers/createFilterParams";
import { getErrorDetails } from "api/helpers/getErrorDetails";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";

import { DOWNLOAD_REPORT_TYPES, LIMIT_OF_PAYMENTS } from "../constants/Reports";

import type { AxiosRequestConfig } from "axios";

import type { ObjectWithId } from "utils/ObjectWithId";

import type { Payment, PaymentsQueryParams } from "../models/Payment";
import type {
  ApiGenerateReport,
  ApiReport,
  DownloadedReport,
} from "../models/Reports";

export type PostGenerateReport = (
  queryParams: PaymentsQueryParams & { orderBy: keyof Payment | undefined },
  options?: AxiosRequestConfig
) => Promise<ApiGenerateReport>;

export type GetReports = (options?: AxiosRequestConfig) => Promise<ApiReport[]>;

export type PostDownloadReport = (
  { id }: ObjectWithId,
  options?: AxiosRequestConfig
) => Promise<DownloadedReport>;

export type DeleteReport = (
  { id }: ObjectWithId,
  options?: AxiosRequestConfig
) => Promise<void>;

export const postGenerateReport: PostGenerateReport = async (
  queryParams,
  options
) => {
  const params = createFilterParams(queryParams, { pagination: false });

  // TODO: limit is temporary and should be removed before deploy
  params.limit = LIMIT_OF_PAYMENTS;

  try {
    const response = await axios({
      ...options,
      method: "POST",
      url: "/payments/reports",
      params,
      paramsSerializer,
    });

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export const getReports: GetReports = async (options) => {
  try {
    const response = await axios({
      ...options,
      method: "GET",
      url: "/payments/reports",
      params: {
        status: ["REQUESTED", "IN_PROGRESS", "FINISHED"],
        ...DEFAULT_PAGINATION,
      },
    });

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export const postDownloadReport: PostDownloadReport = async (
  { id },
  options
) => {
  try {
    const response = await axios({
      ...options,
      method: "POST",
      url: `/payments/reports/${id}/download`,
    });

    return response.headers["content-type"] === "application/json"
      ? { data: response.data.url, type: DOWNLOAD_REPORT_TYPES.LINK }
      : { data: response.data, type: DOWNLOAD_REPORT_TYPES.CSV };
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export const deleteReport: DeleteReport = async ({ id }, options) => {
  try {
    await axios({
      ...options,
      method: "DELETE",
      url: `/payments/reports/${id}`,
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
};
