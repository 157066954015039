import { ButtonDeprecated } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { TEST_IDS } from "../../../constants/TestIds";
import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";
import { RejectRefundConfirmModal } from "../../RejectRefundConfirmModal";
import { useRejectButton } from "./useRejectButton";

export function RejectButton() {
  const { t } = useTranslation("refunds");

  const { isVisible, openModal, isModalOpen, closeModal } = useRejectButton();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <ButtonDeprecated
        color="danger"
        data-testid={TEST_IDS.refundRejectButton}
        onClick={openModal}
      >
        {t(TRANSLATION_KEYS.reject)}
      </ButtonDeprecated>
      <RejectRefundConfirmModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}
