import { styled } from "@mui/material";
import {
  ChevronRightIcon,
  Sidebar as VoltSidebar,
} from "@volt_developers/components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LogoIcon } from "assets/images/logo-sidebar.svg";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { useGlobalFilter } from "hooks/useGlobalFilter";

import {
  SIDEBAR_WIDTH,
  ToggleGlobalFilterSearchStatusEvent,
} from "../../constants/Sidebar";
import { TEST_IDS } from "../../constants/TestIds";
import { Z_INDEX } from "../../utils/zIndex";
import { GlobalFilterLabel } from "./GlobalFilter/GlobalFilterLabel";
import { GlobalFilterSearch } from "./GlobalFilter/GlobalFilterSearch";
import { useGlobalFilterSearch } from "./GlobalFilter/useGlobalFilterSearch";
import { useSidebarConfig } from "./sidebarConfig";
import { SidebarItem } from "./SidebarItem";

interface SidebarProps {
  isAnimationInProgress?: boolean;
  isOpen?: boolean;
}

export function Sidebar({ isOpen, isAnimationInProgress }: SidebarProps) {
  const { sidebarConfig } = useSidebarConfig();
  const { t } = useTranslation("common");
  const [isGlobalFilterSearchOpen, setIsGlobalFilterSearchOpen] =
    useState(false);
  const {
    handleCustomerClear,
    isGlobalFiltering,
    isLoading,
    globallyFilteredCustomer,
  } = useGlobalFilter();

  const {
    isCustomerLoading,
    searchResults,
    isLoading: isGlobalFilterSearchLoading,
    isNoResults,
    hasMore,
    onSearch,
    onSelectCustomer,
    handleLoadMore,
    handleFavouriteClick,
    reset,
  } = useGlobalFilterSearch();

  function handleOpenGlobalFilterSearch() {
    reset();
    setIsGlobalFilterSearchOpen(true);
  }

  function toggleCustomerSelectionMenu() {
    setIsGlobalFilterSearchOpen((prev) => !prev);
  }

  useEffect(() => {
    window.addEventListener(
      ToggleGlobalFilterSearchStatusEvent,
      toggleCustomerSelectionMenu
    );

    return () => {
      window.removeEventListener(
        ToggleGlobalFilterSearchStatusEvent,
        toggleCustomerSelectionMenu
      );
    };
  });

  return (
    <VoltSidebar.Wrapper
      sx={{
        width: SIDEBAR_WIDTH,
        gridTemplateRows: "0fr auto",
        zIndex:
          isOpen && !isAnimationInProgress
            ? Z_INDEX.SIDEBAR_VISIBLE
            : Z_INDEX.SIDEBAR_HIDDEN,
        visibility: !isOpen && !isAnimationInProgress ? "hidden" : "visible",
      }}
    >
      <VoltSidebar.Header sx={{ width: SIDEBAR_WIDTH, height: "auto" }}>
        <VoltSidebar.SelectCustomer
          data-testid={TEST_IDS.sidebarGlobalFilter}
          expandIcon={<ChevronRightIcon />}
          isActive={isGlobalFiltering}
          label={t(COMMON_TRANSLATION_KEYS.viewing)}
          customerLabel={
            <GlobalFilterLabel
              customerName={globallyFilteredCustomer?.name}
              isLoading={isLoading || isCustomerLoading}
            />
          }
          onClick={handleOpenGlobalFilterSearch}
          onRemoveClick={handleCustomerClear}
        />
      </VoltSidebar.Header>
      <VoltSidebar.Menu>
        {sidebarConfig.map((sidebarItem) => (
          <SidebarItem {...sidebarItem} key={sidebarItem.id} />
        ))}
      </VoltSidebar.Menu>
      <VoltSidebar.Footer>
        <LogoWrapper>
          <LogoIcon height={56} width={56} />
        </LogoWrapper>
      </VoltSidebar.Footer>
      {isGlobalFilterSearchOpen && (
        <GlobalFilterSearch
          hasMore={hasMore}
          isLoading={isGlobalFilterSearchLoading}
          isNoResults={isNoResults}
          searchResults={searchResults}
          handleLoadMore={handleLoadMore}
          onCloseFilterSearch={setIsGlobalFilterSearchOpen}
          onFavouriteClick={handleFavouriteClick}
          onSearch={onSearch}
          onSelectCustomer={onSelectCustomer}
        />
      )}
    </VoltSidebar.Wrapper>
  );
}

const LogoWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.text.dlow,
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(1.75, 0),
}));
