import { addMethod, string } from "yup";

import { removeNonDigitsCharacters as utilRemoveNonDigits } from "../../removeNonDigitsCharacters";

declare module "yup" {
  export interface StringSchema {
    removeNonDigitsCharacters(): this;
  }
}

addMethod(
  string,
  "removeNonDigitsCharacters",
  function removeNonDigitsCharacters() {
    return this.transform(utilRemoveNonDigits);
  }
);
