import type { StatusDotColor } from "components/StatusDot";

import type { PaymentStatus } from "../models/PaymentStatus";

export const STATUS_NEW_PAYMENT = "NEW_PAYMENT";

// Initialize authorisation
export const STATUS_BANK_REDIRECT = "BANK_REDIRECT";
export const STATUS_APPROVED_BY_RISK = "APPROVED_BY_RISK";
export const STATUS_ADDITIONAL_AUTHORIZATION_REQUIRED =
  "ADDITIONAL_AUTHORIZATION_REQUIRED";
export const STATUS_AWAITING_CHECKOUT_AUTHORISATION =
  "AWAITING_CHECKOUT_AUTHORISATION";

// Multiple Accounts consent
export const STATUS_ACCOUNT_SELECTION = "ACCOUNT_SELECTION";

// Canceled
export const STATUS_CANCELLED_BY_USER = "CANCELLED_BY_USER";
export const STATUS_FAILED = "FAILED";

// Failed
export const STATUS_REFUSED_BY_RISK = "REFUSED_BY_RISK";
export const STATUS_REFUSED_BY_BANK = "REFUSED_BY_BANK";
export const STATUS_ERROR_AT_BANK = "ERROR_AT_BANK";
export const STATUS_ABANDONED_BY_USER = "ABANDONED_BY_USER";

// Authorized
export const STATUS_AUTHORISED_BY_USER = "AUTHORISED_BY_USER";

// Finished
export const STATUS_COMPLETED = "COMPLETED";
export const STATUS_DELAYED_AT_BANK = "DELAYED_AT_BANK";

// Cash Management
export const STATUS_RECEIVED = "RECEIVED";
export const STATUS_SETTLED = "SETTLED";
export const STATUS_NOT_RECEIVED = "NOT_RECEIVED";

export const STATUS_UNKNOWN = "UNKNOWN";

export const STATUS_GROUP_REQUESTED = "GROUP_REQUESTED";
export const STATUS_GROUP_INITIATED = "GROUP_INITIATED";
export const STATUS_GROUP_PENDING = "GROUP_PENDING";
export const STATUS_GROUP_FAILED = "GROUP_FAILED";
export const STATUS_GROUP_OTHER = "GROUP_OTHER";
/**
 * @deprecated use STATUS_GROUP_COMPLETED instead.
 */
export const STATUS_GROUP_CASH_MANAGEMENT = "GROUP_CASH_MANAGEMENT";
export const STATUS_GROUP_COMPLETED = "GROUP_COMPLETED";

export const STATUS_GROUPS_ALL = [
  STATUS_GROUP_REQUESTED,
  STATUS_GROUP_INITIATED,
  STATUS_GROUP_PENDING,
  STATUS_GROUP_FAILED,
  STATUS_GROUP_OTHER,
  STATUS_GROUP_CASH_MANAGEMENT,
  STATUS_GROUP_COMPLETED,
] as const;

export const STATUS_ALL = [
  // from STATUS_GROUP_REQUESTED
  STATUS_NEW_PAYMENT,
  // from STATUS_GROUP_INITIATED
  STATUS_COMPLETED,
  // from STATUS_GROUP_CASH_MANAGEMENT
  STATUS_SETTLED,
  STATUS_RECEIVED,
  STATUS_NOT_RECEIVED,
  // from STATUS_GROUP_PENDING
  STATUS_BANK_REDIRECT,
  STATUS_APPROVED_BY_RISK,
  STATUS_ADDITIONAL_AUTHORIZATION_REQUIRED,
  STATUS_AUTHORISED_BY_USER,
  STATUS_DELAYED_AT_BANK,
  STATUS_AWAITING_CHECKOUT_AUTHORISATION,
  STATUS_ACCOUNT_SELECTION,
  // from STATUS_GROUP_FAILED
  STATUS_REFUSED_BY_RISK,
  STATUS_CANCELLED_BY_USER,
  STATUS_FAILED,
  STATUS_REFUSED_BY_BANK,
  STATUS_ERROR_AT_BANK,
  STATUS_ABANDONED_BY_USER,
  // from STATUS_GROUP_OTHER
  STATUS_UNKNOWN,
] as const;

export const STATUS_GROUPS: {
  [K in (typeof STATUS_GROUPS_ALL)[number]]?: (typeof STATUS_ALL)[number][];
} = {
  [STATUS_GROUP_COMPLETED]: [STATUS_SETTLED, STATUS_RECEIVED, STATUS_COMPLETED],
  [STATUS_GROUP_PENDING]: [
    STATUS_DELAYED_AT_BANK,
    STATUS_BANK_REDIRECT,
    STATUS_APPROVED_BY_RISK,
    STATUS_ADDITIONAL_AUTHORIZATION_REQUIRED,
    STATUS_AUTHORISED_BY_USER,
    STATUS_AWAITING_CHECKOUT_AUTHORISATION,
    STATUS_ACCOUNT_SELECTION,
  ],
  [STATUS_GROUP_FAILED]: [
    STATUS_REFUSED_BY_RISK,
    STATUS_REFUSED_BY_BANK,
    STATUS_ERROR_AT_BANK,
    STATUS_CANCELLED_BY_USER,
    STATUS_ABANDONED_BY_USER,
    STATUS_FAILED,
    STATUS_NOT_RECEIVED,
  ],
};

export const STATUSES_TREE: StatusTreeItem[] = [
  {
    id: STATUS_NEW_PAYMENT,
  },
  {
    id: STATUS_GROUP_COMPLETED,
    children: [
      { id: STATUS_COMPLETED },
      { id: STATUS_SETTLED },
      { id: STATUS_RECEIVED },
    ],
  },
  {
    id: STATUS_GROUP_FAILED,
    children: [
      { id: STATUS_REFUSED_BY_RISK },
      { id: STATUS_REFUSED_BY_BANK },
      { id: STATUS_ERROR_AT_BANK },
      { id: STATUS_CANCELLED_BY_USER },
      { id: STATUS_ABANDONED_BY_USER },
      { id: STATUS_FAILED },
      { id: STATUS_NOT_RECEIVED },
    ],
  },
  {
    id: STATUS_GROUP_PENDING,
    children: [
      { id: STATUS_DELAYED_AT_BANK },
      { id: STATUS_BANK_REDIRECT },
      { id: STATUS_APPROVED_BY_RISK },
      { id: STATUS_ADDITIONAL_AUTHORIZATION_REQUIRED },
      { id: STATUS_AUTHORISED_BY_USER },
      { id: STATUS_AWAITING_CHECKOUT_AUTHORISATION },
      { id: STATUS_ACCOUNT_SELECTION },
    ],
  },
  {
    id: STATUS_UNKNOWN,
  },
];

export type StatusTreeItem = {
  id: (typeof STATUS_ALL)[number] | (typeof STATUS_GROUPS_ALL)[number];
  children?: StatusTreeItem[];
  expanded?: boolean;
};

type StatusConfig = {
  color: "default" | "error" | "success" | "warning";
};

export const statusConfigs: Readonly<{
  [K in "DEFAULT" | PaymentStatus]: StatusConfig;
}> = {
  DEFAULT: { color: "default" },

  [STATUS_NEW_PAYMENT]: { color: "default" },

  [STATUS_COMPLETED]: { color: "success" },

  [STATUS_SETTLED]: { color: "success" },
  [STATUS_NOT_RECEIVED]: { color: "error" },
  [STATUS_RECEIVED]: { color: "success" },

  [STATUS_APPROVED_BY_RISK]: { color: "success" },
  [STATUS_ADDITIONAL_AUTHORIZATION_REQUIRED]: { color: "error" },
  [STATUS_BANK_REDIRECT]: { color: "warning" },
  [STATUS_AUTHORISED_BY_USER]: { color: "default" },
  [STATUS_DELAYED_AT_BANK]: { color: "warning" },
  [STATUS_AWAITING_CHECKOUT_AUTHORISATION]: { color: "warning" },
  [STATUS_ACCOUNT_SELECTION]: { color: "warning" },

  [STATUS_REFUSED_BY_RISK]: { color: "error" },
  [STATUS_CANCELLED_BY_USER]: { color: "error" },
  [STATUS_FAILED]: { color: "error" },
  [STATUS_REFUSED_BY_BANK]: { color: "error" },
  [STATUS_ERROR_AT_BANK]: { color: "error" },
  [STATUS_ABANDONED_BY_USER]: { color: "error" },

  [STATUS_UNKNOWN]: { color: "error" },
} as const;

export const MAP_STATUS_COLOR_TO_DOT_COLOR: Record<
  StatusConfig["color"],
  StatusDotColor
> = {
  success: "success",
  error: "failure",
  warning: "warning",
  default: "low",
};

export function isStatusGroupsAllKey(
  status: string
): status is (typeof STATUS_GROUPS_ALL)[number] {
  return STATUS_GROUPS_ALL.includes(
    status as (typeof STATUS_GROUPS_ALL)[number]
  );
}

export function isStatusAllKey(
  status: string
): status is (typeof STATUS_ALL)[number] {
  return STATUS_ALL.includes(status as (typeof STATUS_ALL)[number]);
}
