import { blue, green, grey } from "@material-ui/core/colors";

import type { ThemeOptions } from "@material-ui/core/styles";
import type { PaletteOptions } from "@material-ui/core/styles/createPalette";

export interface Variant {
  body: ThemeOptions["body"];
  header: ThemeOptions["header"];
  name: string;
  palette: Pick<PaletteOptions, "primary" | "secondary">;
  sidebar: ThemeOptions["sidebar"];
}

export const darkVariant: Variant = {
  name: "Dark",
  palette: {
    primary: {
      main: blue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: blue[500],
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: "#1B2430",
    header: {
      color: grey[200],
      background: "#232f3e",
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#232f3e",
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: "#FFF",
      background: blue[500],
    },
  },
  body: {
    background: "#f7f7f7",
  },
};
