import type { Customer } from "modules/customers/models/Customer";
import type { ObjectWithId } from "utils/ObjectWithId";

export type AisRedirectUrls = {
  cancelUrl: string;
  failureUrl: string;
  successUrl: string;
};

export type Client = ObjectWithId & {
  active: boolean;
  name: string;
  paymentCancelUrl: string | null;
  paymentFailureUrl: string | null;
  paymentPendingUrl: string | null;
  paymentSuccessUrl: string | null;
  secret: string;
  signingKey: string;
  aisRedirectUrls?: AisRedirectUrls;
  customer?: Pick<Customer, "active" | "id" | "name">;
};

export const PROPERTY_ACTIVE = "active";
export const PROPERTY_REDIRECT_SIGNATURE = "redirectSignature";
export const PROPERTY_SECRET = "secret";
