import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { showDoubleButtonToast } from "components/toasts/DoubleButtonToast";
import { showMessageToast } from "components/toasts/MessageToast";
import { showSingleButtonToast } from "components/toasts/SingleButtonToast";
import { capitalizeString } from "utils/capitalizeString";

import type { TFunction } from "i18next";
import type { ReactNode } from "react";

import type { ToastProps as DoubleButtonProps } from "components/toasts/DoubleButtonToast";
import type { ToastProps } from "components/toasts/MessageToast";
import type { ToastProps as SingleButtonProps } from "components/toasts/SingleButtonToast";

type NoButtonToast = ToastProps & { type?: "no-button" };
type OneButtonToast = SingleButtonProps & { type: "one-button" };
type TwoButtonToast = DoubleButtonProps & { type: "two-button" };

type OneButtonToastWithoutActions = Omit<OneButtonToast, "handleClick">;
type TwoButtonToastWithoutActions = Omit<
  TwoButtonToast,
  "handleClickPrimary" | "handleClickSecondary"
>;

type Toast =
  | NoButtonToast
  | OneButtonToastWithoutActions
  | TwoButtonToastWithoutActions;
type ToastFunction = (message: string) => ToastProps;

type Toasts = Readonly<Record<string, Toast | ToastFunction>>;

export type MessageToastHandler = (message: string) => number | string;
export type NoButtonToastHandler = () => number | string;
type OneButtonToastHandler = (
  handleClick: () => void,
  bodyContent?: string
) => number | string;
type TwoButtonToastHandler = (
  handleClickPrimary: () => void,
  handleClickSecondary: () => void,
  bodyContent?: string
) => number | string;

type ToastShowHandler<T> = {
  [K in keyof T as `show${Capitalize<string & K>}Toast`]: T[K] extends Toast
    ? T[K] extends NoButtonToast
      ? NoButtonToastHandler
      : T[K] extends OneButtonToastWithoutActions
        ? OneButtonToastHandler
        : TwoButtonToastHandler
    : MessageToastHandler;
};

type TranslationsVariables = Record<string, unknown>;

export function useTranslatedToasts<T extends Toasts>(
  toasts: T,
  translationScope?: string
) {
  const { t } = useTranslation(translationScope);

  return useMemo(
    () =>
      Object.entries(toasts).reduce((acc, [key, value]) => {
        (acc as any)[`show${capitalizeString(key)}Toast`] = getHandler(
          value,
          t
        );

        return acc;
      }, {} as ToastShowHandler<T>),
    [t, toasts]
  );
}

function getHandler(value: Toast | ToastFunction, tFunction: TFunction) {
  const prepareContent = (
    content: ReactNode,
    variables?: TranslationsVariables
  ) => (typeof content === "string" ? tFunction(content, variables) : content);

  if (typeof value === "function") {
    return (message: string, variables?: TranslationsVariables) =>
      showMessageToast(value(tFunction(message, variables)));
  }

  if (isOneButtonToast(value)) {
    return (
      handleClick: () => void,
      bodyContent?: string,
      variables?: TranslationsVariables
    ) =>
      showSingleButtonToast({
        ...value,
        bodyContent:
          bodyContent ?? prepareContent(value.bodyContent, variables),
        buttonText: prepareContent(value.buttonText, variables),
        handleClick,
      });
  }

  if (isTwoButtonsToast(value)) {
    return (
      handleClickPrimary: () => void,
      handleClickSecondary: () => void,
      bodyContent?: string,
      variables?: TranslationsVariables
    ) =>
      showDoubleButtonToast({
        ...value,
        buttonTextPrimary: prepareContent(value.buttonTextPrimary, variables),
        buttonTextSecondary: prepareContent(
          value.buttonTextSecondary,
          variables
        ),
        bodyContent:
          bodyContent ?? prepareContent(value.bodyContent, variables),
        handleClickPrimary,
        handleClickSecondary,
      });
  }

  return (variables?: TranslationsVariables) =>
    showMessageToast({
      ...value,
      bodyContent: prepareContent(value.bodyContent, variables),
    });
}

function isOneButtonToast(toastConfig: Toast): toastConfig is OneButtonToast {
  return toastConfig.type === "one-button" && "buttonText" in toastConfig;
}

function isTwoButtonsToast(toastConfig: Toast): toastConfig is TwoButtonToast {
  return (
    toastConfig.type === "two-button" &&
    "buttonTextPrimary" in toastConfig &&
    "buttonTextSecondary" in toastConfig
  );
}
