import { styled } from "@mui/material";
import { Header as VoltHeader, MenuIcon } from "@volt_developers/components";

import { TEST_IDS } from "../../constants/TestIds";
import { Z_INDEX } from "../../utils/zIndex";
import { Avatar } from "../Avatar";
import { Breadcrumbs } from "../Breadcrumbs";
import { EnvironmentSwitcher } from "../EnvironmentSwitcher";

interface HeaderProps {
  onMenuClick: () => void;
}

export function Header({ onMenuClick }: HeaderProps) {
  return (
    <VoltHeader.Wrapper sx={{ position: "relative", zIndex: Z_INDEX.HEADER }}>
      <VoltHeader.Navigation sx={{ marginRight: 4 }}>
        <MenuIconWrapper
          data-testid={TEST_IDS.headerToggleSidebar}
          onClick={onMenuClick}
        >
          <MenuIcon />
        </MenuIconWrapper>
        <Breadcrumbs />
      </VoltHeader.Navigation>
      <VoltHeader.Action sx={{ marginLeft: 0 }}>
        <EnvironmentSwitcher />
        <Avatar />
      </VoltHeader.Action>
    </VoltHeader.Wrapper>
  );
}

const MenuIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(0, 4),
  cursor: "pointer",
  color: theme.palette.primary.main,
  fontSize: 24,
}));
