import { portalApi } from "api/rtkApi";

import type { ApiError } from "api/helpers/handleApiErrors";

type FavouritesRequest = {
  id: string;
};

type FavouritesMutationSuccessResponse = {
  id: string;
  massage: string;
};

type FavouritesMutationErroredResponse = {
  error: {
    exception: ApiError;
  };
};

type PutFavouritesRequest = FavouritesRequest;

type DeleteFavouritesRequest = FavouritesRequest;

type PutFavouritesMutationResponse =
  | FavouritesMutationErroredResponse
  | FavouritesMutationSuccessResponse;

type DeleteFavouritesMutationResponse =
  | FavouritesMutationErroredResponse
  | FavouritesMutationSuccessResponse;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    addToFavourites: builder.mutation<
      PutFavouritesMutationResponse,
      PutFavouritesRequest
    >({
      query: ({ id }) => ({
        url: `/users/settings/favourite-customers/${id}`,
        method: "PUT",
      }),
    }),
    removeFromFavourites: builder.mutation<
      DeleteFavouritesMutationResponse,
      DeleteFavouritesRequest
    >({
      query: ({ id }) => ({
        url: `/users/settings/favourite-customers/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useAddToFavouritesMutation, useRemoveFromFavouritesMutation } =
  extendedApi;
