import { createAction, createSlice } from "@reduxjs/toolkit";

import { listFactory } from "store/listFactory";

import type { APPLICATION_ENV } from "constants/environment";
import type { ListSliceState } from "store/helpers/list";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { Account } from "../models/Account";

export interface FetchAccountPayload {
  customerId?: UUID | UUID[];
  environment?: APPLICATION_ENV;
}

export type UpdateAccountPayload = ObjectWithId & {
  data: Pick<Account, "bankName" | "nickname">;
};

const { slicePart, selectors } = listFactory<Account>("accounts");

export type AccountsSliceState = ListSliceState<
  Account,
  { item?: Account | null }
>;

export const initialState: AccountsSliceState = {
  ...slicePart.initialState,
  item: undefined,
};

export const slice = createSlice({
  ...slicePart,
  initialState,
});

export const { setList } = slice.actions;

export const fetchAccounts = createAction<FetchAccountPayload>("fetchAccounts");
export const { selectList: selectAccounts } = selectors;

export const accountsOperationPaths = () => {
  return {
    listing: ["accounts", "listing"],
  };
};

export const accountsReducer = slice.reducer;
