import { Skeleton, styled, Typography } from "@mui/material";
import { getColor } from "@volt_developers/foundations";
import { forwardRef } from "react";

import { PAGE_HEIGHT } from "constants/styles";
import { TEST_IDS } from "constants/TestIds";

import type { Theme } from "@mui/material";
import type { SystemStyleObject } from "@mui/system";
import type { ReactNode } from "react";

export interface PageWrapperProps {
  children: ReactNode;
  banner?: ReactNode;
  contentWrapperSx?: SystemStyleObject<Theme>;
  isFetching?: boolean;
  title?: string;
  wrapperSx?: SystemStyleObject<Theme>;
}

export const PageWrapper = forwardRef<HTMLDivElement, PageWrapperProps>(
  (
    { banner, children, title, wrapperSx, contentWrapperSx, isFetching },
    ref
  ) => {
    return (
      <Wrapper ref={ref} sx={wrapperSx}>
        {banner}
        <ContentWrapper sx={contentWrapperSx}>
          {isFetching && (
            <Skeleton sx={{ mt: 4, mb: 4 }} variant="text" width={200} />
          )}
          {title && !isFetching && (
            <Typography
              data-testid={TEST_IDS.pageHeaderTitle}
              sx={{ mt: 4, mb: 4, color: getColor("steel500") }}
              variant="h4"
            >
              {title}
            </Typography>
          )}
          {children}
        </ContentWrapper>
      </Wrapper>
    );
  }
);

const ContentWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.ui10,
  flexGrow: 1,
  padding: theme.spacing(4),
  paddingTop: 0,
}));

const Wrapper = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.ui10,
  height: PAGE_HEIGHT,
  position: "relative",
  overflowX: "auto",
  display: "flex",
  flexDirection: "column",
}));
