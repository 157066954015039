import { fork, put, take, takeLatest } from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import {
  enqueueSnackbarError,
  enqueueSnackbarSticky,
  enqueueSnackbarSuccess,
  setError,
  setSuccess,
  startLoading,
} from "store/appSlice";

import * as BlocklistApi from "../../api/Blocklist";
import { translateBlockRuleToDuplicateError } from "../../utils/translateBlockRuleToDuplicateError";
import {
  batchBlockItems,
  batchUnblockItems,
  BlocklistOperationPaths,
  createBlocklistItem,
  fetchBlocklist,
  fetchBlocklistAndRefreshAfterActions,
  removeSelectedItems,
  setAllItemsCount,
  setBlocklist,
} from "./blocklist.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { ValidationError } from "models/ValidationError";
import type {
  OperationPath} from "store/appSlice";
import type { UUID } from "utils";

import type {
  BlockRulePostBody,
  BlockRulesQueryParams,
} from "../../models/BlockRules";

type FetchBlocklistPayloadAction = PayloadAction<{
  customerId: UUID;
  params: BlockRulesQueryParams;
}>;

const compareLoadingStates = (
  payload: OperationPath,
  loadingStates: Array<OperationPath[]>
) => {
  if (!payload || typeof payload === "string") {
    return false;
  }

  const preparedValues = loadingStates.map((loadingState) =>
    loadingState.join("")
  );
  const preparedPayload = payload.join("");

  return Boolean(
    preparedValues.find((loadingState) => loadingState === preparedPayload)
  );
};

function* fetchBlocklistSaga({
  payload: { customerId, params },
}: FetchBlocklistPayloadAction) {
  const { page = 0, ...rest } = params;

  const { list: operationPath } = BlocklistOperationPaths;

  yield put(startLoading(operationPath));

  try {
    // @ts-ignore noImplicitAny
    const data = yield callApi(BlocklistApi.getBlockRules, customerId, {
      ...rest,
      page,
    });

    yield put(setBlocklist(data));
    yield put(setAllItemsCount(data.allItems));
    yield put(setSuccess(operationPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* fetchBlocklistAndRefreshAfterActionsSaga({
  payload,
}: FetchBlocklistPayloadAction) {
  const { create, block, unblock } = BlocklistOperationPaths;

  // @ts-ignore noImplicitAny
  const blocklistData = yield put(fetchBlocklist(payload));

  while (true) {
    yield take([
      createBlocklistItem.type,
      batchBlockItems.type,
      batchUnblockItems.type,
    ]);

    while (true) {
      const action: ReturnType<typeof setSuccess> = yield take(setSuccess);

      if (compareLoadingStates(action.payload, [create, block, unblock])) {
        break;
      }
    }

    yield put(fetchBlocklist(blocklistData.payload));
  }
}

function* createBlocklistItemSaga({
  payload: { customerId, data },
}: PayloadAction<{ customerId: UUID | null; data: BlockRulePostBody }>) {
  const { create: operationPath } = BlocklistOperationPaths;

  yield put(startLoading(operationPath));

  try {
    yield callApi(BlocklistApi.postBlocklistItem, customerId, data);
    yield put(setSuccess(operationPath));
    yield put(
      enqueueSnackbarSuccess({
        code: "The 1 new key has been added to the list.",
      })
    );
  } catch (error) {
    if (error instanceof BlocklistApi.PostBlocklistItemDuplicateError) {
      const message = translateBlockRuleToDuplicateError(error.duplicateRule);

      yield put(
        enqueueSnackbarSticky({
          message,
        })
      );
      yield put(
        setError({
          path: operationPath,
          error: message,
        })
      );

      return;
    } else if (error.exception?.errorList?.length > 0) {
      const messages = error.exception?.errorList.map(
        (error: ValidationError) => error.message
      );

      for (const i in messages) {
        yield put(
          enqueueSnackbarError({
            message: messages[i],
          })
        );
      }
    } else {
      yield put(
        enqueueSnackbarError({
          code: "Creating block failed",
        })
      );
    }

    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* batchBlockItemsSaga({
  payload: { customerId, ids },
}: PayloadAction<{ customerId: UUID | null; ids: UUID[] }>) {
  const { block: operationPath } = BlocklistOperationPaths;

  yield put(startLoading(operationPath));

  try {
    // @ts-ignore noImplicitAny
    const calledIds = yield callApi(
      BlocklistApi.putBlockBlocklistItems,
      customerId,
      ids
    );

    yield put(setSuccess(operationPath));
    yield put(removeSelectedItems(calledIds));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* batchUnblockItemsSaga({
  payload: { customerId, ids },
}: PayloadAction<{ customerId: UUID | null; ids: UUID[] }>) {
  const { unblock: operationPath } = BlocklistOperationPaths;

  yield put(startLoading(operationPath));

  try {
    // @ts-ignore noImplicitAny
    const calledIds = yield callApi(
      BlocklistApi.putUnblockBlocklistItems,
      customerId,
      ids
    );

    yield put(setSuccess(operationPath));
    yield put(removeSelectedItems(calledIds));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* watchFetchBlocklist() {
  yield takeLatest(fetchBlocklist.type, fetchBlocklistSaga);
}

function* watchFetchBlocklistAndRefreshAfterActions() {
  yield takeLatest(
    fetchBlocklistAndRefreshAfterActions.type,
    fetchBlocklistAndRefreshAfterActionsSaga
  );
}

function* watchCreateBlocklistItem() {
  yield takeLatest(createBlocklistItem.type, createBlocklistItemSaga);
}

function* watchBatchBlockItems() {
  yield takeLatest(batchBlockItems.type, batchBlockItemsSaga);
}

function* watchBatchUnblockItems() {
  yield takeLatest(batchUnblockItems.type, batchUnblockItemsSaga);
}

export function* blocklistSaga() {
  yield fork(watchFetchBlocklist);
  yield fork(watchFetchBlocklistAndRefreshAfterActions);
  yield fork(watchCreateBlocklistItem);
  yield fork(watchBatchBlockItems);
  yield fork(watchBatchUnblockItems);
}
