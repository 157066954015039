import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCurrentUser } from "modules/auth/hooks/useCurrentUser";
import { useIsCurrentUserVoltEmployee } from "modules/auth/hooks/useIsCurrentUserVoltEmployee";
import { useGetCustomerQuery } from "modules/customers/api/Customers";
import {
  clearGlobalFilteredCustomer,
  selectGlobalFilteredCustomer,
} from "store/appSlice";

export function useGlobalFilter() {
  const dispatch = useDispatch();

  const isVoltEmployee = useIsCurrentUserVoltEmployee();
  const { currentUser, isLoading: isLoadingUser } = useCurrentUser();

  const globalPersistedFilteredCustomer = useSelector(
    selectGlobalFilteredCustomer
  );
  const customerId =
    !isVoltEmployee && !globalPersistedFilteredCustomer
      ? currentUser?.customer?.id ?? ""
      : globalPersistedFilteredCustomer?.id ?? "";

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    isError,
  } = useGetCustomerQuery({ id: customerId }, { skip: !customerId });

  const isGlobalFiltering = Boolean(globalPersistedFilteredCustomer?.id);

  const isLoading = isLoadingCustomer || isLoadingUser;

  const globallyFilteredCustomer =
    (!isVoltEmployee || isGlobalFiltering) && !isLoading
      ? customer ?? null
      : null;

  const handleCustomerClear = useCallback(() => {
    dispatch(clearGlobalFilteredCustomer());
  }, [dispatch]);

  return {
    isError,
    isGlobalFiltering,
    isLoading,
    globallyFilteredCustomer,
    handleCustomerClear,
  };
}
