import { v4 as uuidv4 } from "uuid";

import { uuidPattern } from "./patterns";

export function isUUID(uuid: string): boolean {
  return uuidPattern.test(uuid.toLowerCase());
}

export function generateUUID(): UUID {
  return uuidv4();
}

export type UUID = string;
