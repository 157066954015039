import i18n from "i18next";

import type { Currency } from "../../../models/Currency";

export const formatReportAmount = (amount: number, currency: Currency) => {
  const formatedValue = i18n
    .t("common:CURRENCY", {
      data: {
        amount: amount,
        currency,
      },
    })
    .replaceAll(",", "");
  const [integerPart, decimalPart = "0000"] = formatedValue.split(".");
  const correctDecimalPart = decimalPart
    .padEnd(currency.decimal, "0")
    .substring(0, currency.decimal);

  return Number([integerPart, correctDecimalPart].join("."));
};
