import * as Yup from "yup";

import { ALPHANUMERIC_ONLY } from "constants/errors";
import { alphanumericPattern } from "utils/patterns";

declare module "yup" {
  export interface StringSchema {
    validateAlphanumeric(): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateAlphanumeric",
  function () {
    return this.matches(alphanumericPattern, ALPHANUMERIC_ONLY);
  }
);
