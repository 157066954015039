import { useContext } from "react";

import { ERRORS } from "../../constants/Errors";
import { BreadcrumbsContext } from "./BreadcrumbsContext";

export function useBreadcrumbsContext() {
  const context = useContext(BreadcrumbsContext);

  if (!context) {
    throw new Error(ERRORS.MISSING_BREADCRUMBS_CONTEXT);
  }

  return context;
}
