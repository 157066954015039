import { useIsCurrentUserVoltEmployee } from "../../hooks/useIsCurrentUserVoltEmployee";

export function useSCAConfirmBanner() {
  const isVoltAdmin = useIsCurrentUserVoltEmployee();
  const shouldShowBanner = !isVoltAdmin;

  return {
    shouldShowBanner,
  };
}
