export interface ApiValidationFormError {
  message: string;
  property: string;
}

export interface ApiError {
  code: number;
  message: string;
}

/** @deprecated */
function isApiValidationFormErrors(
  error: unknown
): error is { exception: { errorList: ApiValidationFormError[] } } {
  return Boolean(
    (error as { exception: { errorList: ApiValidationFormError[] } }).exception
      ?.errorList
  );
}

/** @deprecated */
function isApiError(error: unknown): error is { exception: ApiError } {
  return Boolean((error as { exception: ApiError }).exception);
}

/** @deprecated */
export function handleApiErrors(
  error: unknown
): ApiError | ApiValidationFormError[] | undefined {
  if (!Boolean(error)) {
    return;
  }

  if (isApiValidationFormErrors(error)) {
    return error.exception.errorList;
  }

  if (isApiError(error)) {
    return error.exception;
  }
}
