import { createStyles, makeStyles } from "@material-ui/core";

export const FILTER_ACTIVE_ENABLED = "enabled";
export const FILTER_ACTIVE_DISABLED = "disabled";
export const FILTER_ACTIVE_INDETERMINATE = "indeterminate";

export type CIRCUIT_BREAKER_BLOCKLIST_FILTER_ACTIVE =
  | typeof FILTER_ACTIVE_DISABLED
  | typeof FILTER_ACTIVE_ENABLED
  | typeof FILTER_ACTIVE_INDETERMINATE;

export function useBlocklistFilters() {
  const classes = useStyles();

  return {
    classes,
  };
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    plusIcon: {
      width: 12,
      height: 12,
      marginTop: -2,
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(0, 2, 0, 1),
      fontSize: 12,
      letterSpacing: 1,
      minWidth: 0,

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    buttonText: {
      lineHeight: "24px",
      paddingTop: "2px",
    },
  })
);
