import { useCallback, useState } from "react";

import { useSCA } from "../../hooks/useSCA";

export interface UseSCAConfirmModalProps {
  onSubmit: (scaToken?: string) => Promise<void>;
}

export function useSCAConfirmModal({ onSubmit }: UseSCAConfirmModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { authenticateSCAAction, isLoading: isSCAAuthenticationInProgress } =
    useSCA();

  const authenticateSCAAndHandleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const token = await authenticateSCAAction();

    onSubmit(token).finally(() => {
      setIsSubmitting(false);
    });
  }, [authenticateSCAAction, onSubmit]);

  const isLoading = isSCAAuthenticationInProgress || isSubmitting;

  return {
    authenticateSCAAndHandleSubmit,
    isLoading,
  };
}
