import type { UUID } from "utils";

export interface ExtraNotificationFields {
  paymentNotificationSettingsFields?: string[];
  paymentReceivedNotificationSettingsFields?: string[];
}
export interface VerifyNotificationSettings {
  active: boolean;
  createdAt: string;
  email: string;
  enabled: boolean;
  extraNotificationFields: ExtraNotificationFields;
  ownerId: UUID;
  secret: string;
  sources: "verify";
  url: string;
  paymentReceivalEnabled?: boolean;
}

export interface FieldsFilter {
  _default?: string[];
  PAYMENT_RECEIVED?: string[];
}

export interface NotificationSettings {
  createdAt: string;
  email: string;
  enabled: boolean;
  fieldsFilter: FieldsFilter;
  ownerId: string;
  secret: string;
  url: string;
}

export type NotificationSettingsUpdate = Pick<
  NotificationSettings,
  "email" | "enabled" | "fieldsFilter" | "url"
>;

export type VerifyNotificationSettingsUpdate = Pick<
  VerifyNotificationSettings,
  "email" | "enabled" | "extraNotificationFields" | "url"
>;

export const PROPERTY_EXTRA_FIELDS = "fieldsFilter";
