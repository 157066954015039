import { useSelector } from "react-redux";

import { useOperationState } from "hooks/operationsState";
import { useIsCurrentUserVoltEmployee } from "modules/auth/hooks/useIsCurrentUserVoltEmployee";
import { authOperationPath , selectIsAuthorized } from "modules/auth/store/auth.slice";

import { useTermsAndConditions } from "../../hooks/useTermsAndConditions";
import { operationPath } from "../../store";
import { TermsAndConditionsDialog } from "./Dialog";

export function TermsAndConditions() {
  const isCurrentUserVoltEmployee = useIsCurrentUserVoltEmployee();
  const isAuthorized = useSelector(selectIsAuthorized);

  const { doesUserNeedToAcceptTermsAndConditions } = useTermsAndConditions();

  const { operationSuccess: isCurrentUserSuccess } = useOperationState(
    authOperationPath.currentUser,
    {
      defaultLoading: true,
    }
  );

  const { operationSuccess: isLicenceAgreementLastUpdateSuccess } =
    useOperationState(operationPath.licenceAgreementLastUpdate);

  const { operationSuccess: isLicenceAgreementFileSuccess } = useOperationState(
    operationPath.licenceAgreementFile
  );

  if (
    isLicenceAgreementLastUpdateSuccess &&
    isLicenceAgreementFileSuccess &&
    isCurrentUserSuccess &&
    doesUserNeedToAcceptTermsAndConditions &&
    !isCurrentUserVoltEmployee &&
    isAuthorized
  ) {
    return <TermsAndConditionsDialog />;
  }

  return null;
}
