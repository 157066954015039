import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";

import type { FieldErrors } from "react-hook-form";

import type { ReportNotificationsForm } from "../../../models/ReportNotificationsForm";

export function useReportNotificationsFormButtons() {
  const { t } = useTranslation("connect");
  const { formState, watch } = useFormContext<ReportNotificationsForm>();
  const values = watch();

  const tooltipTitleTranslationKey = getTooltipTitleTranslationKey(
    values,
    formState.errors
  );

  const tooltipTitle = tooltipTitleTranslationKey
    ? t(tooltipTitleTranslationKey)
    : null;

  const isDisabled = Boolean(tooltipTitle);

  return {
    tooltipTitle,
    isDisabled,
  };
}

export function getTooltipTitleTranslationKey(
  values: ReportNotificationsForm,
  fieldErrors: FieldErrors<ReportNotificationsForm>
) {
  if (!values.url) {
    return TRANSLATION_KEYS.pleaseProvideNotificationURL;
  }

  if (!values.email) {
    return TRANSLATION_KEYS.pleaseProvideEmail;
  }

  if (fieldErrors.url) {
    return TRANSLATION_KEYS.pleaseProvideValidNotificationURL;
  }

  if (fieldErrors.email) {
    return TRANSLATION_KEYS.pleaseProvideValidEmail;
  }

  return null;
}
