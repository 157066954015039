import {
  FIELD_REQUIRED,
  GENERIC_ERROR,
  INVALID_EMAIL_ADDRESS,
  LENGTH_EQUAL,
  LENGTH_MAX,
  LENGTH_MIN,
} from "constants/errors";

import type { LocaleObject } from "yup";

export const yupLocale: LocaleObject = {
  mixed: {
    default: GENERIC_ERROR,
    required: FIELD_REQUIRED,
  },
  string: {
    min: ({ min }: { min: number }) => ({
      key: LENGTH_MIN,
      values: { $minLength: min },
    }),
    max: ({ max }: { max: number }) => ({
      key: LENGTH_MAX,
      values: { $maxLength: max },
    }),
    length: ({ length }: { length: number }) => ({
      key: LENGTH_EQUAL,
      values: { $length: length },
    }),
    email: INVALID_EMAIL_ADDRESS,
  },
};
