import { CircuitBreaker } from "@volt_developers/react-ui-components";

import { ACTION_LIST } from "models/role/action";
import { SUBJECT_CIRCUIT_BREAKER } from "models/role/subject";

import { allowlistRoute } from "./allowlist";
import { blocklistRoute } from "./blocklist";
import { riskRulesRoute } from "./riskRules";

import type { Route } from "routes/RouteModel";

export const circuitBreakerRoutes: Route = {
  // title for this path is defined in children routes
  icon: CircuitBreaker,
  path: "/circuit-breaker",
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CIRCUIT_BREAKER,
  },
  children: [allowlistRoute, blocklistRoute, riskRulesRoute],
  showChildrenInSidebar: true,
};

export { allowlistRoute, blocklistRoute, riskRulesRoute };
