import React from "react";
import Helmet from "react-helmet";
import { Switch } from "react-router-dom";

import { AuthorizedRoute } from "components/routes/AuthorizedRoute";
import { UnauthorizedRoute } from "components/routes/UnauthorizedOnlyRoute";
import { UniversalRoute } from "components/routes/UniversalRoute";
import { useAppRoutes } from "hooks/useBuildChildRoutes";
import { useRedirectToTargetPathname } from "hooks/useRedirectToTargetPathname";
import { BasicLayout } from "layouts/Basic";
import { DashboardLayout } from "layouts/Dashboard";
import { TermsAndConditions } from "modules/user-settings/components/termsAndConditions";
import { Page404 } from "pages/Page404";
import { SentryRoute } from "sentry";

import { useRouterAnalytics } from "./useRouterAnalytics";

export function MainSwitch() {
  useRedirectToTargetPathname();
  const { defaultPageTitle, onHelmetChangeClintState } = useRouterAnalytics();

  const {
    switchAppRoutes,
    switchAuthRoutes,
    authPaths,
    appPaths,
    universalPaths,
    switchUniversalRoutes,
  } = useAppRoutes();

  return (
    <>
      <Helmet
        defaultTitle={defaultPageTitle}
        titleTemplate="%s • Volt Fuzebox"
        onChangeClientState={onHelmetChangeClintState}
      />
      <Switch>
        <UniversalRoute
          path={universalPaths}
          render={() => (
            <BasicLayout>
              <Switch>{switchUniversalRoutes}</Switch>
            </BasicLayout>
          )}
        />
        <UnauthorizedRoute
          path={authPaths}
          render={() => (
            <BasicLayout>
              <Switch>{switchAuthRoutes}</Switch>
            </BasicLayout>
          )}
        />
        <AuthorizedRoute
          path={appPaths}
          render={() => (
            <DashboardLayout key="layout">
              <TermsAndConditions />
              <Switch>
                {switchAppRoutes}
                <SentryRoute component={Page404} />
              </Switch>
            </DashboardLayout>
          )}
        />
      </Switch>
    </>
  );
}
