/*eslint perfectionist/sort-objects: "error"*/
export const TEST_IDS = {
  authSecureInfo: "auth-secure-info",
  banksAutocomplete: "banks-autocomplete",
  buttonLoadingIndicator: "button-loading-indicator",
  buttonRetry: "button-retry",
  contactSupportLink: "contact-support-link",
  currency: "account-currencies",
  dataGridPlaceholder: "applications-data-grid-placeholder",
  dataGridPlaceholderButton: "data-grid-placeholder-create-button",
  dataGridTable: "data-grid-table",
  downloadCopyContentDialog: "download-copy-content-dialog",
  downloadCopyContentWrapper: "download-copy-content-wrapper",
  formCancel: "form-cancel",
  formCancelButton: "form-cancel-button",
  formSubmit: "form-submit",
  formSubmitButton: "form-submit-button",
  getAutocomplete: (id: string) => `${id}-autocomplete`,
  getCard: (id: string) => `${id}-card`,
  getCardInput: (id: string) => `${id}-input-card`,
  getCheckbox: (id: string) => `${id}-checkbox`,
  getCountry: (id: string) => `country-${id}`,
  getError: (id: string) => `${id}-error`,
  getForm: (name: string) => `${name}-form`,
  getInput: (id: string) => `${id}-input`,
  getItems: (id: string) => `${id}-items`,
  getRegion: (id: string) => `region-${id}`,
  getTableHeaderCell: (cell: string) => `data-grid-header-cell-${cell}`,
  getTextAreaError: (id: string) => `text-area-${id}-error`,
  getTextAreaHelperText: (id: string) => `text-area-${id}-helper-text`,
  getTextAreaInput: (id: string) => `text-area-${id}-input`,
  listHeaderCheckbox: "list-header-checkbox",
  modalCancelButton: "modal-cancel-button",
  modalConfirmButton: "modal-confirm-button",
  notFoundButton: "not-found-button",
  notFoundDescription: "not-found-description",
  notFoundHeader: "not-found-header",
  notificationsConfigureButton: "notifications-configure-button",
  notificationsConfigureCard: "notifications-configure-card",
  notificationURL: "notification-url",
  pageHeaderTitle: "page-header-title",
  querySearch: "query-search",
  regionTreeSelect: "region-tree-select",
  regulationInfo: "regulation-info",
  reportsHistoryWrapper: "reports-history-wrapper",
} as const;
