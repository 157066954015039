import { currenciesMap, defaultCurrency } from "../../../utils/currencies";

import type { Currency } from "../../../models/Currency";
import type { CurrencyCode } from "../../../models/CurrencyCode";

/**
 * @note: Currently Volt supports querying either by single currency and amount range or multiple currencies without amount range.
 */
export function getReportFormatCurrency(
  currency: CurrencyCode[] | null | undefined
): Currency {
  if (!currency || currency.length !== 1) {
    return defaultCurrency;
  }

  return currenciesMap.get(currency[0]) ?? defaultCurrency;
}
