import type { AnyAction } from "@reduxjs/toolkit";

export function sanitizeSensitiveDataFromPayload(
  action: AnyAction,
  keysToSanitize: readonly string[]
) {
  if (
    !action.payload ||
    typeof action.payload !== "object" ||
    Array.isArray(action.payload)
  ) {
    return action;
  }

  const sanitizedAction = {
    ...action,
    payload: { ...action.payload },
  };

  Object.keys(sanitizedAction.payload).forEach((key) => {
    if (keysToSanitize.includes(key)) {
      sanitizedAction.payload[key] = "<redacted>";
    }
  });

  return sanitizedAction;
}
