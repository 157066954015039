import { TRANSLATION_KEYS } from "./TranslationKeys";

import type { ToastProps } from "components/toasts/MessageToast";

export const AUTH_TOASTS_IDS = {
  scaConfigurationSuccess: "auth-sca-configuration-success-id",
  scaConfigurationFailure: "auth-sca-configuration-failure-id",
  scaLoginFailure: "auth-sca-login-failure-id",
  scaAuthenticateFailure: "auth-sca-authenticate-failure-id",
  proxyLoginFailure: "auth-proxy-login-failure-id",
  proxyLoginInvalidToken: "auth-proxy-login-wrong-token-id",
  transactionInitiationSigningFailed:
    "auth-transaction-initiation-signing-failed-id",
  transactionFinalizationSigningFailed:
    "auth-transaction-finalization-signing-failed-id",
} as const;

export const AUTH_TOASTS = {
  scaConfigurationSuccess: {
    bodyContent: TRANSLATION_KEYS.strongCustomerAuthenticationHasBeenEnabled,
    variant: "success",
    config: {
      toastId: AUTH_TOASTS_IDS.scaConfigurationSuccess,
      updateId: "auth-sca-configuration-success-update-id",
    },
  },
  scaConfigurationFailure: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.scaConfigurationFailure,
      updateId: "auth-sca-configuration-failure-update-id",
    },
  }),
  scaLoginFailure: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.scaLoginFailure,
      updateId: "auth-sca-login-failure-update-id",
    },
  }),
  scaAuthenticateFailure: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.scaAuthenticateFailure,
      updateId: "auth-sca-authenticate-failure-update-id",
    },
  }),
  proxyLoginFailure: {
    bodyContent: TRANSLATION_KEYS.proxyLoginFailed,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.proxyLoginFailure,
      updateId: "auth-proxy-login-failure-id",
    },
  },
  proxyLoginInvalidToken: {
    bodyContent: TRANSLATION_KEYS.proxyLoginInvalidToken,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.proxyLoginInvalidToken,
      updateId: "auth-proxy-login-wrong-token-update-id",
    },
  },
  transactionInitiationSigningFailure: {
    bodyContent: TRANSLATION_KEYS.transactionInitiationSigningFailed,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.transactionInitiationSigningFailed,
      updateId: "auth-transaction-signing-failure-update-id",
    },
  },
  transactionFinalizationSigningFailure: {
    bodyContent: TRANSLATION_KEYS.transactionFinalizationSigningFailed,
    variant: "failure",
    config: {
      toastId: AUTH_TOASTS_IDS.transactionFinalizationSigningFailed,
      updateId: "auth-transaction-finalization-signing-update-id",
    },
  },
} as const;
