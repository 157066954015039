import { Box } from "@material-ui/core";
import { styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useRef } from "react";
import { createGlobalStyle } from "styled-components";

import { Notifier } from "components/Notifier";
import { PAGE_HEIGHT } from "constants/styles";
import { HelpHeaderProvider } from "context/HelpHeaderContext";
import { useApplicationShell } from "hooks/useApplicationShell";
import { Header } from "modules/common/components/Header";
import { Sidebar } from "modules/common/components/Sidebar";
import { SIDEBAR_WIDTH } from "modules/common/constants/Sidebar";
import { BreadcrumbsProvider } from "modules/common/context/BreadcrumbsContext";
import { shouldForwardPropHelper } from "utils/shouldForwardPropHelper";

import type { theme as voltTheme } from "@volt_developers/react-ui-components";
import type { ReactNode} from "react";

const SNACK_NUMBER = 3;
const DURATION_IN_MS = 3000;

export interface DashboardProps {
  children?: ReactNode;
}

export function DashboardLayout({ children }: DashboardProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const {
    toggleSidebar,
    isSidebarOpen,
    handleTransitionEnd,
    isSidebarAnimationInProgress,
  } = useApplicationShell();

  return (
    <NewRoot>
      <SnackbarProvider
        autoHideDuration={DURATION_IN_MS}
        maxSnack={SNACK_NUMBER}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <HelpHeaderProvider>
          <BreadcrumbsProvider>
            <Notifier />
            <GlobalStyle />
            <AppContainer
              isOpen={isSidebarOpen}
              ref={rootRef}
              onTransitionEnd={handleTransitionEnd}
            >
              <Sidebar
                isAnimationInProgress={isSidebarAnimationInProgress}
                isOpen={isSidebarOpen}
              />
              <MainWrapper>
                <Header onMenuClick={toggleSidebar} />
                <Box display="flex" flex={1} flexDirection="column">
                  <ContentWrapper>{children}</ContentWrapper>
                </Box>
              </MainWrapper>
            </AppContainer>
          </BreadcrumbsProvider>
        </HelpHeaderProvider>
      </SnackbarProvider>
    </NewRoot>
  );
}

const GlobalStyle = createGlobalStyle<{ theme: typeof voltTheme }>`
  html,
  body,
  #root {
    height: 100%;
  }

  *, ::after, ::before {
    box-sizing: inherit;
  }
`;

const NewRoot = styled("div")({
  minHeight: "100vh",
  boxSizing: "border-box",
});

interface AppContainerProps {
  isOpen: boolean;
}

const APP_CONTAINER_PROPS = new Set(["isOpen"]);

const AppContainer = styled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardPropHelper(prop, APP_CONTAINER_PROPS),
})<AppContainerProps>(({ isOpen }) => ({
  display: "grid",
  gridTemplateColumns: "0px 100%",
  // On Safari versions < 17 grid-template-columns transition is not triggering transitionend event
  // As a workaround we are animating top which should have no effect on static element
  transition: "grid-template-columns 0.3s, top 0.3s",

  top: 0,
  ...(isOpen && {
    top: 1,
    gridTemplateColumns: `${SIDEBAR_WIDTH}px calc(100% - ${SIDEBAR_WIDTH}px)`,
  }),
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  height: PAGE_HEIGHT,
  backgroundColor: theme.palette.ui10,
  overflow: "auto",
}));

const MainWrapper = styled("div")({
  minWidth: "450px",
});
