import type { Report } from "../models/Reports";

export function swapReportId(
  reports: Report[],
  oldReportId: string,
  newReportId: string
): Report[] {
  return reports.map((report) =>
    report.id === oldReportId ? { ...report, id: newReportId } : report
  );
}
