import axios from "axios";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import {
  addPaginationAndSort,
  getPaginationInfoFromHeaders,
} from "api/helpers/pagination";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { isUUID } from "utils";

import { DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_ACTIVE_FLAG } from "../config";
import { BLOCK_RULE_TYPE_BANK } from "../constants/Rules";
import { BlockRuleActiveStates } from "../models/BlockRules";

import type { AxiosRequestConfig } from "axios";

import type { PaginatedList } from "api/helpers/pagination";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type {
  BlockRule,
  BlockRulePostBody,
  BlockRulesQueryParams,
} from "../models/BlockRules";

export type GetBlockRules = (
  customerId: UUID,
  qsParams: BlockRulesQueryParams,
  options?: AxiosRequestConfig
) => Promise<PaginatedList<BlockRule>>;

export const getBlockRules: GetBlockRules = async (
  customerId,
  qsParams,
  options
) => {
  const { types, active, search } = qsParams;
  const params: Record<string, any> = addPaginationAndSort<BlockRule>(qsParams);

  if (types) {
    params.type = types;
  }

  if (active.length === 1) {
    params.active = active.includes(BlockRuleActiveStates.YES);
  }

  if (search) {
    if (isUUID(search)) {
      params.id = search;
    } else {
      params.search = search;
    }
  }

  try {
    const response = await axios.get<BlockRule[]>(
      `/circuit-breaker/customers/${customerId}/block-rules`,
      {
        ...options,
        params,
        paramsSerializer,
      }
    );

    const { data, headers: responseHeaders = {} } = response;

    const { totalItems, currentPage, currentLimit } =
      getPaginationInfoFromHeaders(responseHeaders);
    const allItems = parseInt(
      responseHeaders["all-items"]?.toString() ?? "",
      10
    );

    return {
      list: data,
      totalItems,
      allItems,
      currentPage,
      currentLimit,
    };
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export type PutBlockBlocklistItems = (
  customerId: UUID,
  data: UUID[],
  options?: AxiosRequestConfig
) => Promise<string>;

export const putBlockBlocklistItems: PutBlockBlocklistItems = async (
  customerId,
  itemsIDs,
  options
) => {
  try {
    const response = await axios.put<string>(
      `/circuit-breaker/customers/${customerId}/block-rules/enable`,
      itemsIDs,
      options
    );

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export type PutUnblockBlocklistItems = (
  customerId: UUID,
  data: UUID[],
  options?: AxiosRequestConfig
) => Promise<string>;

export const putUnblockBlocklistItems: PutUnblockBlocklistItems = async (
  customerId,
  itemsIDs,
  options
) => {
  try {
    const response = await axios.put<string>(
      `/circuit-breaker/customers/${customerId}/block-rules/disable`,
      itemsIDs,
      options
    );

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
};

export class PostBlocklistItemDuplicateError extends Error {
  duplicateRule: BlockRule;

  constructor(duplicateRule: BlockRule) {
    super();

    this.duplicateRule = duplicateRule;
    Object.setPrototypeOf(this, PostBlocklistItemDuplicateError.prototype);
  }
}

export type PostBlocklistItem = (
  customerId: UUID,
  data: BlockRulePostBody,
  options?: AxiosRequestConfig
) => Promise<ObjectWithId>;

export const postBlocklistItem: PostBlocklistItem = async (
  customerId,
  item,
  options
) => {
  try {
    const postBody =
      item.type === BLOCK_RULE_TYPE_BANK
        ? {
            type: BLOCK_RULE_TYPE_BANK,
            bank: item.value,
            active: DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_ACTIVE_FLAG,
          }
        : {
            type: item.type,
            value: item.value,
            active: DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_ACTIVE_FLAG,
          };

    const response = await axios.post<Pick<BlockRule, "id">>(
      `/circuit-breaker/customers/${customerId}/block-rules`,
      postBody,
      options
    );

    return response.data;
  } catch (error) {
    if (error.response?.status === 409) {
      throw new PostBlocklistItemDuplicateError(error.response.data);
    }

    throw getErrorDetails(error);
  }
};
