/*eslint perfectionist/sort-objects: "error"*/
import type { UUID } from "utils";

const ALLOW_RULE = "allow-rule";
const ALLOW_LIST_FORM = "allowlist-create-form";

export const TEST_IDS = {
  actionBarButton: "action-bar-button",
  addItem: "add-item",
  allowlistBreadcrumbLink: "allowlist-breadcrumb-link",
  allowlistCreateBreadcrumbLink: "allowlist-create-breadcrumb-link",
  allowlistCreateForm: ALLOW_LIST_FORM,
  allowlistCreateFormCard: `${ALLOW_LIST_FORM}-card`,
  allowlistCreateFormLoading: `${ALLOW_LIST_FORM}-loading`,
  allowlistUpdateFailure: "allowlist-update-failure",
  allowlistUpdateSuccess: "allowlist-update-success",
  allowlistUsernamesFailure: "allowlist-usernames-failure",
  allowRuleAddButton: `${ALLOW_RULE}-add-button`,
  allowRuleInput: `${ALLOW_RULE}-input`,
  allowRuleRemove: `${ALLOW_RULE}-remove`,
  blocklistBreadcrumbLink: "blocklist-breadcrumb-link",

  circuitBreakerBlocklistBlockButton: "circuit-breaker-blocklist-block-button",

  circuitBreakerBlocklistCreateForm: "circuit-breaker-blocklist-create-form",
  circuitBreakerBlocklistCreateFormBankType:
    "circuit-breaker-blocklist-create-form-bank-type",
  circuitBreakerBlocklistCreateFormInputType:
    "circuit-breaker-blocklist-create-form-input-type",

  circuitBreakerBlocklistCreateFormInputValue:
    "circuit-breaker-blocklist-create-form-input-value",

  circuitBreakerBlocklistCreateFormSubmit:
    "circuit-breaker-blocklist-create-form-submit",
  circuitBreakerBlocklistFilterAddButton:
    "circuit-breaker-blocklist-filter-add-button",
  circuitBreakerBlocklistFiltersChips:
    "circuit-breaker-blocklist-filters-chips",
  circuitBreakerBlocklistUnblockButton:
    "circuit-breaker-blocklist-unblock-button",
  circuitBreakerBreadcrumbLink: "circuit-breaker-breadcrumb-link",
  circuitBreakerLink: "circuit-breaker-link",
  circuitBreakerPlaceholderIcon: "circuit-breaker-placeholder-icon",
  circuitBreakerPlaceholderMessage: "circuit-breaker-placeholder-message",
  circuitBreakerPlaceholderWrapper: "circuit-breaker-placeholder-wrapper",
  circuitBreakerPreviewDialog: "circuit-breaker-preview-dialog",
  circuitBreakerRiskRulesAddRuleButton:
    "circuit-breaker-risk-rules-add-rule-button",
  circuitBreakerRiskRulesLimitRuleButton:
    "circuit-breaker-risk-rules-limit-rule-button",
  circuitBreakerSearch: "circuit-breaker-search",
  getAllowlistRow: (id: UUID) => `allowlist-id-${id}`,
  getAllowRule: (index: number) => `${ALLOW_RULE}-${index}`,
  goToCustomerButton: "go-to-customer-button",
  goToCustomersButton: "go-to-customers-button",
  riskRulesBreadcrumbLink: "risk-rules-breadcrumb-link",
};
