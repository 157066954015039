import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectIsAuthorized } from "modules/auth/store/auth.slice";
import { clearTargetPathname, selectTargetPathname } from "store/appSlice";

export function useRedirectToTargetPathname() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectIsAuthorized);
  const history = useHistory();
  const targetPathname = useSelector(selectTargetPathname);

  useEffect(() => {
    if (targetPathname && isAuthorized) {
      history.push(targetPathname);
    }

    return () => {
      if (targetPathname) {
        dispatch(clearTargetPathname());
      }
    };
  }, [dispatch, targetPathname, history, isAuthorized]);
}
