import axios from "axios";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import { INVALID_URL } from "constants/errors";

import type { AxiosRequestConfig } from "axios";

import type {
  LicenceAgreementFile,
  LicenceAgreementLastUpdate,
} from "../models/TermsAndConditions";

const ERROR = {
  name: INVALID_URL,
  message: INVALID_URL,
  code: INVALID_URL,
} as Error;

function licenceAgreementFileParser(rawData: string): LicenceAgreementFile {
  const title = rawData.split("\n").shift() ?? "";
  const content = rawData.substring(rawData.indexOf("\n") + 1);

  return { title, content };
}

export async function getLicenceAgreementLastUpdate(): Promise<LicenceAgreementLastUpdate> {
  try {
    const url = import.meta.env.FUZEBOX_LICENCE_AGREEMENT_UPDATE_DATE;

    if (!url) {
      throw ERROR;
    }

    const response = await axios.get<LicenceAgreementLastUpdate>(url, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    return response.data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function getLicenceAgreementFile(
  version: LicenceAgreementLastUpdate["version"]
): Promise<LicenceAgreementFile> {
  try {
    const prefixUrl = import.meta.env.FUZEBOX_LICENCE_AGREEMENT_PREFIX_URL;

    if (!prefixUrl) {
      throw ERROR;
    }

    const url = `${prefixUrl}licence.${version}.txt`;

    const { data } = await axios.get<string>(url);

    return licenceAgreementFileParser(data);
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export type PostTermAndConditionsAccept = (
  options: AxiosRequestConfig
) => Promise<void>;

export const postTermAndConditionsAccept: PostTermAndConditionsAccept = async (
  options
) => {
  try {
    await axios.post("/users/settings/sandbox-terms/accept", null, options);
  } catch (error) {
    throw getErrorDetails(error);
  }
};
