import { styled } from "@mui/material";
import { ButtonDeprecated, Dialog } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import {
  COMMON_TRANSLATION_KEYS,
  getFromCommonTranslationKeys,
} from "constants/commonTranslationsKeys";
import { TEST_IDS as COMMON_TEST_IDS } from "constants/TestIds";

import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { SCAConfirmBanner } from "../SCAConfirmBanner";
import { useSCAConfirmModal } from "./useSCAConfirmModal";

import type { ReactNode } from "react";

export interface SCAConfirmModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: (scaToken?: string) => Promise<void>;
  title: ReactNode;
}

export function SCAConfirmModal({
  isOpen,
  onClose,
  onSubmit,
  title,
}: SCAConfirmModalProps) {
  const { t } = useTranslation("auth");

  const { authenticateSCAAndHandleSubmit, isLoading } = useSCAConfirmModal({
    onSubmit,
  });

  return (
    <Dialog.Wrapper maxWidth="xl" open={isOpen}>
      <Dialog.Title component="div" sx={{ pt: 3, pb: 0.5 }} onClose={onClose}>
        {title}
      </Dialog.Title>
      <StyledDialogContent>
        <SCAConfirmBanner sx={{ mt: 4, mb: 3 }}>
          {t(TRANSLATION_KEYS.pleaseConfirmYourRequestUsingSelectedSCAMethod)}
        </SCAConfirmBanner>
      </StyledDialogContent>
      <Dialog.Actions sx={{ px: 4, pt: 1.5, pb: 3 }}>
        <ButtonsWrapper>
          <ButtonDeprecated
            color="grey"
            data-testid={COMMON_TEST_IDS.modalCancelButton}
            size="large"
            variant="outlined"
            onClick={onClose}
          >
            {t(getFromCommonTranslationKeys(COMMON_TRANSLATION_KEYS.cancel))}
          </ButtonDeprecated>
          <ButtonDeprecated
            data-testid={COMMON_TEST_IDS.modalConfirmButton}
            loading={isLoading}
            size="large"
            sx={{ width: "inherit" }}
            onClick={authenticateSCAAndHandleSubmit}
          >
            {t(getFromCommonTranslationKeys(COMMON_TRANSLATION_KEYS.confirm))}
          </ButtonDeprecated>
        </ButtonsWrapper>
      </Dialog.Actions>
    </Dialog.Wrapper>
  );
}

interface DialogContentProps {
  children?: ReactNode;
}

function StyledDialogContent({ children }: DialogContentProps) {
  return (
    <Dialog.Content
      sx={{
        p: 4,
        pb: 0,
        width: DIALOG_CONTENT_WIDTH,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Dialog.Content>
  );
}

const ButtonsWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
  gap: 24,
});

const DIALOG_CONTENT_WIDTH = 576;
