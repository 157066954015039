import { useLocalStorage } from "react-use";

import { useAbility } from "hooks/useAbility";

import type { PermissionObject } from "../../types/permissions";

export interface UseSidebarItem {
  itemId: string;
  permissions: PermissionObject[];
  isDefaultOpen?: boolean;
  isGroup?: boolean;
  permissionOneOf?: boolean;
}

export function useSidebarItem({
  itemId,
  permissions,
  permissionOneOf,
  isDefaultOpen = false,
}: UseSidebarItem) {
  const ability = useAbility();

  const permissionsArray = permissions.map((permission) =>
    ability.can(permission.do, permission.on)
  );
  const hasAccess = permissionOneOf
    ? permissionsArray.some((can) => can)
    : permissionsArray.every((can) => can);

  const [isGroupOpen, setIsGroupOpen] = useLocalStorage(
    `isOpen-${itemId}`,
    isDefaultOpen
  );

  return { hasAccess, isGroupOpen, setIsGroupOpen };
}
