import axios from "axios";

import { getErrorDetails } from "./helpers/getErrorDetails";

import type { AxiosRequestConfig } from "axios";

import type { Country } from "models/Country";

/**
 * @deprecated Do not use it in the new code base. Use `useGetProcessableCountries` from `banks` module.
 * @see useGetCountriesQuery
 */
export async function getProcessableCountries(
  options?: AxiosRequestConfig
): Promise<Country["id"][]> {
  try {
    const response = await axios.get<Country["id"][]>(
      `/banks/countries/processable`,
      options
    );

    const { data = [] } = response;

    return data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}
