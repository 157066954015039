import { createTheme } from "@material-ui/core/styles";

import { breakpoints } from "./breakpoints";
import { overrides } from "./overrides";
import { props } from "./props";
import { shadows } from "./shadows";
import { typography } from "./typography";
import { darkVariant as variants } from "./variants";
import { zIndex } from "./zIndex";

/**
 * @deprecated Do not use it in the new code base.
 * @todo Remove it with material-ui library
 */
export const theme = createTheme({
  spacing: 4,
  breakpoints: breakpoints,
  overrides: overrides,
  props: props,
  typography: typography,
  shadows: shadows,
  body: variants.body,
  header: variants.header,
  palette: variants.palette,
  sidebar: variants.sidebar,
  zIndex: zIndex,
});
