import { SelectCustomer } from "@volt_developers/components";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { useGlobalFilter } from "hooks/useGlobalFilter";
import { useOnScreen } from "hooks/useOnScreen";
import { isElementInFourthQuartile } from "utils/isElementInFourthQuartile";

import { TEST_IDS } from "../../../constants/TestIds";

import type { MouseEvent } from "react";

import type {
  SearchResultCustomer,
  SearchResults,
} from "./useGlobalFilterSearch";

export interface GlobalFilterSearchListProps {
  handleLoadMore: VoidFunction;
  handleSelectCustomer: (selectedCustomer: SearchResultCustomer) => void;
  hasMore: boolean;
  isLoading: boolean;
  onFavouriteClick: (
    isFavourite: boolean,
    id: string
  ) => (event: MouseEvent<HTMLDivElement>) => void;
  searchResults: SearchResults;
}

export function GlobalFilterSearchList({
  hasMore,
  isLoading,
  searchResults,
  handleSelectCustomer,
  handleLoadMore,
  onFavouriteClick,
}: GlobalFilterSearchListProps) {
  const { t } = useTranslation("common");
  const elementRef = useRef(null);
  const { globallyFilteredCustomer } = useGlobalFilter();

  const isElementVisible = useOnScreen(elementRef, searchResults.length);

  useEffect(() => {
    if (isElementVisible) {
      handleLoadMore();
    }
  }, [handleLoadMore, isElementVisible]);

  if (!Boolean(searchResults.length) || isLoading) {
    return SKELETON_LIST;
  }

  return (
    <SelectCustomer.Menu data-testid={TEST_IDS.globalFilterSearchList}>
      {searchResults.map(({ id, name, descendantsCount, isFavourite }, idx) => (
        <SelectCustomer.Item
          key={TEST_IDS.getGlobalFilterSearchItem(id)}
          customer={name}
          data-testid={TEST_IDS.getGlobalFilterSearchItem(id)}
          isDisabled={globallyFilteredCustomer?.id === id}
          isFavorite={isFavourite}
          // TODO: Revert in https://supercharged.atlassian.net/browse/FBI-329
          isFavoriteVisible={false}
          id={
            Boolean(descendantsCount)
              ? t(COMMON_TRANSLATION_KEYS.accounts, {
                  count: descendantsCount,
                })
              : id
          }
          ref={
            isElementInFourthQuartile(idx + 1, searchResults.length)
              ? elementRef
              : undefined
          }
          onClick={() =>
            handleSelectCustomer({
              id,
              name,
              descendantsCount,
              isFavourite,
            })
          }
          onIconClick={onFavouriteClick(isFavourite, id)}
        />
      ))}
      {hasMore && <SelectCustomer.ItemSkeleton />}
    </SelectCustomer.Menu>
  );
}

export const SKELETON_LIST_LENGTH = 4;
const SKELETON_LIST = (
  <div data-testid={TEST_IDS.globalFilterSearchSkeletonList}>
    {Array.from({ length: SKELETON_LIST_LENGTH }, (_, idx) => (
      <SelectCustomer.ItemSkeleton key={idx} />
    ))}
  </div>
);
