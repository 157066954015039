export interface UseApproveRefundConfirmModalParams {
  onClose: () => void;
  onSuccess: (scaToken: string) => Promise<void>;
}

export function useApproveRefundConfirmModal({
  onSuccess,
  onClose,
}: UseApproveRefundConfirmModalParams) {
  const handleSubmit = async (scaToken?: string) => {
    // scaToken can be undefined if error occurred during SCA process and whose error is handled in SCA
    if (!scaToken) {
      return;
    }

    await onSuccess(scaToken);
    onClose();
  };

  return { handleSubmit };
}
