import { prepareCommonFiltersForApi } from "modules/common/utils/prepareCommonFiltersForApi";

import type { RefundsFilters } from "../../constants/RefundsFilters";

export function prepareFiltersForApi(filters?: Partial<RefundsFilters>) {
  if (!filters) {
    return {};
  }

  const { currency, amountFrom, amountTo, ...commonFilters } = filters;

  const param = prepareCommonFiltersForApi(commonFilters);

  if (currency) {
    param["currency"] = currency;
  }

  if (amountFrom) {
    param["amount[gte]"] = amountFrom;
  }

  if (amountTo) {
    param["amount[lte]"] = amountTo;
  }

  return param;
}
