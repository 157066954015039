import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useInvalidateRefreshTokenMutation } from "../api/Auth";
import { logout, selectRefreshToken } from "../store/auth.slice";

const Logout = () => {
  const dispatch = useDispatch();

  const [invalidateRefreshToken] = useInvalidateRefreshTokenMutation();
  const refreshToken = useSelector(selectRefreshToken);

  useEffect(() => {
    if (refreshToken) {
      invalidateRefreshToken({ refreshToken });
    }

    dispatch(logout());
  }, [dispatch, refreshToken, invalidateRefreshToken]);

  return null;
};

export default Logout;
