import { push } from "connected-react-router";
import { put, takeEvery, takeLatest } from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import { setError, setSuccess, startLoading } from "store/appSlice";

import {
  getCustomerPreferencesBranding,
  postCustomerLogo,
  putCustomerPreferencesBranding,
} from "../api";
import { PAGE_URLS } from "../constants/PageUrls";
import {
  deleteCustomerLogo,
  fetchCustomerPreferencesBranding,
  operationPaths,
  setCustomerPreferencesBranding,
  updateCustomerLogo,
  updateCustomerPreferencesBranding,
} from "./slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { CustomerPreferences } from "../models/CheckoutBranding";
import type { CustomerLogoType } from "../models/CustomerLogo";

function* fetchCustomerPreferencesBrandingSaga({
  payload,
}: PayloadAction<ObjectWithId>) {
  const { id } = payload ?? {};

  yield put(startLoading(operationPaths.brandingPreferencesFetch));

  try {
    const data: {
      active: true;
      preferences: Partial<CustomerPreferences>;
    } = yield callApi(getCustomerPreferencesBranding, id);

    if (Array.isArray(data.preferences.colors)) {
      data.preferences.colors = {
        primary: "",
        primaryContrast: "",
        secondary: "",
        secondaryContrast: "",
        sidebar: "",
        sidebarContrast: "",
        link: "",
      };
    }

    yield put(setCustomerPreferencesBranding(data));
    yield put(setSuccess(operationPaths.brandingPreferencesFetch));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPaths.brandingPreferencesFetch,
        error,
      })
    );
  }
}

function* updateCustomerPreferencesBrandingSaga({
  payload,
}: PayloadAction<{
  data: CustomerPreferences;
  id: UUID;
}>) {
  const { id, data } = payload ?? {};

  yield put(startLoading(operationPaths.brandingPreferencesUpdate));

  try {
    const customerPreferences: CustomerPreferences = yield callApi(
      putCustomerPreferencesBranding,
      id,
      data
    );

    yield put(setCustomerPreferencesBranding(customerPreferences));
    yield put(setSuccess(operationPaths.brandingPreferencesUpdate));
    yield put(push(PAGE_URLS.details));
  } catch (error) {
    yield put(
      setError({
        path: operationPaths.brandingPreferencesUpdate,
        error,
      })
    );
  }
}

function* updateCustomerLogoSaga({
  payload,
}: PayloadAction<{
  file: File;
  id: UUID;
  logoType: CustomerLogoType;
}>) {
  const { id, file, logoType } = payload ?? {};

  yield put(startLoading(operationPaths.logoUpdate(logoType)));

  try {
    const customerPreferences: CustomerPreferences = yield callApi(
      postCustomerLogo,
      id,
      {
        logoType,
        file,
      }
    );

    yield put(setCustomerPreferencesBranding(customerPreferences));
    yield put(setSuccess(operationPaths.logoUpdate(logoType)));
  } catch (error) {
    yield put(
      setError({
        path: operationPaths.logoUpdate(logoType),
        error,
      })
    );
  }
}

function* deleteCustomerLogoSaga({
  payload,
}: PayloadAction<{
  id: UUID;
  logoType: CustomerLogoType;
}>) {
  const { id, logoType } = payload ?? {};

  yield put(startLoading(operationPaths.logoDelete(logoType)));
  try {
    const customerPreferences: CustomerPreferences = yield callApi(
      deleteCustomerLogo,
      id,
      { logoType }
    );

    yield put(setCustomerPreferencesBranding(customerPreferences));
    yield put(setSuccess(operationPaths.logoDelete(logoType)));
  } catch (error) {
    yield put(
      setError({
        path: operationPaths.logoDelete(logoType),
        error,
      })
    );
  }
}

export function* rootSaga() {
  yield takeEvery(
    fetchCustomerPreferencesBranding.type,
    fetchCustomerPreferencesBrandingSaga
  );
  yield takeLatest(
    updateCustomerPreferencesBranding.type,
    updateCustomerPreferencesBrandingSaga
  );
  yield takeEvery(updateCustomerLogo.type, updateCustomerLogoSaga);
  yield takeEvery(deleteCustomerLogo.type, deleteCustomerLogoSaga);
}
