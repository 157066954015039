import { TEST_IDS } from "./TestIds";
import { TRANSLATION_KEYS } from "./TranslationKeys";

import type { ToastProps } from "components/toasts/MessageToast";

export const REFUNDS_TOASTS_IDS = {
  usernamesFailure: "user-names-error-toast-id",
  approveRefundSuccess: "approve-refund-success-id",
  approveRefundsSuccess: "approve-refunds-success-id",
  rejectRefundSuccess: "reject-refund-success-id",
  refundSuccessToast: TEST_IDS.refundSuccessToast,
  refundFailureToast: TEST_IDS.refundFailureToast,
} as const;

export const REFUNDS_TOASTS = {
  usernamesFailure: {
    bodyContent: TRANSLATION_KEYS.cannotLoadUsernames,
    variant: "failure",
    config: {
      toastId: REFUNDS_TOASTS_IDS.usernamesFailure,
    },
  },
  bulkApproveSuccess: (bodyContent: string): ToastProps => ({
    bodyContent,
    variant: "success",
    config: {
      toastId: REFUNDS_TOASTS_IDS.approveRefundsSuccess,
    },
  }),
  approveSuccess: {
    bodyContent: TRANSLATION_KEYS.refundHasBeenApproved,
    variant: "success",
    config: {
      toastId: REFUNDS_TOASTS_IDS.approveRefundSuccess,
    },
  },
  rejectSuccess: {
    bodyContent: TRANSLATION_KEYS.refundHasBeenRejected,
    variant: "success",
    config: {
      toastId: REFUNDS_TOASTS_IDS.rejectRefundSuccess,
    },
  },
  refundSuccess: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.weHaveAcceptedYourRefundRequest,
    config: {
      toastId: REFUNDS_TOASTS_IDS.refundSuccessToast,
    },
  },
  refundFailure: {
    variant: "failure",
    bodyContent: TRANSLATION_KEYS.sorrySomethingWentWrong,
    config: {
      toastId: REFUNDS_TOASTS_IDS.refundFailureToast,
    },
  },
} as const;
