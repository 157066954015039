import { BankAccount as BankAccountIcon } from "@volt_developers/react-ui-components";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_ACCOUNT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const AccountList = lazyRetry(() => import("../pages/List"), "BankAccountView");
const AccountPage = lazyRetry(
  () => import("../pages/account"),
  "BankAccountPage"
);
const AccountEdit = lazyRetry(
  () => import("../pages/Edit"),
  "BankAccountManage"
);
const AccountCreate = lazyRetry(
  () => import("../pages/Create"),
  "BankAccountManage"
);

export const routes: Route = {
  title: [COMMON_TRANSLATION_KEYS.bankAccounts, COMMON_TRANSLATION_KEYS.list],
  path: "/configuration/accounts",
  component: AccountList,
  icon: BankAccountIcon,
  newTheme: true,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_ACCOUNT,
  },
  children: [
    {
      title: [
        COMMON_TRANSLATION_KEYS.bankAccounts,
        COMMON_TRANSLATION_KEYS.new,
      ],
      path: "/configuration/accounts/create",
      component: AccountCreate,
      newTheme: true,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_ACCOUNT,
      },
    },
    {
      path: PAGE_URLS.accountDetails,
      component: AccountPage,
      newTheme: true,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_ACCOUNT,
      },
      hasNestedSwitch: true,
    },
    {
      title: [
        COMMON_TRANSLATION_KEYS.bankAccounts,
        COMMON_TRANSLATION_KEYS.edit,
      ],
      path: "/configuration/accounts/:id/edit",
      component: AccountEdit,
      newTheme: true,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_ACCOUNT,
      },
    },
  ],
};
