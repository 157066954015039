import { put, takeLatest } from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import { setError, setSuccess, startLoading } from "store/appSlice";

import * as AccountsApi from "../api/Accounts";

import {
  accountsOperationPaths,
  fetchAccounts,
  setList,
} from ".";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { List } from "api/helpers/pagination";

import type {
  FetchAccountPayload} from ".";
import type { Account } from "../models/Account";

function* fetchAccountsSaga({ payload }: PayloadAction<FetchAccountPayload>) {
  const { customerId, environment } = payload ?? {};
  const { listing: operationPath } = accountsOperationPaths();

  yield put(startLoading(operationPath));
  try {
    const data: List<Account> = yield callApi(AccountsApi.getAccounts, {
      customerId,
      environment,
    });

    yield put(setList(data));
    yield put(setSuccess(operationPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

export function* rootSaga() {
  yield takeLatest(fetchAccounts.type, fetchAccountsSaga);
}
