/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  branding: "Branding",
  checkoutBranding: "Checkout branding",
  checkoutBrandingInactiveMessage:
    "Branding is not active, checkout uses default Volt branding",
  colors: "Colors",
  companyLogo: "Company logo",
  companyLogoRecommendedRatio: "Recommended aspect ratio {{ratio}}",
  companyLogoRecommendedRatioOtherwise:
    "Recommended aspect ratio {{ratio}}, otherwise it might not display properly",
  companyLogoRequirements: "Please provide images, max. size: {{maxSizeMB}}MB",
  configureCustomBranding: "Configure custom branding",
  customerName: "Customer name",
  defaultColor: "{{color}} (default)",
  desktop: "Desktop",
  edit: "Edit",
  information: "Information",
  insideQR: "Inside QR code",
  linkColor: "Link color",
  linkTextColor: "Link text color",
  mobile: "Mobile",
  primaryColor: "Primary color",
  primaryTextColor: "Primary text color",
  save: "Save",
  secondaryColor: "Secondary color",
  secondaryTextColor: "Secondary text color",
  selectCustomer: "Select customer",
  sidebarColor: "Sidebar color",
  sidebarTextColor: "Sidebar text color",
  status: "Status",
  tagline: "Tagline",
  toViewCheckoutBrandingSelectCustomer:
    "To view Checkout branding preferences, please select a customer in the select customer menu in the upper left corner.",
  useDefaultVoltBranding: "Use default Volt branding",
};
