import {
  ButtonDeprecated,
  ToastDeprecated as VoltToast,
} from "@volt_developers/components";
import { toast } from "react-toastify";

import { useToast } from "hooks/toasts/useToast";

import type { ReactNode } from "react";
import type { ToastOptions } from "react-toastify";

import type { ToastVariant } from "hooks/toasts/useToast";

export type ToastProps = DoubleButtonToastProps & {
  config?: ToastOptions;
};

export interface DoubleButtonToastProps {
  bodyContent: ReactNode;
  buttonTextPrimary: ReactNode;
  buttonTextSecondary: ReactNode;
  handleClickPrimary: () => void;
  handleClickSecondary: () => void;
  variant: ToastVariant;
  toastProps?: ToastOptions;
}

export function DoubleButtonToast({
  bodyContent,
  buttonTextSecondary,
  handleClickPrimary,
  handleClickSecondary,
  buttonTextPrimary,
  toastProps,
  variant,
}: DoubleButtonToastProps) {
  const { buttonVariant, buttonColor, iconComponent, buttonGhostColor } =
    useToast(variant);

  return (
    <VoltToast
      data-testid={(toastProps?.toastId as string) ?? "toast"}
      variant={variant}
    >
      <VoltToast.RootWrapper>
        <VoltToast.IconWrapper>{iconComponent}</VoltToast.IconWrapper>
        <VoltToast.ContentWrapper>
          {bodyContent}
          <VoltToast.ContentFooter>
            <ButtonDeprecated
              color={buttonGhostColor}
              onClick={handleClickSecondary}
            >
              {buttonTextSecondary}
            </ButtonDeprecated>
            <ButtonDeprecated
              color={buttonColor}
              variant={buttonVariant}
              onClick={handleClickPrimary}
            >
              {buttonTextPrimary}
            </ButtonDeprecated>
          </VoltToast.ContentFooter>
        </VoltToast.ContentWrapper>
      </VoltToast.RootWrapper>
    </VoltToast>
  );
}

export const showDoubleButtonToast = ({
  config,
  ...propsForToast
}: ToastProps) => toast(<DoubleButtonToast {...propsForToast} />, config);
