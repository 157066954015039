import axios from "axios";

import { getErrorDetails } from "api/helpers/getErrorDetails";

import type { AxiosRequestConfig } from "axios";

import type { UUID } from "utils";

import type { CustomerPreferences } from "../models/CheckoutBranding";
import type {
  CustomerLogoFile,
  CustomerLogoType,
} from "../models/CustomerLogo";

export async function getCustomerPreferencesBranding(
  id: UUID,
  options?: AxiosRequestConfig
) {
  try {
    const response = await axios({
      ...options,
      method: "GET",
      url: `/customers/${id}/preferences/branding`,
    });
    const { data = {} } = response;

    return data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function putCustomerPreferencesBranding(
  id: UUID,
  customerPreferences: CustomerPreferences,
  options?: AxiosRequestConfig
) {
  const data = { ...customerPreferences };

  data.preferences = { ...customerPreferences.preferences };

  try {
    await axios({
      ...options,
      method: "PUT",
      url: `/customers/${id}/preferences/branding`,
      data,
    });

    return await getCustomerPreferencesBranding(id, options);
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function postCustomerLogo(
  id: UUID,
  {
    logoType,
    file,
  }: {
    file: CustomerLogoFile;
    logoType: CustomerLogoType;
  },
  options?: AxiosRequestConfig
) {
  try {
    await axios({
      ...options,
      method: "POST",
      url: `/customers/${id}/preferences/branding/logo/${logoType}`,
      data: file,
      headers: {
        ...options?.headers,
        "Content-Type": file.type,
      },
    });

    return await getCustomerPreferencesBranding(id, options);
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function deleteCustomerLogo(
  id: UUID,
  {
    logoType,
  }: {
    logoType: CustomerLogoType;
  },
  options?: AxiosRequestConfig
) {
  try {
    await axios({
      ...options,
      method: "DELETE",
      url: `/customers/${id}/preferences/branding/logo/${logoType}`,
    });

    return await getCustomerPreferencesBranding(id, options);
  } catch (error) {
    throw getErrorDetails(error);
  }
}
