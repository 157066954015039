import { PROPERTY_EXTRA_FIELDS } from "models/NotificationSettings";
import {
  ACTION_APPROVE,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_DETAILS,
  ACTION_GLOBAL_FILTER,
  ACTION_LIST,
  ACTION_TEST,
  ACTION_UPDATE,
  ACTIONS_CRU,
  ACTIONS_READ,
  ACTIONS_WRITE,
} from "models/role/action";
import {
  PROPERTY_ID,
  SUBJECT_ACCOUNT,
  SUBJECT_BANK,
  SUBJECT_CASH_MANAGEMENT,
  SUBJECT_CASH_MANAGEMENT_ACCOUNT,
  SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
  SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
  SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION,
  SUBJECT_CHECKOUT,
  SUBJECT_CIRCUIT_BREAKER,
  SUBJECT_CLIENT,
  SUBJECT_CLIENT_VERIFY_NOTIFICATIONS,
  SUBJECT_CLOSED_LOOP_PAYOUTS,
  SUBJECT_CREDIT_NOTIFICATION_PREFERENCES,
  SUBJECT_CUSTOMER,
  SUBJECT_CUSTOMER_COUNTRIES,
  SUBJECT_CUSTOMER_PAYMENTS_PROVIDERS,
  SUBJECT_CUSTOMER_PRODUCT_FLAGS,
  SUBJECT_CUSTOMER_PUBLIC_KEYS,
  SUBJECT_DYNAMIC_BENEFICIARY,
  SUBJECT_NOTIFICATION_SETTINGS,
  SUBJECT_OPEN_LOOP_PAYOUTS,
  SUBJECT_PAY_BY_LINK,
  SUBJECT_PAYMENT,
  SUBJECT_PAYMENT_EXTERNAL_LINKS,
  SUBJECT_PAYMENT_NOTIFICATION,
  SUBJECT_PAYOUTS_NOTIFICATIONS,
  SUBJECT_REFUND,
  SUBJECT_USER,
  SUBJECT_VERIFICATION,
  SUBJECT_VERIFY,
  SUBJECT_WORKFLOWS,
} from "models/role/subject";
import {
  PROPERTY_ACTIVE as CLIENT_PROPERTY_ACTIVE,
  PROPERTY_REDIRECT_SIGNATURE,
  PROPERTY_SECRET,
} from "modules/applications/models/Client";
import {
  PROPERTY_PAYOUT_NOTIFICATIONS_SECRET,
  PROPERTY_PAYOUT_NOTIFICATIONS_STATUS,
  PROPERTY_PRODUCTION_SETTLEMENT_BALANCE,
  PROPERTY_PRODUCTION_SETTLEMENT_BATCHES,
  PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
  PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
  PROPERTY_SANDBOX_SETTLEMENT_BALANCE,
  PROPERTY_SANDBOX_SETTLEMENT_BATCHES,
  PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
  PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
} from "modules/connect";
import {
  PROPERTY_PRODUCTION_ACCOUNT_BALANCE,
  PROPERTY_SANDBOX_ACCOUNT_BALANCE,
} from "modules/connect/models/CashManagementAccount";
import {
  PROPERTY_ACTIVE as CUSTOMER_PROPERTY_ACTIVE,
  PROPERTY_ADDRESS,
  PROPERTY_CIRCUIT_BREAKER_ENABLED,
  PROPERTY_CREDENTIALS,
  PROPERTY_MERCHANT_CATEGORY_CODE,
  PROPERTY_PAYMENT_PREFERENCES,
  PROPERTY_PRODUCTION_CONNECT,
  PROPERTY_PRODUCTION_GATEWAY,
  PROPERTY_PRODUCTION_GATEWAY_AUSTRALIA,
  PROPERTY_PRODUCTION_GATEWAY_US,
  PROPERTY_PRODUCTION_MATCHMETER,
  PROPERTY_PRODUCTION_OPEN_LOOP_PAYOUTS,
  PROPERTY_PRODUCTION_PAY_BY_LINK,
  PROPERTY_PRODUCTION_PAYOUTS,
  PROPERTY_PRODUCTION_REFUND,
  PROPERTY_PRODUCTION_SAME_DAY_ACH,
  PROPERTY_PRODUCTION_SIGNAL,
  PROPERTY_PRODUCTION_TRANSFORMER,
  PROPERTY_PRODUCTION_VERIFY,
  PROPERTY_SANDBOX_CONNECT,
  PROPERTY_SANDBOX_GATEWAY,
  PROPERTY_SANDBOX_GATEWAY_AUSTRALIA,
  PROPERTY_SANDBOX_GATEWAY_US,
  PROPERTY_SANDBOX_MATCHMETER,
  PROPERTY_SANDBOX_OPEN_LOOP_PAYOUTS,
  PROPERTY_SANDBOX_PAY_BY_LINK,
  PROPERTY_SANDBOX_PAYOUTS,
  PROPERTY_SANDBOX_REFUND,
  PROPERTY_SANDBOX_SAME_DAY_ACH,
  PROPERTY_SANDBOX_SIGNAL,
  PROPERTY_SANDBOX_TRANSFORMER,
  PROPERTY_SANDBOX_VERIFY,
  PROPERTY_TERMINATE_AT,
  PROPERTY_TYPE,
} from "modules/customers/models/Customer";
import {
  PROPERTY_BANK_RESPONSES,
  PROPERTY_BENEFICIARY_ACCOUNT_IDENTIFICATIONS,
  PROPERTY_PROVIDER,
  PROPERTY_SENDER,
  PROPERTY_SENDER_ACCOUNT_NAME,
  PROPERTY_SENDER_CHECKOUT,
  PROPERTY_SENDER_PAYMENT_PROVIDER,
  PROPERTY_SENDER_VOLT_CONNECT,
} from "modules/payments/models/Payment";
import {
  PROPERTY_ACTIVE as USER_PROPERTY_ACTIVE,
  PROPERTY_VOLT_ADMIN,
} from "modules/user-management/models/User";
import {
  PROPERTY_COUNTRIES,
  PROPERTY_DATA_SCOPE,
} from "modules/verify/models/verify";

import type { PERMISSION_GROUP_LIST_ALL } from "modules/auth/constants/permissionGroups";
import type { UUID } from "utils";

import type { AbilityInterface } from "./ability";

type permissionApplyFunction = (args: {
  ability: AbilityInterface;
  customerId: UUID | null;
  hierarchyIds: UUID[];
  userId: UUID;
}) => void;

export const applyPermissions: Record<
  PERMISSION_GROUP_LIST_ALL,
  permissionApplyFunction
> = {
  PERMISSION_GROUP_CUSTOMER_VIEW: ({ ability, customerId }) => {
    ability.can(ACTION_LIST, SUBJECT_CUSTOMER);
    ability.can(ACTION_DETAILS, SUBJECT_CUSTOMER, [
      PROPERTY_ID,
      PROPERTY_ADDRESS,
    ]);

    if (customerId) {
      ability.cannot(ACTION_UPDATE, SUBJECT_CUSTOMER, [
        CUSTOMER_PROPERTY_ACTIVE,
      ]);
    }
  },
  PERMISSION_VOLT_GROUP_CUSTOMER_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_CUSTOMER_VIEW(args);
  },
  PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE: ({ ability }) => {
    ability.can([ACTION_CREATE, ACTION_UPDATE], SUBJECT_CHECKOUT);
  },
  PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_MANAGE(
      args
    );
  },
  PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_CHECKOUT);
  },
  PERMISSION_VOLT_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_CUSTOMER_BRANDING_PREFERENCES_VIEW(args);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_INTERNAL: () => void 0,
  PERMISSION_VOLT_GROUP_CUSTOMER_MANAGE: ({ ability }) => {
    ability.can([ACTION_UPDATE], SUBJECT_CUSTOMER, [
      PROPERTY_ID,
      CUSTOMER_PROPERTY_ACTIVE,
      PROPERTY_MERCHANT_CATEGORY_CODE,
      PROPERTY_ADDRESS,
      PROPERTY_TYPE,
    ]);
    ability.can([ACTION_CREATE, ACTION_UPDATE], SUBJECT_CUSTOMER_COUNTRIES);
    ability.can(ACTION_UPDATE, SUBJECT_CUSTOMER, [PROPERTY_TERMINATE_AT]);
  },
  PERMISSION_VOLT_GROUP_CUSTOMER_CREATION: ({ ability }) => {
    ability.can(ACTION_CREATE, SUBJECT_CUSTOMER, [PROPERTY_ID]);
  },
  PERMISSION_GROUP_CUSTOMER_CREDENTIALS_MANAGE: ({ ability }) => {
    ability.can(ACTION_CREATE, SUBJECT_CUSTOMER, PROPERTY_CREDENTIALS);
    ability.can([ACTION_CREATE, ACTION_UPDATE], SUBJECT_CUSTOMER_PUBLIC_KEYS);
  },
  PERMISSION_VOLT_GROUP_CUSTOMER_CREDENTIALS_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_CUSTOMER_CREDENTIALS_MANAGE(args);
  },
  PERMISSION_GROUP_PRODUCT_ACTIVATION_MANAGE: ({ ability }) => {
    ability.can(ACTION_UPDATE, SUBJECT_CUSTOMER_PRODUCT_FLAGS, [
      PROPERTY_SANDBOX_VERIFY,
      PROPERTY_PRODUCTION_VERIFY,
    ]);
  },
  PERMISSION_VOLT_GROUP_PRODUCT_ACTIVATION_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_PRODUCT_ACTIVATION_MANAGE(args);
    args.ability.can(ACTION_UPDATE, SUBJECT_CUSTOMER, [
      PROPERTY_CIRCUIT_BREAKER_ENABLED,
    ]);
    args.ability.can(ACTION_UPDATE, SUBJECT_CUSTOMER_PRODUCT_FLAGS, [
      PROPERTY_SANDBOX_TRANSFORMER,
      PROPERTY_PRODUCTION_TRANSFORMER,
      PROPERTY_SANDBOX_REFUND,
      PROPERTY_PRODUCTION_REFUND,
      PROPERTY_PRODUCTION_PAY_BY_LINK,
      PROPERTY_SANDBOX_PAY_BY_LINK,
      PROPERTY_PRODUCTION_PAYOUTS,
      PROPERTY_PRODUCTION_OPEN_LOOP_PAYOUTS,
      PROPERTY_SANDBOX_PAYOUTS,
      PROPERTY_SANDBOX_OPEN_LOOP_PAYOUTS,
      PROPERTY_PRODUCTION_GATEWAY,
      PROPERTY_SANDBOX_GATEWAY,
      PROPERTY_SANDBOX_CONNECT,
      PROPERTY_PRODUCTION_CONNECT,
      PROPERTY_SANDBOX_MATCHMETER,
      PROPERTY_PRODUCTION_MATCHMETER,
      PROPERTY_SANDBOX_GATEWAY_US,
      PROPERTY_SANDBOX_GATEWAY_AUSTRALIA,
      PROPERTY_PRODUCTION_GATEWAY_US,
      PROPERTY_PRODUCTION_GATEWAY_AUSTRALIA,
      PROPERTY_SANDBOX_SAME_DAY_ACH,
      PROPERTY_PRODUCTION_SAME_DAY_ACH,
      PROPERTY_PRODUCTION_SIGNAL,
      PROPERTY_SANDBOX_SIGNAL,
    ]);
  },
  PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_CUSTOMER_PAYMENTS_PROVIDERS);
    ability.can(ACTIONS_READ, SUBJECT_CUSTOMER, [PROPERTY_PAYMENT_PREFERENCES]);
  },
  PERMISSION_VOLT_GROUP_PAYMENT_PREFERENCES_UPDATE: ({ ability }) => {
    ability.can(ACTION_UPDATE, SUBJECT_CUSTOMER_PAYMENTS_PROVIDERS);

    ability.can([ACTION_UPDATE], SUBJECT_CUSTOMER, [
      PROPERTY_PAYMENT_PREFERENCES,
    ]);
  },
  PERMISSION_VOLT_GROUP_BANKS_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_BANK, [PROPERTY_ID]);
  },
  PERMISSION_GROUP_BANKS_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_BANKS_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_BANK, [PROPERTY_ID]);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP: ({
    ability,
  }) => {
    ability.can(ACTIONS_CRU, SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP: (
    args
  ) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_SETUP(
      args
    );
    args.ability.can(
      ACTIONS_CRU,
      SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION
    );
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW: ({
    ability,
  }) => {
    ability.can(ACTIONS_READ, SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW: (
    args
  ) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION_VIEW(
      args
    );
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_CASH_MANAGEMENT);
    ability.can(ACTIONS_READ, SUBJECT_CASH_MANAGEMENT_ACCOUNT);
    ability.cannot(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_ACCOUNT_BALANCE,
      PROPERTY_SANDBOX_ACCOUNT_BALANCE,
    ]);
    ability.cannot(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
    ]);
    ability.cannot(ACTIONS_CRU, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_BALANCE,
      PROPERTY_SANDBOX_SETTLEMENT_BALANCE,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
    ]);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_SETTINGS_VIEW(args);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTINGS_CONFIGURE: ({ ability }) => {
    ability.can(ACTIONS_CRU, SUBJECT_CASH_MANAGEMENT_ACCOUNT);
    ability.can(ACTIONS_CRU, SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP);
    ability.cannot(ACTIONS_WRITE, SUBJECT_CASH_MANAGEMENT, [
      PROPERTY_PRODUCTION_SETTLEMENT_BATCHES,
    ]);
    ability.cannot(ACTIONS_CRU, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_ACCOUNT_BALANCE,
      PROPERTY_SANDBOX_ACCOUNT_BALANCE,
      PROPERTY_PRODUCTION_SETTLEMENT_BALANCE,
      PROPERTY_SANDBOX_SETTLEMENT_BALANCE,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
    ]);
  },
  PERMISSION_GROUP_MANAGE_SETTLEMENT_BATCHES: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_CASH_MANAGEMENT, [
      PROPERTY_SANDBOX_SETTLEMENT_BATCHES,
      PROPERTY_PRODUCTION_SETTLEMENT_BATCHES,
    ]);
  },
  PERMISSION_VOLT_GROUP_MANAGE_SETTLEMENT_BATCHES: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_CASH_MANAGEMENT, [
      PROPERTY_SANDBOX_SETTLEMENT_BATCHES,
    ]);

    ability.cannot(ACTIONS_WRITE, SUBJECT_CASH_MANAGEMENT, [
      PROPERTY_PRODUCTION_SETTLEMENT_BATCHES,
    ]);
  },
  PERMISSION_GROUP_APPLICATIONS_MANAGE: ({ ability }) => {
    ability.can(ACTION_CREATE, SUBJECT_CLIENT, [PROPERTY_ID]);
    ability.can([ACTION_UPDATE, ACTION_DELETE], SUBJECT_CLIENT, [PROPERTY_ID]);
    ability.can([ACTION_DETAILS], SUBJECT_CLIENT, [PROPERTY_ID]);
    ability.can(ACTION_UPDATE, SUBJECT_CLIENT, [
      CLIENT_PROPERTY_ACTIVE,
      PROPERTY_SECRET,
      PROPERTY_REDIRECT_SIGNATURE,
    ]);
    ability.can(ACTION_TEST, SUBJECT_PAYMENT_NOTIFICATION);
    ability.can(ACTIONS_CRU, SUBJECT_NOTIFICATION_SETTINGS);
    ability.cannot(ACTION_UPDATE, SUBJECT_NOTIFICATION_SETTINGS, [
      PROPERTY_EXTRA_FIELDS,
    ]);
  },
  PERMISSION_VOLT_GROUP_APPLICATIONS_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_APPLICATIONS_MANAGE(args);
    const { ability } = args;

    ability.can(
      ACTION_UPDATE,
      SUBJECT_NOTIFICATION_SETTINGS,
      PROPERTY_EXTRA_FIELDS
    );
    ability.can([ACTION_DETAILS], SUBJECT_NOTIFICATION_SETTINGS, [
      PROPERTY_EXTRA_FIELDS,
    ]);
  },
  PERMISSION_GROUP_APPLICATIONS_VIEW: ({ ability }) => {
    ability.can([ACTION_LIST], SUBJECT_CLIENT);
    ability.can([ACTION_DETAILS], SUBJECT_CLIENT, [PROPERTY_ID]);
    ability.can([ACTION_DETAILS], SUBJECT_NOTIFICATION_SETTINGS, [
      PROPERTY_EXTRA_FIELDS,
    ]);
  },
  PERMISSION_VOLT_GROUP_APPLICATIONS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_APPLICATIONS_VIEW(args);
  },
  PERMISSION_GROUP_CIRCUIT_BREAKER_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_CRU, SUBJECT_CIRCUIT_BREAKER);
  },
  PERMISSION_GROUP_CIRCUIT_BREAKER_VIEW: ({ ability }) => {
    ability.can([ACTION_DETAILS, ACTION_LIST], SUBJECT_CIRCUIT_BREAKER);
  },
  PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_CIRCUIT_BREAKER_VIEW(args);
    args.ability.can(ACTION_GLOBAL_FILTER, SUBJECT_CIRCUIT_BREAKER);
  },
  PERMISSION_VOLT_GROUP_CIRCUIT_BREAKER_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_CIRCUIT_BREAKER_MANAGE(args);
    args.ability.can(ACTION_GLOBAL_FILTER, SUBJECT_CIRCUIT_BREAKER);
  },
  PERMISSION_GROUP_BANK_ACCOUNTS_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_ACCOUNT, PROPERTY_ID);
  },
  PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_BANK_ACCOUNTS_VIEW(args);
  },
  PERMISSION_GROUP_PAYMENTS_VIEW: ({ ability }) => {
    ability.can(ACTION_LIST, SUBJECT_PAYMENT);
    ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, PROPERTY_ID);
    ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, PROPERTY_SENDER);
  },
  PERMISSION_VOLT_GROUP_PAYMENTS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_PAYMENTS_VIEW(args);
    args.ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, [
      PROPERTY_BANK_RESPONSES,
      PROPERTY_PROVIDER,
    ]);
    args.ability.can(ACTION_DETAILS, SUBJECT_PAYMENT_EXTERNAL_LINKS);
  },
  PERMISSION_GROUP_BENEFICIARY_VIEW: ({ ability }) => {
    ability.can(
      ACTION_DETAILS,
      SUBJECT_PAYMENT,
      PROPERTY_BENEFICIARY_ACCOUNT_IDENTIFICATIONS
    );
  },
  PERMISSION_VOLT_GROUP_BENEFICIARY_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_BENEFICIARY_VIEW(args);
  },
  PERMISSION_GROUP_BANK_ACCOUNTS_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_WORKFLOWS);
    ability.can(ACTIONS_READ, SUBJECT_ACCOUNT, [PROPERTY_ID]);
    ability.can(ACTIONS_WRITE, SUBJECT_ACCOUNT, [PROPERTY_ID]);
  },
  PERMISSION_VOLT_GROUP_BANK_ACCOUNTS_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_WORKFLOWS);
    ability.can(ACTIONS_READ, SUBJECT_ACCOUNT, [PROPERTY_ID]);
    ability.can(ACTIONS_WRITE, SUBJECT_ACCOUNT, [PROPERTY_ID]);
    ability.can(ACTIONS_WRITE, SUBJECT_DYNAMIC_BENEFICIARY, [PROPERTY_ID]);
  },
  PERMISSION_GROUP_SENSITIVE_DATA_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, PROPERTY_SENDER);
    ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, PROPERTY_SENDER_ACCOUNT_NAME);
    ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, [
      PROPERTY_SENDER_VOLT_CONNECT,
      PROPERTY_SENDER_PAYMENT_PROVIDER,
    ]);
  },
  PERMISSION_VOLT_GROUP_SENSITIVE_DATA_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_SENSITIVE_DATA_VIEW(args);
    args.ability.can(ACTION_DETAILS, SUBJECT_PAYMENT, PROPERTY_SENDER_CHECKOUT);
  },
  PERMISSION_GROUP_USERS_VIEW: ({ ability }) => {
    ability.can(ACTION_LIST, SUBJECT_USER);
    ability.can(ACTION_DETAILS, SUBJECT_USER, PROPERTY_ID);
  },
  PERMISSION_VOLT_GROUP_USERS_VIEW: ({ ability }) => {
    ability.can(ACTION_LIST, SUBJECT_USER);
    ability.can(ACTION_DETAILS, SUBJECT_USER, PROPERTY_ID);
  },
  PERMISSION_GROUP_USERS_MANAGE: ({ ability, userId }) => {
    ability.can(ACTION_CREATE, SUBJECT_USER, PROPERTY_ID);
    ability.can(ACTION_UPDATE, SUBJECT_USER, [
      PROPERTY_ID,
      USER_PROPERTY_ACTIVE,
    ]);
    ability.cannot(ACTION_UPDATE, SUBJECT_USER, USER_PROPERTY_ACTIVE, {
      id: userId,
    });
    ability.can(ACTIONS_READ, SUBJECT_USER, PROPERTY_ID);
  },
  PERMISSION_VOLT_GROUP_USERS_MANAGE: ({ ability, userId }) => {
    ability.can(ACTION_CREATE, SUBJECT_USER, PROPERTY_ID);
    ability.can(ACTION_UPDATE, SUBJECT_USER, [
      PROPERTY_ID,
      USER_PROPERTY_ACTIVE,
    ]);
    ability.cannot(ACTION_UPDATE, SUBJECT_USER, USER_PROPERTY_ACTIVE, {
      id: userId,
    });
    ability.can(ACTIONS_READ, SUBJECT_USER, PROPERTY_ID);
    ability.can(ACTION_CREATE, SUBJECT_USER, PROPERTY_VOLT_ADMIN);
  },
  PERMISSION_GROUP_REFUNDS_AND_PAYBACKS_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_REFUND);
    ability.can(ACTION_LIST, SUBJECT_REFUND);
  },
  PERMISSION_VOLT_GROUP_REFUNDS_AND_PAYBACKS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_REFUNDS_AND_PAYBACKS_VIEW(args);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS: ({ ability }) => {
    ability.can(ACTION_APPROVE, SUBJECT_REFUND);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_REFUNDS: ({ ability }) => {
    ability.cannot(ACTION_APPROVE, SUBJECT_REFUND);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS: ({
    ability,
  }) => {
    ability.can(ACTION_CREATE, SUBJECT_REFUND);
    ability.can(ACTION_UPDATE, SUBJECT_REFUND);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_REFUND_AND_PAYBACKS: ({
    ability,
  }) => {
    // Manage refunds for volt user is only for user-mgmt purposes. Volt cannot manage refunds
    ability.cannot(ACTION_CREATE, SUBJECT_REFUND);
    ability.cannot(ACTION_UPDATE, SUBJECT_REFUND);
  },
  PERMISSION_GROUP_VERIFICATION_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_VERIFICATION, PROPERTY_ID);
  },
  PERMISSION_VOLT_GROUP_VERIFICATION_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_VERIFICATION_VIEW(args);
  },
  PERMISSION_GROUP_AIS_NOTIFICATION_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_CLIENT_VERIFY_NOTIFICATIONS);
  },
  PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_AIS_NOTIFICATION_VIEW(args);
  },
  PERMISSION_GROUP_AIS_NOTIFICATION_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_CLIENT_VERIFY_NOTIFICATIONS);
  },
  PERMISSION_VOLT_GROUP_AIS_NOTIFICATION_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_AIS_NOTIFICATION_MANAGE(args);
  },
  PERMISSION_VOLT_GROUP_AIS_SCOPE_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_CRU, SUBJECT_VERIFY, PROPERTY_DATA_SCOPE);
  },
  PERMISSION_GROUP_AIS_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_VERIFY, PROPERTY_COUNTRIES);
  },
  PERMISSION_VOLT_GROUP_AIS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_AIS_VIEW(args);
  },
  PERMISSION_GROUP_AIS_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_VERIFY, PROPERTY_COUNTRIES);
  },
  PERMISSION_VOLT_GROUP_AIS_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_AIS_MANAGE(args);
  },
  PERMISSION_VOLT_GROUP_PROVIDER_BANK_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_TRAFFIC_RELAY_CONFIG: () => void 0,
  PERMISSION_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_ACCOUNT_BALANCE,
      PROPERTY_SANDBOX_ACCOUNT_BALANCE,
    ]);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_ACCOUNT_BALANCE_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_ACCOUNT_BALANCE,
      PROPERTY_SANDBOX_ACCOUNT_BALANCE,
    ]);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_BALANCE,
      PROPERTY_SANDBOX_SETTLEMENT_BALANCE,
    ]);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_BALANCE_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_BALANCE,
      PROPERTY_SANDBOX_SETTLEMENT_BALANCE,
    ]);
  },
  PERMISSION_GROUP_PAY_BY_LINK_MANAGE: ({ ability }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_PAY_BY_LINK);
  },
  PERMISSION_VOLT_GROUP_PAY_BY_LINK_MANAGE: (args) => {
    applyPermissions.PERMISSION_GROUP_PAY_BY_LINK_MANAGE(args);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW: ({ ability }) => {
    ability.can(ACTIONS_READ, SUBJECT_CLOSED_LOOP_PAYOUTS);
    ability.can(ACTIONS_READ, SUBJECT_OPEN_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW: (args) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUTS_VIEW(args);
  },
  PERMISSION_VOLT_GROUP_LATAM_SETTLEMENTS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_LATAM_REFUNDS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_MANAGE: () => void 0,
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_PAYOUTS_NOTIFICATIONS);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE: ({
    ability,
  }) => {
    ability.can(ACTIONS_WRITE, SUBJECT_PAYOUTS_NOTIFICATIONS, [
      PROPERTY_PAYOUT_NOTIFICATIONS_SECRET,
      PROPERTY_PAYOUT_NOTIFICATIONS_STATUS,
    ]);
    ability.can(ACTION_TEST, SUBJECT_PAYOUTS_NOTIFICATIONS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW: (
    args
  ) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_VIEW(
      args
    );
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE: (
    args
  ) => {
    applyPermissions.PERMISSION_GROUP_CASH_MANAGEMENT_PAYOUT_NOTIFICATION_SETTINGS_MANAGE(
      args
    );
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS: ({ ability }) => {
    ability.can(ACTION_APPROVE, SUBJECT_CLOSED_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_APPROVE_PAYOUTS: ({ ability }) => {
    ability.cannot(ACTION_APPROVE, SUBJECT_CLOSED_LOOP_PAYOUTS);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE: ({ ability }) => {
    ability.can(ACTION_APPROVE, SUBJECT_OPEN_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_APPROVE: ({
    ability,
  }) => {
    ability.can(ACTION_APPROVE, SUBJECT_OPEN_LOOP_PAYOUTS);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS: ({ ability }) => {
    ability.can(ACTION_CREATE, SUBJECT_CLOSED_LOOP_PAYOUTS);
    ability.can(ACTION_UPDATE, SUBJECT_CLOSED_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_MANAGE_PAYOUTS: ({ ability }) => {
    ability.cannot(ACTION_CREATE, SUBJECT_CLOSED_LOOP_PAYOUTS);
    ability.cannot(ACTION_UPDATE, SUBJECT_CLOSED_LOOP_PAYOUTS);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE: ({ ability }) => {
    ability.can(ACTION_CREATE, SUBJECT_OPEN_LOOP_PAYOUTS);
    ability.can(ACTION_UPDATE, SUBJECT_OPEN_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_OPEN_LOOP_PAYOUTS_MANAGE: ({
    ability,
  }) => {
    ability.cannot(ACTION_CREATE, SUBJECT_OPEN_LOOP_PAYOUTS);
    ability.cannot(ACTION_UPDATE, SUBJECT_OPEN_LOOP_PAYOUTS);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
    ]);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
    ]);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
    ]);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_SETTLEMENT_CONFIGURATION_MANAGE: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_ACCOUNT, [
      PROPERTY_PRODUCTION_SETTLEMENT_CONFIGURATION,
      PROPERTY_SANDBOX_SETTLEMENT_CONFIGURATION,
      PROPERTY_PRODUCTION_SETTLEMENT_SCHEDULE_CONFIG,
      PROPERTY_SANDBOX_SETTLEMENT_SCHEDULE_CONFIG,
    ]);
  },
  PERMISSION_GROUP_SETTLEMENT_BATCHES_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_SETTLEMENT);
    ability.can(ACTION_LIST, SUBJECT_CASH_MANAGEMENT_SETTLEMENT);
  },
  PERMISSION_VOLT_GROUP_SETTLEMENT_BATCHES_VIEW: ({ ability }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CASH_MANAGEMENT_SETTLEMENT);
    ability.can(ACTION_LIST, SUBJECT_CASH_MANAGEMENT_SETTLEMENT);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE: ({
    ability,
  }) => {
    ability.can(ACTION_CREATE, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_TEST, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_UPDATE, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
  },
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_LIST, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_MANAGE: ({
    ability,
  }) => {
    ability.can(ACTION_CREATE, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_TEST, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_UPDATE, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
  },
  PERMISSION_GROUP_CASH_MANAGEMENT_CREDIT_NOTIFICATION_SETTINGS_VIEW: ({
    ability,
  }) => {
    ability.can(ACTION_DETAILS, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
    ability.can(ACTION_LIST, SUBJECT_CREDIT_NOTIFICATION_PREFERENCES);
  },
  PERMISSION_VOLT_GROUP_FEATURE_FLAG_CREATE: () => void 0,
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_CASH_MANAGEMENT_FINOPS_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_WORKFLOWS_APPROVE: ({ ability }) => {
    ability.can(ACTION_APPROVE, SUBJECT_WORKFLOWS);
  },
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_ADMIN_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_BILLING_ENGINE_FEES_CONFIGURATION_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_SETTLEMENT_CANCEL: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_ADMIN: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING: () => void 0,
  PERMISSION_GROUP_VCM_APPROVE_PAYMENT_VIA_OPEN_BANKING: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING: () =>
    void 0,
  PERMISSION_GROUP_VCM_APPROVE_ACCOUNT_INFORMATION_VIA_OPEN_BANKING: () =>
    void 0,
  PERMISSION_VOLT_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING:
    () => void 0,
  PERMISSION_GROUP_VCM_APPROVE_CONFIRMATION_OF_FUNDS_VIA_OPEN_BANKING: () =>
    void 0,
  PERMISSION_GROUP_VCM_MERCHANT_ROLE: () => void 0,
  PERMISSION_GROUP_VCM_MERCHANT_VIEW_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_MERCHANT_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_MERCHANT_VIEW_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ADMIN_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ANALYST_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_ADMIN_EXT_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_COMPLIANCE_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_PERMISSIONS_ADMIN_ROLE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_REQUEST: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_ACCOUNTS_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_FAILED_CREDITS_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_TRANSACTIONS_VIEW: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_FINOPS_ACTIONS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_ACCOUNT_LOCKS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_SYSTEM_CONFIG_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_SUPPORT_REPROCESS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_TRUSTED_ACCOUNTS_MANAGE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_PAYOUTS_CREATE: () => void 0,
  PERMISSION_VOLT_GROUP_VCM_ROLES_MANAGE: () => void 0,
  PERMISSION_GROUP_VCM_PAYOUTS_CREATE: () => void 0,
  PERMISSION_GROUP_VCM_TRANSACTIONS_VIEW: () => void 0,
  PERMISSION_GROUP_VCM_ACCOUNTS_VIEW: () => void 0,
};
