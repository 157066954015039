import { portalApi, TAGS } from "api/rtkApi";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";
import { SERVICES } from "constants/environment";

import { getPaginationInfoFromHeaders } from "../../../api/helpers/pagination";
import { prepareCashManagementAccountDataForApi } from "../utils/prepareCashManagementAccountDataForApi/prepareCashManagementAccountDataForApi";

import type { CountryCode } from "models/CountryCode";
import type { Params } from "modules/common/hooks/useQueryString";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { RTKMeta } from "../../../models/RTKMeta";
import type {
  CashManagementAccount,
  OrderableColumns,
} from "../models/CashManagementAccount";
import type { CreateCashManagementAccountForm } from "../models/CreateCashManagementAccountForm";
import type { SettlementBalance, SettlementLimit } from "../models/Settlements";
import type { SettlementScheduleConfig } from "../models/SettlementScheduleConfig";

type GetCashManagementAccountsResponse = {
  cashManagementAccounts: CashManagementAccount[];
  totalItems: number;
};
type GetCashManagementAccountsRequest = Partial<
  PaginationParams<OrderableColumns>
> & {
  customerId?: UUID;
  isActive?: boolean;
};

type GetCashManagementAccountResponse = CashManagementAccount;
type GetCashManagementAccountRequest = ObjectWithId;

type PutCashManagementAccountResponse = ObjectWithId;
type PutCashManagementAccountRequest = ObjectWithId & {
  data: Partial<CashManagementAccount>;
};

type PostCashManagementAccountResponse = ObjectWithId;
export type PostCashManagementAccountRequest =
  CreateCashManagementAccountForm & {
    customerId: UUID;
    country?: CountryCode;
  };

type GetProviderBalanceRequest = ObjectWithId;
type GetProviderBalanceResponse = { amount: number };

type GetSettlementScheduleConfigRequest = ObjectWithId;
type GetSettlementScheduleConfigResponse = SettlementScheduleConfig;

type GetSettlementBalanceRequest = ObjectWithId;
type GetSettlementBalanceResponse = SettlementBalance;

type GetSettlementLimitRequest = ObjectWithId;
type GetSettlementLimitResponse = SettlementLimit;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCashManagementAccounts: builder.query<
      GetCashManagementAccountsResponse,
      GetCashManagementAccountsRequest
    >({
      query: (params) => ({
        url: "/cash-management-accounts",
        params: prepareGetCashManagementAccountsParams(params),
      }),
      providesTags: [TAGS.CASH_MANAGEMENT_ACCOUNTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
      transformResponse: (
        cashManagementAccounts: CashManagementAccount[],
        meta: RTKMeta
      ) => {
        const { totalItems } = getPaginationInfoFromHeaders(
          Object.fromEntries(meta.response.headers.entries())
        );

        return {
          cashManagementAccounts,
          totalItems,
        };
      },
    }),
    getCashManagementAccount: builder.query<
      GetCashManagementAccountResponse,
      GetCashManagementAccountRequest
    >({
      query: ({ id }) => ({
        url: `cash-management-accounts/${id}`,
      }),
      providesTags: [TAGS.CASH_MANAGEMENT_ACCOUNTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    putCashManagementAccount: builder.mutation<
      PutCashManagementAccountResponse,
      PutCashManagementAccountRequest
    >({
      query: ({ id, data }) => ({
        url: `/cash-management-accounts/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TAGS.CASH_MANAGEMENT_ACCOUNTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
      onQueryStarted: async ({ id, data }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getCashManagementAccount",
            { id },
            (draft) => {
              Object.assign(draft, data);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    postCashManagementAccount: builder.mutation<
      PostCashManagementAccountResponse,
      PostCashManagementAccountRequest
    >({
      query: (data) => ({
        url: "/cash-management-accounts",
        method: "POST",
        body: prepareCashManagementAccountDataForApi(data),
      }),
      invalidatesTags: [TAGS.CASH_MANAGEMENT_ACCOUNTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getProviderBalance: builder.query<
      GetProviderBalanceResponse,
      GetProviderBalanceRequest
    >({
      query: ({ id }) => ({
        url: `/cash-management-accounts/${id}/provider-balance`,
        method: "GET",
      }),
      providesTags: (_, __, { id }) => [{ type: TAGS.PROVIDER_BALANCE, id }],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getSettlementScheduleConfig: builder.query<
      GetSettlementScheduleConfigResponse,
      GetSettlementScheduleConfigRequest
    >({
      query: ({ id }) => ({
        url: `/cash-management-accounts/${id}/settlement-schedule-config`,
        method: "GET",
      }),
      providesTags: (_, __, { id }) => [
        { type: TAGS.SETTLEMENT_SCHEDULE_CONFIG, id },
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getSettlementBalance: builder.query<
      GetSettlementBalanceResponse,
      GetSettlementBalanceRequest
    >({
      query: ({ id }) => ({
        url: `/cash-management-accounts/${id}/settlement-balance`,
        method: "GET",
      }),
      providesTags: (_, __, { id }) => [{ type: TAGS.SETTLEMENT_BALANCE, id }],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getSettlementLimit: builder.query<
      GetSettlementLimitResponse,
      GetSettlementLimitRequest
    >({
      query: ({ id }) => ({
        url: `/portal/cash-management-accounts/${id}/reserve-adjustment`,
        method: "GET",
      }),
      extraOptions: {
        service: SERVICES.CONNECT,
      },
      providesTags: (_, __, { id }) => [{ type: TAGS.SETTLEMENT_LIMIT, id }],
    }),
  }),
});

export const {
  useGetCashManagementAccountsQuery,
  useGetCashManagementAccountQuery,
  usePutCashManagementAccountMutation,
  usePostCashManagementAccountMutation,
  useGetProviderBalanceQuery,
  useGetSettlementScheduleConfigQuery,
  useGetSettlementBalanceQuery,
  useGetSettlementLimitQuery,
} = extendedApi;

function prepareGetCashManagementAccountsParams(
  request: GetCashManagementAccountsRequest
) {
  const params: Params = {
    ...DEFAULT_PAGINATION,
  };

  if (request.page !== undefined) {
    params.page = request.page;
  }

  if (request.limit !== undefined) {
    params.limit = request.limit;
  }

  if (request.customerId !== undefined) {
    params.customer = request.customerId;
  }

  if (request.sortBy && request.order) {
    params[`order[${request.sortBy}]`] = request.order;
  }

  if (request.isActive !== undefined) {
    params.active = request.isActive;
  }

  return params;
}
