import { styled } from "@mui/material";
import { ButtonDeprecated, SelectCustomer } from "@volt_developers/components";
import { Trans, useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { TEST_IDS } from "../../../constants/TestIds";

export interface GlobalFilterSearchNoResultsProps {
  handleResetSearch: VoidFunction;
  search: string;
}

export function GlobalFilterSearchNoResults({
  search,
  handleResetSearch,
}: GlobalFilterSearchNoResultsProps) {
  const { t } = useTranslation("common");

  return (
    <SelectCustomer.Menu data-testid={TEST_IDS.globalFilterSearchNoResults}>
      <SelectCustomer.NoResults.Wrapper>
        <Header>{t(COMMON_TRANSLATION_KEYS.noResults)}</Header>
        <Description>
          <Trans components={{ strong: <Strong /> }} t={t} values={{ search }}>
            {COMMON_TRANSLATION_KEYS.noResultsFor}
          </Trans>
        </Description>
        <SelectCustomer.NoResults.Actions>
          <ButtonDeprecated variant="outlined" onClick={handleResetSearch}>
            {t(COMMON_TRANSLATION_KEYS.resetSearch)}
          </ButtonDeprecated>
        </SelectCustomer.NoResults.Actions>
      </SelectCustomer.NoResults.Wrapper>
    </SelectCustomer.Menu>
  );
}

const Header = styled(SelectCustomer.NoResults.Header)(({ theme }) => ({
  color: theme.palette.text.high,
}));

const Description = styled(SelectCustomer.NoResults.Description)(
  ({ theme }) => ({
    color: theme.palette.text.high,
  })
);

const Strong = styled("strong")(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));
