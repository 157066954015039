import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout , selectIsAuthorized } from "modules/auth/store/auth.slice";
import { SentryRoute } from "sentry";

import type { RouteProps} from "react-router-dom";

export interface AuthRouteProps extends Omit<RouteProps, "render"> {
  invalidAuthStateRedirect: string;
  render: Exclude<RouteProps["render"], undefined>;
  requiredAuthState: boolean;
}

export function AuthRoute({
  render: routeRender,
  requiredAuthState,
  invalidAuthStateRedirect,
  ...props
}: AuthRouteProps) {
  const isAuthorized = useSelector(selectIsAuthorized);
  const dispatch = useDispatch();
  const history = useHistory();

  const isRouteAuthConditionMet = requiredAuthState === isAuthorized;

  useEffect(() => {
    if (!isAuthorized && !isRouteAuthConditionMet) {
      dispatch(logout()); // the session might have expired
    }

    if (!isRouteAuthConditionMet) {
      history.push({
        pathname: invalidAuthStateRedirect,
        state: { from: history.location },
      });
    }
  }, [
    dispatch,
    history,
    invalidAuthStateRedirect,
    isAuthorized,
    isRouteAuthConditionMet,
    requiredAuthState,
  ]);

  if (!isRouteAuthConditionMet) {
    return null;
  }

  return (
    <SentryRoute
      {...props}
      render={(routeComponentProps) => routeRender(routeComponentProps)}
    />
  );
}
