import cloneDeep from "lodash/cloneDeep";
import { useMemo } from "react";

import type { AppAbilityType } from "context/ability/ability";
import type { Route } from "routes/RouteModel";

function useMenu(
  routes: Route[],
  ability: AppAbilityType,
  forceDisplay: boolean = false
) {
  const menu = useMemo(() => {
    const clonedRoutes = cloneDeep(routes);

    function filterPermittedRoutes(
      routes: Route[],
      ability: AppAbilityType
    ): Route[] {
      return routes
        .filter((route) =>
          route.can ? ability.can(route.can.do, route.can.on) : true
        )
        .filter((route) =>
          forceDisplay
            ? !route.hidden || route.forceDisplayInHeader
            : !route.hidden
        )
        .map((route) => {
          if (route.children && route.children.length > 0) {
            route.children = filterPermittedRoutes(route.children, ability);
          }

          // after filtering children could change to 0, because of that we are removing children property
          if (route.children && route.children.length === 0) {
            delete route.children;
          }

          return route;
        })
        .filter(
          (route) => !(route.showChildrenInSidebar && !("children" in route))
        );
    }

    return filterPermittedRoutes(clonedRoutes, ability);
  }, [routes, ability, forceDisplay]);

  return { menu };
}

export { useMenu };
