const DATE_SHORT = "DATE_SHORT";
const DATETIME_SHORT = "DATETIME_SHORT";
const DATETIME_SHORT_WITH_SECONDS = "DATETIME_SHORT_WITH_SECONDS";
const TIME_SIMPLE = "TIME_SIMPLE";
const TIME_24_WITH_SECONDS = "TIME_24_WITH_SECONDS";

const dataTimeFormats = [
  DATE_SHORT,
  DATETIME_SHORT,
  DATETIME_SHORT_WITH_SECONDS,
  TIME_SIMPLE,
  TIME_24_WITH_SECONDS,
] as const;

export default dataTimeFormats;
