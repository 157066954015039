import { styled } from "@mui/material";
import {
  Button,
  ButtonDropdownDeprecated,
  VerticalDotsIcon,
} from "@volt_developers/components";

import type { PropsWithChildren } from "react";

export interface DropdownNavigationProps extends PropsWithChildren {
  buttonTestId?: string;
}

export function DropdownNavigation({
  buttonTestId,
  children,
}: DropdownNavigationProps) {
  return (
    <Wrapper>
      <ButtonDropdownDeprecated>
        <Button.Wrapper
          color="secondary"
          data-testid={buttonTestId}
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button.Text>
            <VerticalDotsIcon
              sx={{
                height: ICON_HEIGHT,
                position: "relative",
                top: ICON_TOP_POSITION_CLEARING_PADDING,
              }}
            />
          </Button.Text>
        </Button.Wrapper>
        {children}
      </ButtonDropdownDeprecated>
    </Wrapper>
  );
}

const ICON_HEIGHT = 18;

const ICON_TOP_POSITION_CLEARING_PADDING = 2;

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  position: "relative",
});
