/*
  First filters Dictionary keys leaving only keys starting with Prefix and then trims all keys leaving only suffix.

  type Dict = { fuzebox_host: string, connect_host: number };
  type FuzeboxDict = FilterAndTrimKeysByPrefix<Dict, 'fuzebox_'>; // Resolves to { host: string };
  type ConnectDict = FilterAndTrimKeysByPrefix<Dict, 'connect_'>; // Resolves to { host: number };
  type EmptyDict = FilterAndTrimKeysByPrefix<Dict, '???'>;        // Resolves to {};
 */
export type FilterAndTrimKeysByPrefix<
  Dictionary extends Object,
  Prefix extends string,
> = {
  [Key in keyof Dictionary as Key extends `${Prefix}${infer NAME}`
    ? NAME
    : never]: Dictionary[Key];
};

/*
    First filters dictionary keys leaving only keys starting with prefix and then trims all keys leaving only suffix.

    const dict = { fuzebox_host: 'localhost' as const, connect_host: 8080 as const };
    const fuzeboxDict = filterAndTrimKeysByPrefix(dict, 'fuzebox_'); // Evaluates to { host: 'localhost' }
    const connectDict = filterAndTrimKeysByPrefix(dict, 'connect_'); // Evaluates to { host: 8080' }
 */
export function filterAndTrimKeysByPrefix<
  Dictionary extends Object,
  Prefix extends string,
>(
  dictionary: Dictionary,
  prefix: Prefix
): FilterAndTrimKeysByPrefix<Dictionary, Prefix> {
  return Object.entries(dictionary)
    .filter(([key, value]) => key.startsWith(prefix))
    .reduce(
      (acc, [key, value]) =>
        Object.assign(acc, { [key.slice(prefix.length)]: value }),
      {}
    ) as FilterAndTrimKeysByPrefix<Dictionary, Prefix>;
}
